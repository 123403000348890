<template>
  <!--
    Layout3Inner had to be created to use in overlays. Overlays should not
    contain another <main>. Main is styled by Vuetify and takes sidebars into
    account. When opening an overlay on the documents page, <main> would have
    a broad padding to the right. This would result in content being offset from
    the center.

    Always use Layout3Inner for overlays.
  -->
  <!-- TODO: Fix this -->
  <v-container :fill-height="$vuetify.breakpoint.mdAndUp">
    <v-layout :align-center="!contentFlexStart" wrap layout-3>
      <v-flex xs12 :class="[equalWidth ? 'md6' : 'md5']">
        <v-layout column flex-start>
          <!-- Slot left contains entire left side of the layout -->
          <slot name="left">
            <!-- Slot left-upper contains heading and mobile image -->
            <slot name="left-upper">
              <heading :title="title" :subtitle="subtitle" :hint-title="hintTitle" :hint-text="hintText" />
              <!-- Don't output the image when the right slot is used -->
              <responsive-image
                v-if="!$slots['right'] && !hideImageOnMobile && $vuetify.breakpoint.smAndDown"
                class="mb-6"
                :width="image.width"
                :height="image.height"
                :source="image.src"
              />
              <!-- Slot default contains all below heading and mobile image -->
              <slot />
            </slot>
          </slot>
        </v-layout>
      </v-flex>
      <v-flex xs12 :class="[equalWidth ? 'md6' : 'md7']">
        <!-- Layout shall only center when an image is displayed -->
        <v-layout column :align-center="!$slots['right']">
          <!-- Slot left contains entire righ side of the layout -->
          <slot name="right">
            <responsive-image
              v-if="!$slots['right'] && $vuetify.breakpoint.mdAndUp"
              :source="image.src"
              :width="image.width"
              :height="image.height"
            />
          </slot>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'

import Heading from '~/components/Heading.vue'
import ResponsiveImage from '~/components/ResponsiveImage.vue'

interface Img {
  src: string
  width: number
  height: number
}

interface Props {
  contentFlexStart: boolean
  equalWidth: boolean
  hintTitle: string
  hintText: string
  hideImageOnMobile: boolean
  image: Img
  subtitle: string
  title: string
}

export default Vue.extend<void, void, void, Props>({
  name: 'Layout3Inner',
  components: {
    Heading,
    ResponsiveImage,
  },
  props: {
    // Top align left and right parts of the layout
    contentFlexStart: Boolean,
    // Makes columns equally wide
    equalWidth: Boolean,
    hintTitle: {
      type: String,
      default: '',
    },
    hintText: {
      type: String,
      default: '',
    },
    // Hides images for sm and down. Consider setting this as default or maybe
    // disable showing images on mobile for all layout3
    hideImageOnMobile: Boolean,
    image: {
      type: Object as Vue.PropType<Img>,
      default: () => ({
        src: '',
        width: 0,
        height: 0,
      }),
    },
    subtitle: String,
    title: String,
  },
})

// export default data
</script>

<style lang="sass"></style>

import { objectToCamelCase, objectToSnakeCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export async function sign(signData: SignData, language: Language = 'en', tan?: string): Promise<SignResponse> {
  return await batchSign([signData], language, tan)
}

export async function batchSign(signData: SignData[], language: Language = 'en', tan?: string): Promise<SignResponse> {
  const payload = signData.map(({ id, visualSignature, signingName, signingReason }) => ({
    signatureRequestId: id,
    signingName,
    signingReason,
    visualSignature,
  }))

  const response = await $http.$post(
    '/v1/sign',
    objectToSnakeCase({
      signatureRequests: payload,
      language: language as string,
      ...(Boolean(tan) && { tan }),
    })
  )

  return objectToCamelCase<SignResponse>(response)
}

export async function seal(sealData: SignData, sealAccountName: string): Promise<SignResponse> {
  return await batchSeal([sealData], sealAccountName)
}

export async function batchSeal(sealData: SignData[], sealAccountName: string): Promise<SignResponse> {
  const payload = sealData.map(({ id, visualSignature }) => ({
    signatureRequestId: id,
    visualSignature,
  }))

  const response = await $http.$post(
    '/v1/sign',
    objectToSnakeCase({
      signatureRequests: payload,
      sealAccountName,
    })
  )

  return objectToCamelCase<SignResponse>(response)
}

export async function continueSign(continuationId: string, isPwdotp = false): Promise<SignResponse> {
  const response = await $http.$post<{ status: string; message: string }>(`/v1/swisscom/proceed/${continuationId}`, {
    pwdotp: isPwdotp,
  })

  return objectToCamelCase<SignResponse>(response)
}

export async function checkSigningLock(signatureRequestId: string): Promise<{ locked: boolean }> {
  return await $http.$get<{ locked: boolean }>(`/v1/signature-requests/${signatureRequestId}/lock-status`)
}

export async function getSignatureRequest(id: string): Promise<SignatureRequestData> {
  const response = await $http.$get(`/v1/signature-requests/${id}`)

  return objectToCamelCase<SignatureRequestData>(response)
}

export async function updateSignatureRequest(
  id: string,
  updateData:
    | Partial<SignatureRequestData>
    | (Omit<SignatureRequestData, 'signatures'> & { signatures: Partial<SignatureData>[] })
): Promise<SignatureRequestData> {
  // TODO: Talk to backend about this
  // await $http.$put(`/v1/signature-requests/${id}`, objectToSnakeCase(updateData))
  const updatedSignatureRequest = await $http.$put('/v1/signature-requests', objectToSnakeCase({ id, ...updateData }))

  return objectToCamelCase<SignatureRequestData>(updatedSignatureRequest)
}

export async function getSignatureRequests(
  type: SignatureRequestType = 'all',
  email?: string
): Promise<SignatureRequestData[]> {
  const searchParams: Record<string, string> = {}

  switch (type) {
    case 'toSign':
      Object.assign(searchParams, {
        status_overall: 'OPEN',
        signature_status: 'OPEN',
        account_email: email,
      })
      break
    case 'pending':
      Object.assign(searchParams, {
        status_overall: 'OPEN',
        /**
         * Magic number defined in the backend, signifying `signatures_count > 0` 😵‍💫
         *
         * @see {@link https://app.asana.com/0/1201960016666275/1204888603830081/f}
         */
        signatures_count: 672,
      })
      break
    case 'empty':
      Object.assign(searchParams, {
        status_overall: 'OPEN',
        signatures_count: 0,
      })
      break
    case 'declined':
      searchParams.status_overall = 'DECLINED,WITHDRAWN'
      break
    case 'completed':
      searchParams.status_overall = 'SIGNED'
      break
  }

  const response = await $http.$get('/v1/signature-requests', { searchParams })

  return objectToCamelCase<SignatureRequestData[]>(response)
}

export async function getSignatureRequestEvents(id: string): Promise<SignatureRequestEvent[]> {
  const response = await $http.$get(`/v1/signature-requests/${id}/events`)

  return objectToCamelCase<SignatureRequestEvent[]>(response)
}

export async function getDocument(id: string): Promise<DocumentData> {
  const response = await $http.$get(`/v1/documents/${id}`)

  return objectToCamelCase<DocumentData>(response)
}

import { objectToCamelCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export async function ffMigrateAccept(businessId: string): Promise<BusinessData> {
  const response = await $http.$put(`/v2/businesses/${businessId}/migrate_ff_individual/accept`)

  return objectToCamelCase(response)
}

export async function ffMigrateDecline(businessId: string): Promise<BusinessData> {
  const response = await $http.$put(`/v2/businesses/${businessId}/migrate_ff_individual/decline`)

  return objectToCamelCase(response)
}

export async function acceptFreeMigration(): Promise<void> {
  await $http.$put('/v2/users/migrate/consent_accept')
}

import Vue from 'vue'
import type { GetterTree, MutationTree } from 'vuex'

export const state: () => BaseState = () => ({
  locales: ['en-gb', 'de-ch', 'fr-ch', 'it'],
  loadedLocales: [],
  locale: 'en-gb',
  directSignSession: false,
  user: null,
  userSignatureQualities: null,
  processedSignatureRequests: [], // SR which are currently processed (signed, declined etc.)
  displayedSignatureRequests: [], // SRs shown on /
  activeSignatureRequest: null, // SR chosen on / or /view/*
  activeDocument: null, // document chosen on /view/* or /request/*
  activeTab: 'toSign', // active tab on /
  continuationData: null, // continuation data to proceed signing (sc_ra, sc_fast_track, atrust etc.)
  userForgotPwd: false, // to indicate that user clicked forgot pw on pwd screen which needs to trigger a cancel first
  signingStatus: null,
  lockStatus: null,
  exitURLs: null, // to store URLs and timing related to exit URLs when signing
  sealDetails: {
    data: {
      label: '',
      name: '',
      members: {
        api_keys: [],
        users: [],
      },
    },
    image: null,
  },
  signatureImage: false,
  // controls when overlays shall be displayed
  overlays: {
    signingFailure: false,
    signing: false,
    tanExpired: false,
    tanInvalid: false,
    tanLocked: false,
    mobileAes: false,
    downgrade: false,
  },
  snackbar: {
    value: false,
    message: '',
    action: {},
    color: 'skribbleu',
  },
  alert: {
    value: false,
    message: '',
    action: {},
    color: 'info',
    priority: 5,
  },
  countryList: [],
  now: {
    value: '',
    lastUpdated: 0,
  },
})

export const getters: GetterTree<BaseState, RootState> = {
  // returns augmented user from store
  user: state => {
    // Tells the highest level the user can sign, where null means that they
    // cannot sign at all. A verified email address is the least we require,
    // unless we are dealing with a direct signer.
    // If a user is set to demo mode they will have all their signature
    // qualities set to 'demo'. So we can check for this in the 'qes' key.
    let highestQuality: string | null = null
    if (state.user && (state.user.emailVerified || state.directSignSession) && state.userSignatureQualities) {
      if (state.userSignatureQualities.qes?.eidas || state.userSignatureQualities.qes?.zertes) {
        // If a user did a video ident they can only sign with eIDAS. However,
        // it is possible to end up in a ZertES-only case when the user only
        // accepted the ZertES GTC (and not the eIDAS GTC) of Swisscom.
        // Therefore we need to check both keys for 'demo'.
        if (state.userSignatureQualities.qes.eidas?.quality?.toLowerCase() === 'demo') {
          highestQuality = 'demo'
        } else if (state.userSignatureQualities.qes.zertes?.quality?.toLowerCase() === 'demo') {
          highestQuality = 'demo'
        } else {
          highestQuality = 'qes'
        }
      } else if (state.userSignatureQualities.aes?.any) {
        highestQuality = 'aes'
      } else if (state.userSignatureQualities.ses?.any) {
        highestQuality = 'ses'
      }
    }

    // introduce entireName which is a composition of first- and lastname or
    // the email address if the name is unknow (directSign from GUI)
    let entireName = ''

    // this will be true for biz account members and pro users or free users
    // who still have free signatures remaining
    let hasSigningCredit = false

    // this will be true when an SSO signup didn't provice a name
    // for the user, so this needs to be provided along with accepting GTC
    let forceCompleteSignup = false

    // show GTC dialog to users which haven't accepted them so far
    let showGTCDialog = false

    // show biz invite dialog to users which haven't seen them so far
    let showBizInviteDialog = false

    // show no longer in biz dialog to users which left or get kicked from a biz
    let showNoLongerInBizDialog = false

    // indicate wheter a pro user has a special deal like SAV, expertSUISSE etc.
    let proWithSpecialDeal = false

    // Check if this is a free user
    let isFreeUser = true

    // As the GUI will now also create directSign SRs for AES requests
    // directSign users must supply certain eID data.
    // This getter will indicate which fields are immutable and which can be
    // edited by the user.
    // The legacy flow allows no changes at all which is indicated by ['all']
    // and is set as default.
    let directSignImmutableEidFields = ['all']

    if (state.user) {
      /**
       * kind of weird function but this is just making it easier to read
       * the following conditionals when dealing with these attribute arrays
       */
      const transformAttr = (userAttrs: {
        immutable_eid_fields?: string[]
        [index: string]: string | string[] | undefined | boolean
      }): Record<string, string> & {
        immutable_eid_fields?: string[]
      } => {
        const attrs = {}
        for (const attr in userAttrs) {
          if (attr === 'immutable_eid_fields') {
            attrs[attr] = userAttrs[attr] ? userAttrs[attr] : ['all']
          } else {
            const value = (
              userAttrs[attr] ? (Array.isArray(userAttrs[attr]) ? userAttrs[attr]?.[0] : userAttrs[attr]) : ''
            ) as string
            attrs[attr] = value
          }
        }
        return attrs
      }
      const attrs = transformAttr(state.user.attributes as any)

      if (attrs.stripe_subscription_id || attrs.member_of) {
        isFreeUser = false
      }

      if (state.user.firstName || state.user.lastName) {
        entireName = `${state.user.firstName} ${state.user.lastName}`
      } else if (attrs.signer_identity_email) {
        entireName = attrs.signer_identity_email
      }

      // show biz invite if user just got added to a business
      showBizInviteDialog = Boolean(!attrs.accepted_business_invite && attrs.member_of)

      // show no longer in biz if user got kicked from a business
      showNoLongerInBizDialog = attrs.removed_from_business === 'true'

      // user has incomplete SSO signup
      forceCompleteSignup =
        !state.directSignSession && !state.user.firstName && !state.user.lastName && !attrs.accepted_gtc

      // show GTCs if user did not accept them already and is not in a business
      showGTCDialog =
        !forceCompleteSignup &&
        !attrs.accepted_gtc &&
        attrs.business_deleted !== 'true' &&
        !attrs.member_of &&
        !showNoLongerInBizDialog

      if (!isFreeUser) {
        // this user can sign anyway because they are either in a biz account
        // or are a pro user. so we set this to true
        hasSigningCredit = true
      } else if (state.directSignSession || parseInt(attrs.free_signatures_remaining ?? '0', 10) > 0) {
        // check if user is a NAS or if the user has at least
        // 1 free signature left
        hasSigningCredit = true
      }

      proWithSpecialDeal = Boolean(attrs.stripe_subscription_id) && !attrs.stripe_self_qes_trx_si

      // directSign users from the new flow will always have an
      // immutable_eid_fields attribute on the user.
      if (attrs.immutable_eid_fields) {
        directSignImmutableEidFields = attrs.immutable_eid_fields
      }
    }

    const user = Object.assign({}, state.user, {
      directSignImmutableEidFields,
      entireName,
      hasSigningCredit,
      highestQuality,
      isFreeUser,
      proWithSpecialDeal,
      showBizInviteDialog,
      showGTCDialog,
      showNoLongerInBizDialog,
      forceCompleteSignup,
    })

    return user
  },
  // returns NAS basic auth as b64 encoded token for img and a tags
  directSignB64AuthHeader: (state): string => {
    if (
      state.directSignSession &&
      state.directSignSession?.auth &&
      state.directSignSession?.auth.username &&
      state.directSignSession?.auth.password
    ) {
      return btoa(`${state.directSignSession?.auth?.username}:${state.directSignSession?.auth?.password}`)
    }
    return ''
  },
  sealImage: state => {
    return state.sealDetails.image
  },
  lang: state => {
    return state.user?.attributes.lang?.[0].substring(0, 2) ?? ''
  },
}

export const mutations: MutationTree<BaseState> = {
  SET_DIRECTSIGN(state, data: { auth: BasicAuth }) {
    state.directSignSession = data
  },
  RESET_DIRECTSIGN(state) {
    state.directSignSession = false
  },
  SET_USER(state, user: User) {
    state.user = user
  },
  RESET_USER(state) {
    state.user = null
    state.userSignatureQualities = null
  },
  SET_USERSIGNATUREQUALITIES(state, user: SignatureQualities) {
    state.userSignatureQualities = user
  },
  REMOVE_STRIPE_SUBSCRIPTION_ID(state) {
    if (state.user && state.user.attributes) Vue.set(state.user.attributes, 'stripe_subscription_id', [])
  },
  SET_DISPLAYED_SIGNATURE_REQUESTS(state, signatureRequests: ActiveSignatureRequest[]) {
    state.displayedSignatureRequests = signatureRequests
  },
  REMOVE_FROM_DISPLAYED_SIGNATURE_REQUESTS(state, signatureRequest) {
    const index = state.displayedSignatureRequests.findIndex(sr => {
      return sr.id === signatureRequest
    })
    if (index > -1) {
      state.displayedSignatureRequests.splice(index, 1)
    }
  },
  SET_DISPLAYED_SIGNATURE_REQUEST_TO(state, data: { signatureRequestId: string; stateToSet: string }) {
    const index = state.displayedSignatureRequests.findIndex(sr => {
      return sr.id === data.signatureRequestId
    })
    if (index > -1) {
      state.displayedSignatureRequests[index].status_overall = data.stateToSet
    }
  },
  SET_ACTIVE_SIGNATURE_REQUEST(state, signatureRequest) {
    Vue.set(state, 'activeSignatureRequest', signatureRequest)
  },
  SET_ACTIVE_SIGNATURE_REQUEST_EVENTS(state, events) {
    // workaround to avoid a racing condition issue:
    // sometimes reset_visual_signature_request is being called by a vue hook
    // between set_active_signature_request (above) and the functions below
    if (state.activeSignatureRequest) Vue.set(state.activeSignatureRequest, 'events', events)
  },
  SET_ACTIVE_SIGNATURE_REQUEST_BRANDING(state, branding) {
    // workaround to avoid a racing condition issue:
    // sometimes reset_visual_signature_request is being called by a vue hook
    // between set_active_signature_request (above) and the functions below
    if (state.activeSignatureRequest) Vue.set(state.activeSignatureRequest, 'branding', branding)
  },
  SET_ACTIVE_SIGNATURE_REQUEST_BIZNAME(state, name) {
    // workaround to avoid a racing condition issue:
    // sometimes reset_visual_signature_request is being called by a vue hook
    // between set_active_signature_request (above) and the functions below
    if (state.activeSignatureRequest) Vue.set(state.activeSignatureRequest, 'bizName', name)
  },
  RESET_ACTIVE_SIGNATURE_REQUEST(state) {
    state.activeSignatureRequest = null
  },
  SET_PROCESSED_SIGNATURE_REQUESTS(state, signatureRequests: ActiveSignatureRequest[]) {
    state.processedSignatureRequests = signatureRequests
  },
  RESET_PROCESSED_SIGNATURE_REQUESTS(state) {
    state.processedSignatureRequests = []
  },
  SET_VISUAL_SIGNATURE(state, visualSignature) {
    if (state.activeSignatureRequest) Vue.set(state.activeSignatureRequest, 'visualSignature', visualSignature)
  },
  RESET_VISUAL_SIGNATURE(state) {
    if (state.activeSignatureRequest) Vue.set(state.activeSignatureRequest, 'visualSignature', null)
  },
  SET_VISUAL_SIGNATURE_SPEC(state, visualSignatureSpec) {
    if (state.activeSignatureRequest) Vue.set(state.activeSignatureRequest, 'visualSignatureSpec', visualSignatureSpec)
  },
  SET_ACTIVE_DOCUMENT(state, document: SkrDocument) {
    state.activeDocument = document
  },
  RESET_ACTIVE_DOCUMENT(state) {
    state.activeDocument = null
  },
  SET_ACTIVE_TAB(state, tab: ActiveTab | null) {
    state.activeTab = tab
  },
  RESET_ACTIVE_TAB(state) {
    state.activeTab = null
  },
  SET_EXIT_URLS(
    state,
    data: {
      signatureRequest?: string | undefined
      exitURL?: string | undefined
      errorURL?: string | undefined
      declineURL?: string | undefined
      timeout?: number | undefined
      declineRedirect?: boolean | undefined
    } | null
  ) {
    state.exitURLs = data
  },
  SET_CONTINUATION_DATA(state, data: { channel: string; url: string }) {
    state.continuationData = data
  },
  RESET_CONTINUATION_DATA(state) {
    state.continuationData = null
  },
  SHOW_OVERLAY(state, overlay) {
    state.overlays[overlay] = true
  },
  HIDE_OVERLAY(state, overlay) {
    state.overlays[overlay] = false
  },
  SET_SIGNING_STATUS(state, status: { status: string; message: string }) {
    state.signingStatus = status
  },
  RESET_SIGNING_STATUS(state) {
    state.signingStatus = null
  },
  SET_LOCK_STATUS(
    state,
    status: {
      signature_request: ActiveSignatureRequest
    } | null
  ) {
    state.lockStatus = status
  },
  RESET_LOCK_STATUS(state) {
    state.lockStatus = null
  },
  SET_USER_FORGOT_PWD(state) {
    state.userForgotPwd = true
  },
  RESET_USER_FORGOT_PWD(state) {
    state.userForgotPwd = false
  },
  SET_SIGNATURE_IMAGE(state, imageData: boolean) {
    state.signatureImage = imageData
  },
  RESET_SIGNATURE_IMAGE(state) {
    state.signatureImage = false
  },
  // this will set the snackbar data with color = 'info' as default
  // a snackbar will be displayed in the layout as soon as its message
  // is not a falsy value, i.e ''
  SET_SNACKBAR(state, snackbar: SnackBar) {
    state.snackbar = {
      ...state.snackbar,
      ...{
        value: true,
        color: 'skribbleu',
        action: {},
        message: '',
      },
      ...snackbar,
    }
  },
  RESET_SNACKBAR(state) {
    // reset snackbar to defaults and hide
    state.snackbar = Object.assign(state.snackbar, {
      value: false,
      message: null,
      action: {},
      color: 'skribbleu',
      timeout: 4000,
    })
  },
  SET_ALERT(state, alert: Alert) {
    // the new alert will only be set if its priority is higher than the
    // existing one
    if (state.alert.priority >= alert.priority) {
      state.alert = {
        ...state.alert,
        ...{
          value: true,
        },
        ...alert,
      }
    }
  },
  RESET_ALERT(state) {
    // reset alert to defaults and hide
    state.alert = Object.assign(state.alert, {
      value: false,
      message: '',
      color: '',
      action: {},
      priority: 5,
    })
  },
  SET_SEAL_DETAILS(state, sealDetails: BusinessSealDetails) {
    state.sealDetails.data = sealDetails
  },
  SET_SEAL_IMAGE(state, imageData: SkrImage | null) {
    state.sealDetails.image = imageData
  },
  RESET_SEAL_IMAGE(state) {
    state.sealDetails.image = null
  },
  SET_NOW(state, now: string) {
    state.now = {
      value: now,
      lastUpdated: Date.now(),
    }
  },
}

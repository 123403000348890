import { defineStore } from 'pinia'

interface WidgetData {
  snackbarQueue: SnackBar[]
  activeOverlay: DynamicOverlayData | null
  overlayProps: DynamicOverlayData['props']
  activeDialog: DynamicDialogData | null
}

export const useWidgetStore = defineStore('widget', {
  state: (): WidgetData => ({
    snackbarQueue: [],
    activeOverlay: null,
    overlayProps: {},
    activeDialog: null,
  }),
  actions: {
    setOverlay<T extends OverlayName>(name: T, props: Overlays[T], onAction?: DynamicOverlayData['onAction']) {
      this.overlayProps = props
      this.activeOverlay = {
        loader: () => import(`@/components/overlays/${name}.vue`),
        props: this.overlayProps,
        onAction,
      }
    },
    setDialog<T extends DialogName>(
      name: T,
      props: Dialogs[T]['props'],
      onAction?: (contextData: Dialogs[T]['contextData']) => void | Promise<void>
    ) {
      this.activeDialog = {
        loader: () => import(`@/components/dialogs/${name}.vue`),
        props,
        onAction,
      }
    },
    updateProps<T extends OverlayName>(props: Overlays[T]) {
      this.overlayProps = props
      if (this.activeOverlay) this.activeOverlay.props = this.overlayProps
    },
    createSnackbar(data: Partial<SnackBar>) {
      // Using the old way for now, but we really should rethink
      // the whole snackbar logic
      void this.$nuxt.store.dispatch('setSnackbar', data)
    },
    resetSnackbar() {
      // This wouldn't be necessary anymore if we rethink the whole snackbar logic
      void this.$nuxt.store.dispatch('resetSnackbar')
    },
    resetWidgets() {
      this.activeOverlay = null
      this.overlayProps = {}
    },
  },
})

const middleware = {}

middleware['auth'] = require('../src/middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['billing'] = require('../src/middleware/billing.ts')
middleware['billing'] = middleware['billing'].default || middleware['billing']

middleware['business'] = require('../src/middleware/business.ts')
middleware['business'] = middleware['business'].default || middleware['business']

middleware['cookiesblocked'] = require('../src/middleware/cookiesblocked.ts')
middleware['cookiesblocked'] = middleware['cookiesblocked'].default || middleware['cookiesblocked']

middleware['exitURL'] = require('../src/middleware/exitURL.ts')
middleware['exitURL'] = middleware['exitURL'].default || middleware['exitURL']

middleware['freeUser'] = require('../src/middleware/freeUser.ts')
middleware['freeUser'] = middleware['freeUser'].default || middleware['freeUser']

middleware['i18n'] = require('../src/middleware/i18n.ts')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['identification'] = require('../src/middleware/identification.ts')
middleware['identification'] = middleware['identification'].default || middleware['identification']

middleware['locked'] = require('../src/middleware/locked.ts')
middleware['locked'] = middleware['locked'].default || middleware['locked']

middleware['loginEmail'] = require('../src/middleware/loginEmail.ts')
middleware['loginEmail'] = middleware['loginEmail'].default || middleware['loginEmail']

middleware['maintenanceWindow'] = require('../src/middleware/maintenanceWindow.ts')
middleware['maintenanceWindow'] = middleware['maintenanceWindow'].default || middleware['maintenanceWindow']

middleware['sso'] = require('../src/middleware/sso.ts')
middleware['sso'] = middleware['sso'].default || middleware['sso']

middleware['testing'] = require('../src/middleware/testing.ts')
middleware['testing'] = middleware['testing'].default || middleware['testing']

middleware['userDelete'] = require('../src/middleware/userDelete.ts')
middleware['userDelete'] = middleware['userDelete'].default || middleware['userDelete']

middleware['userpilot'] = require('../src/middleware/userpilot.ts')
middleware['userpilot'] = middleware['userpilot'].default || middleware['userpilot']

export default middleware

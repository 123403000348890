import { camelize, camelizeKeys, decamelizeKeys } from 'humps'

// TODO: Composable might be confusing because no state is involved
export function objectToCamelCase<R = undefined, I = unknown>(obj: I): R extends undefined ? Camelized<I> : R {
  return camelizeKeys(obj, (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key))) as never
}

export function objectToSnakeCase<R = undefined, I = unknown>(obj: I): R extends undefined ? Decamelized<I> : R {
  return decamelizeKeys(obj, (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key))) as never
}

export function stringToCamelCase(str: string) {
  return camelize(str)
}

export function responseTransformer(rawJsonData?: string) {
  if (rawJsonData) {
    const data = JSON.parse(rawJsonData) as unknown
    return camelizeKeys(data, (key, convert) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key)))
  }
}

export function requestTransformer(data: any, headers: any) {
  if (data && typeof data === 'object') {
    headers['Content-Type'] = 'application/json'
    const transformedData = decamelizeKeys(data)
    return JSON.stringify(transformedData)
  }
}

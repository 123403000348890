import { defineStore } from 'pinia'

export const useExitUrlStore = defineStore('exitUrl', {
  state: () => ({
    exitInterval: 0,
    exitDelay: 0,
    autoRedirect: false,
  }),
  getters: {
    signatureRequest(): string {
      return this.$nuxt.store.state.exitURLs?.signatureRequest
    },
    timeout(): number {
      return this.$nuxt.store.state.exitURLs?.timeout ?? 0
    },
    exitURL(): string {
      return this.$nuxt.store.state.exitURLs?.exitURL ?? ''
    },
    errorURL(): string {
      return this.$nuxt.store.state.exitURLs?.errorURL ?? ''
    },
    declineURL(): string {
      return this.$nuxt.store.state.exitURLs?.declineURL ?? ''
    },
  },
  actions: {
    areExitURLsApplicable(signatureRequestIDs: [string]) {
      // This functions expects an array of SR IDs which is the structure of
      // $store's processedSignatureRequests

      // The exit URLs are applicable if one was specified in this session
      // and the user just signed the corresponding signature request not in a
      // batch signature process
      return signatureRequestIDs.length === 1 && signatureRequestIDs[0] === this.signatureRequest
    },
    initAutoRedirect(signatureRequestID: string) {
      if (this.exitInterval) return
      // Init automatic redirection if there was an exitURL given based on
      // the redirection timeout set.
      this.exitDelay = this.timeout
      if (this.areExitURLsApplicable([signatureRequestID])) {
        this.exitInterval = window.setInterval(() => {
          this.exitDelay -= 1
          if (this.exitDelay <= 0) {
            // redirect to exitURL
            this.leaveToExitURL()
          }
        }, 1000)
      }
    },
    clearInterval() {
      window.clearInterval(this.exitInterval)
      this.exitInterval = 0
    },
    cancelRedirect() {
      this.clearInterval()
    },
    leaveToExitURL() {
      // Leave to exitURL and clear state
      this.clearInterval()
      const redirectTo = this.exitURL
      void this.$nuxt.store.dispatch('resetExitURLs')
      window.location.href = redirectTo
    },
    leaveToErrorURL() {
      // Leave to errorURL and clear state
      const redirectTo = this.errorURL
      void this.$nuxt.store.dispatch('resetExitURLs')
      window.location.href = redirectTo
    },
    leaveToDeclineURL() {
      // Leave to declineURL and clear state
      const redirectTo = this.declineURL
      void this.$nuxt.store.dispatch('resetExitURLs')
      window.location.href = redirectTo
    },
  },
})

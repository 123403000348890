var getScrollInfo = function getScrollInfo(element) {
  return {
    scrollTop: element.scrollTop,
    scrollLeft: element.scrollLeft,
    scrollHeight: element.scrollHeight,
    scrollWidth: element.scrollWidth,
    sceneHeight: element.clientHeight,
    sceneWidth: element.clientWidth
  };
};
export default function useScrollPosition(element) {
  var scrollWidth = ref(0);
  var scrollHeight = ref(0);
  var scrollTop = ref(0);
  var scrollLeft = ref(0);
  var sceneWidth = ref(0);
  var sceneHeight = ref(0);
  var container = ref(null);
  var updateScrollInfo = function updateScrollInfo() {
    if (!container.value) return;
    var _getScrollInfo = getScrollInfo(container.value),
      top = _getScrollInfo.scrollTop,
      left = _getScrollInfo.scrollLeft,
      width = _getScrollInfo.scrollWidth,
      height = _getScrollInfo.scrollHeight,
      sWidth = _getScrollInfo.sceneWidth,
      sHeight = _getScrollInfo.sceneHeight;
    scrollTop.value = top;
    scrollLeft.value = left;
    scrollHeight.value = height;
    scrollWidth.value = width;
    sceneWidth.value = sWidth;
    sceneHeight.value = sHeight;
  };
  var unwatch = watchEffect(function () {
    var el = unref(element);
    if (el !== null && el !== void 0 && el.parentElement) {
      container.value = el.parentElement;
      updateScrollInfo();
      container.value.addEventListener('scroll', updateScrollInfo);
      unwatch();
    }
  });
  onBeforeUnmount(function () {
    var _container$value;
    (_container$value = container.value) === null || _container$value === void 0 ? void 0 : _container$value.removeEventListener('scroll', updateScrollInfo);
  });
  return {
    scrollTop: scrollTop,
    scrollLeft: scrollLeft,
    scrollHeight: scrollHeight,
    scrollWidth: scrollWidth,
    sceneHeight: sceneHeight,
    sceneWidth: sceneWidth,
    container: container
  };
}
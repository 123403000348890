import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import Cookies from 'js-cookie';
export default defineNuxtRouteMiddleware(function (to) {
  var _to$name, _userAttributes$value;
  var _useNuxtApp = useNuxtApp(),
    $config = _useNuxtApp.$config;
  var splitTesting = $config.public.splitTesting;
  if (!splitTesting.branch) return;
  var _storeToRefs = storeToRefs(useUserStore()),
    userId = _storeToRefs.id,
    userAttributes = _storeToRefs.attributes;
  var routeName = (_to$name = to.name) !== null && _to$name !== void 0 ? _to$name : '';
  if (userId && ((_userAttributes$value = userAttributes.value.frontend_version) === null || _userAttributes$value === void 0 ? void 0 : _userAttributes$value[0]) === splitTesting.branch) {
    // Don't set the cookie again if unchanged
    if (Cookies.get('nf_ab') === splitTesting.branch) return;
    var date = new Date();
    var expires = date.getTime() + 1000 * 3600 * 24 * 365;
    date.setTime(expires);
    Cookies.set('nf_ab', splitTesting.branch, {
      expires: date
    });
    window.location.reload();
  } else if (userId && Cookies.get('nf_ab') === splitTesting.branch) {
    // We don't want to interrupt with a reload on auth routes, that will happen on next login if necessary
    if (['login', 'logout'].includes(routeName)) return;

    // Reset to default branch
    Cookies.remove('nf_ab');
    window.location.reload();
  }
});
import "core-js/modules/es.function.name.js";
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';

// TODO: Casing is inconsistent, we should probably fix that

// This is a temporary convenience composable to help us determine what features
// the current user should be allowed to see, for both legacy and new users

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useUserPlanInfo() {
  var businessStore = useBusinessStore();
  var userStore = useUserStore();
  var businessPlanId = computed(function () {
    var _businessStore$priceP;
    return (_businessStore$priceP = businessStore.pricePlan.current.base) === null || _businessStore$priceP === void 0 ? void 0 : _businessStore$priceP.type;
  });
  var activePricePlanId = computed(function () {
    var _userStore$attributes;
    // We need to check for legacy stuff during pricing transition
    var legacyPlanId = businessStore.pricePlan.name;
    if (legacyPlanId) {
      return legacyPlanId;
    }
    if (businessPlanId.value && businessPlanId.value !== 'CANCELED') {
      return businessPlanId.value;
    }
    var freePlanId = (_userStore$attributes = userStore.attributes.free_account_type) === null || _userStore$attributes === void 0 ? void 0 : _userStore$attributes[0];
    if (freePlanId) {
      return freePlanId;
    }
    return null;
  });
  var isLegacyPlan = computed(function () {
    return activePricePlanId.value === 'ent_v2' || activePricePlanId.value === 'sme_v2';
  });
  var isFreePlan = computed(function () {
    // FIXME: Currently, we cannot rely on the backend to consistently handle the `free_account_type` attribute
    return !businessPlanId.value && !userStore.isBusinessMember; // && Boolean(activePricePlanId.value?.toLowerCase().startsWith('free_'))
  });
  var isNewFreePlan = computed(function () {
    // FIXME: Currently, we cannot rely on the backend to consistently handle the `free_account_type` attribute
    return !businessPlanId.value && (activePricePlanId.value === 'free_v1' || activePricePlanId.value === 'free_migrated');
  });
  var planStart = computed(function () {
    var _businessStore$priceP2;
    return (_businessStore$priceP2 = businessStore.pricePlan.current.base) === null || _businessStore$priceP2 === void 0 ? void 0 : _businessStore$priceP2.startAt;
  });
  return {
    activePricePlanId: activePricePlanId,
    isFreePlan: isFreePlan,
    isLegacyPlan: isLegacyPlan,
    isNewFreePlan: isNewFreePlan,
    planStart: planStart
  };
}
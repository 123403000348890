import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  props: {
    source: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: false
    }
  },
  computed: {
    extension: function extension() {
      return this.$canHandleWebP() ? 'webp' : 'jpg';
    },
    imgHeight: function imgHeight() {
      var _this$height;
      return (_this$height = this.height) !== null && _this$height !== void 0 ? _this$height : 'auto';
    }
  }
});
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.map.delete-all.js";
import "core-js/modules/esnext.map.every.js";
import "core-js/modules/esnext.map.filter.js";
import "core-js/modules/esnext.map.find.js";
import "core-js/modules/esnext.map.find-key.js";
import "core-js/modules/esnext.map.includes.js";
import "core-js/modules/esnext.map.key-of.js";
import "core-js/modules/esnext.map.map-keys.js";
import "core-js/modules/esnext.map.map-values.js";
import "core-js/modules/esnext.map.merge.js";
import "core-js/modules/esnext.map.reduce.js";
import "core-js/modules/esnext.map.some.js";
import "core-js/modules/esnext.map.update.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineStore } from 'pinia';
export var useInvitationStore = defineStore('invitation', function () {
  var route = useRoute();
  var invitationSignatureRequests = ref([]);

  // TODO: Can we even have multiple drafts?
  var invitationDrafts = ref(new Map());
  var updateDraft = function updateDraft(draftData) {
    // Workaround until we have full Vue 3 support
    invitationDrafts.value = new Map(invitationDrafts.value.set(draftData.signatureRequest.id, draftData));
  };
  var removeDraft = function removeDraft(id) {
    if (invitationDrafts.value.has(id)) {
      // Workaround until we have full Vue 3 support
      invitationDrafts.value.delete(id);
      invitationDrafts.value = new Map(invitationDrafts.value);
    }
  };
  var draftId = computed(function () {
    if (route.name === 'invite-id') {
      return route.params.id;
    }
    return '';
  });
  var activeDraft = computed(function () {
    var activeDraftId = draftId.value;
    if (activeDraftId && invitationDrafts.value.has(activeDraftId)) {
      return invitationDrafts.value.get(draftId.value);
    }
    return {
      signatureRequest: {
        id: route.params.id,
        title: '',
        message: '',
        documentId: '',
        business: '',
        quality: '',
        signingUrl: '',
        statusOverall: '',
        attachments: [],
        attachOnSuccess: [],
        signatures: [],
        ccEmailAddresses: [],
        owner: '',
        readAccess: [],
        writeAccess: [],
        createdAt: '',
        updatedAt: '',
        expirationDate: '',
        legislation: ''
      },
      options: {
        singleSigner: 1,
        userIsSigning: false,
        signingOrderEnabled: false
      },
      order: {
        invitees: {},
        ordering: []
      }
    };
  });
  return {
    drafts: invitationDrafts,
    activeDraft: activeDraft,
    invitationSignatureRequests: invitationSignatureRequests,
    updateDraft: updateDraft,
    removeDraft: removeDraft
  };
});
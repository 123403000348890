import { objectToCamelCase, objectToSnakeCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export async function sign(signData: SignData, language: Language = 'en', tan?: string): Promise<SignResponse> {
  const response = await $http.$post(
    'v1/direct/sign',
    objectToSnakeCase({
      signatureRequests: [
        {
          signatureRequestId: signData.id,
          visualSignature: signData.visualSignature,
        },
      ],
      language: language as string,
      ...(Boolean(tan) && { tan }),
    })
  )

  return objectToCamelCase<SignResponse>(response)
}

export async function continueSign(continuationId: string, isPwdotp = false): Promise<SignResponse> {
  const response = await $http.$post<{ status: string; message: string }>(
    `/v1/direct/swisscom/proceed/${continuationId}`,
    {
      pwdotp: isPwdotp,
    }
  )

  return objectToCamelCase<SignResponse>(response)
}

export async function getSignatureRequest(id: string): Promise<SignatureRequestData> {
  const response = await $http.$get(`/v1/direct/signature-requests/${id}`)

  return objectToCamelCase<SignatureRequestData>(response)
}

export async function getSignatureRequestEvents(id: string): Promise<SignatureRequestEvent[]> {
  const response = await $http.$get(`/v1/direct/signature-requests/${id}/events`)

  return objectToCamelCase<SignatureRequestEvent[]>(response)
}

export async function getDocument(id: string): Promise<DocumentData> {
  const response = await $http.$get(`/v1/direct/documents/${id}`)

  return objectToCamelCase<DocumentData>(response)
}

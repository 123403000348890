import { $http } from '@/plugins/http'

import { extractFilename } from '../document'

// NB: This mostly mirrors the document repository, but we need to use a different base URL
// and we need to pass the token as a query parameter instead of a header

export async function getDocumentData(
  documentId: string,
  token: string,
  options = { isSigned: false, isInline: false }
): Promise<{ data: Blob; name: string }> {
  const params = new URLSearchParams()
  params.append('token', token)
  if (options.isSigned) params.append('signed', 'true')
  if (options.isInline) params.append('inline', 'true')

  const response = await $http.get(`v1/direct/documents/${documentId}/download${params.size ? `?${params}` : ''}`)

  const data = await response.blob()
  const name = extractFilename(response)

  return {
    data,
    name: name ?? `${documentId}.pdf`,
  }
}

export async function getProtocol(srId: string, type: SignatureProtocolType, token: string): Promise<BlobResponse> {
  const response = await $http.get(`v1/direct/signature-requests/${srId}/report?type=${type}&token=${token}`)

  const data = await response.blob()
  const name = extractFilename(response)

  return {
    data,
    name: name ?? `${srId}_protocol.pdf`,
  }
}

export async function getAttachment(srId: string, attachmentId: string, token: string): Promise<BlobResponse> {
  const response = await $http.get(
    `v1/direct/signature-requests/${srId}/attachments/${attachmentId}/content?token=${token}`
  )

  const data = await response.blob()
  const dataType = data.type.split('/')[1]
  const name = extractFilename(response)

  return {
    data,
    name: name ?? `${srId}_attachment-${attachmentId}.${dataType}`,
  }
}

export async function getDocumentPage(
  documentId: string,
  pageNumber: number,
  parameters: { scale?: number; token: string }
): Promise<Blob> {
  const query = new URLSearchParams({
    scale: parameters.scale?.toString() ?? '100',
    token: parameters.token,
  })
  const response = await $http.get(`v1/direct/documents/${documentId}/pages/${pageNumber}?${query.toString()}`, {
    retry: 10,
  })

  return response.blob()
}

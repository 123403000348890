import type { ActionTree, MutationTree } from 'vuex'

const getInitialState = (): TanState => ({
  linkFlow: false,
  lockedRemaining: 0,
  tanEntry: false,
})

const state = getInitialState

const mutations: MutationTree<TanState> = {
  SET_LOCKED_REMAINING(state, remaining: number) {
    state.lockedRemaining = remaining
  },
  SET_TAN_ENTRY(state) {
    state.tanEntry = true
  },
  RESET_TAN_ENTRY(state) {
    state.tanEntry = false
  },
  SET_LINK_FLOW(state, value: boolean) {
    state.linkFlow = value
  },
}

const actions: ActionTree<TanState, RootState> = {
  check(_, { tan, auth }: { auth: BasicAuth; tan: number }) {
    return this.$axios.$post('/api/direct/tan/check', { tan }, { auth })
  },
  send(
    _,
    {
      url,
      subject,
      company_branding,
      auth,
    }: {
      url: string
      subject: string
      company_branding: string
      auth: BasicAuth
    }
  ) {
    return this.$axios.$post('/api/direct/tan/resend', { url, subject, company_branding }, { auth })
  },
  showLocked({ dispatch, commit }, { remaining }) {
    commit('SET_LOCKED_REMAINING', remaining)
    dispatch('showOverlay', 'tanLocked', { root: true }).catch(() => {
      // TODO: handle error
    })
  },
  setLinkFlow({ commit }) {
    commit('SET_LINK_FLOW', true)
  },
  resetLinkFlow({ commit }) {
    commit('SET_LINK_FLOW', false)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

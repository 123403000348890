import type { StripeElements } from '@stripe/stripe-js'
import { de, enGB, frCH, it } from 'date-fns/locale'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import type { ActionTree, GetterTree, MutationTree } from 'vuex'

import * as actionTypes from '@/store/actions/business'
import * as mutationTypes from '@/store/mutations/business'
import * as businessRepository from '@/repository/business'

// TODO: This should live somewhere else
export class StripePaymentError extends Error {
  code?: string

  constructor(message: string, code?: string) {
    super(message)
    this.code = code
  }
}

const getInitialState = (): BusinessState => ({
  created_at: '',
  billing: {},
  billing_status: '',
  company: {
    name: '',
    billing: {
      address1: '',
      address2: '',
      postal_code: '',
      city: '',
      country: '',
    },
    domains: [],
    email: '',
    phone: '',
    admins: [],
    termsAccepted: false,
    id: '',
    aes4biz: {},
    price_plan: {},
    settings: {},
  },
  defaultPaymentMethod: null,
  seals: [],
  settings: {},
  trial_end: '',
})

const getLocale = (l: string) => {
  const locale = {}

  switch (l) {
    case 'de-ch':
      Object.assign(locale, de)
      break
    case 'fr-ch':
      Object.assign(locale, frCH)
      break
    case 'en-gb':
      Object.assign(locale, enGB)
      break
    case 'it':
      Object.assign(locale, it)
      break
  }

  return locale
}

const state: () => BusinessState = getInitialState

const actions: ActionTree<BusinessState, RootState> = {
  registerBusiness({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post('/api/businesses', data)
        .then(response => {
          commit('SET_BUSINESS', response)
          dispatch('getUser', null, { root: true })
            .then(response => {
              resolve(response)
            })
            .catch(() => {
              // TODO: handle error
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async deleteBusiness({ rootState, commit }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    try {
      commit('RESET_BUSINESS')
      return await this.$axios.delete(`businesses/${businessId}?really`)
    } catch (error: unknown) {
      return new Error(error as string)
    }
  },
  getBusiness({ commit, state }, memberOf) {
    return new Promise<void>((resolve, reject) => {
      // If the user is not a member of any Business and the state is not
      // already blank, commit a reset mutation
      if (typeof memberOf === 'undefined') {
        if (state.company.name !== '') {
          commit('RESET_BUSINESS')
        }
        resolve()
      } else {
        // Otherwise, get business data
        this.$axios
          .$get(`/api/businesses/${memberOf[0]}`)
          .then(response => {
            commit('SET_BUSINESS', response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  async editBusiness(
    { commit, state },
    { businessId, parameter, value }: { businessId: string; parameter: string; value: string | CompanyBilling }
  ) {
    const modifiedBusiness = {
      name: state.company.name,
      billing: state.company.billing,
      email: state.company.email,
      phone: state.company.phone,
    }
    if (parameter === 'country' && typeof value === 'string') {
      modifiedBusiness.billing.country = value
    } else if (parameter === 'support_contact') {
      Object.assign(modifiedBusiness, {
        settings: value,
      })
    } else {
      modifiedBusiness[parameter] = value
    }

    const response = await this.$axios.$put<{ business: Partial<BusinessState> }>(
      `/api/businesses/${businessId}`,
      modifiedBusiness
    )

    commit('SET_BUSINESS', response.business)

    return response
  },
  editAutoRemindSettings(
    { commit },
    {
      businessId,
      intervalSet,
    }: {
      businessId: string
      intervalSet: number[]
    }
  ) {
    return new Promise((resolve, reject) => {
      const settings = {
        signature_requests: {
          signatures: {
            reminder: intervalSet,
          },
        },
      }
      this.$axios
        .$put(`/api/businesses/${businessId}/settings`, settings)
        .then(response => {
          commit('SET_SETTINGS', response.settings)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  editAutoDeletionSettings(
    { commit },
    {
      businessId,
      enabled,
      retentionPeriod,
    }: {
      businessId: string
      enabled?: boolean | undefined
      retentionPeriod: number
    }
  ) {
    // Set default value for enable, if undefined
    enabled ??= true

    return new Promise((resolve, reject) => {
      const settings = {
        signature_requests: {
          retention: {
            enabled,
            delete_after: retentionPeriod,
          },
        },
      }
      this.$axios
        .$put(`/api/businesses/${businessId}/settings`, settings)
        .then(response => {
          commit('SET_SETTINGS', response.settings)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  legacyEditAutoDeletionSettings(
    { commit },
    {
      businessId,
      retentionPeriod,
    }: {
      businessId: string
      retentionPeriod: number
    }
  ) {
    return new Promise((resolve, reject) => {
      const settings = {
        signature_requests: {
          retention: {
            enabled: true,
            terminal_state: true,
            delete_after: retentionPeriod,
          },
        },
      }
      this.$axios
        .$put(`/api/businesses/${businessId}/settings`, settings)
        .then(response => {
          commit('SET_SETTINGS', response.settings)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async getInviteesByOffset(_, { memberOf, limit, offset }: { memberOf: string; limit: number; offset: number }) {
    try {
      return await this.$axios.$get(`/api/businesses/${memberOf}/invitations`, { params: { limit, offset } })
    } catch {}
  },
  async addMembers({ rootState }, data) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const response = await this.$axios.$post<APIErrorOrSuccessBool>(`/api/businesses/${businessId}/members`, data)
    if ('errors' in response && response.errors.length > 0) {
      return Promise.reject(response.errors)
    } else {
      return response
    }
  },
  async removeMember({ rootState }, data) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const response = await businessRepository.removeMembers(businessId, data)
    if ('errors' in response && response.errors.length > 0) {
      return Promise.reject(response.errors)
    } else {
      return response
    }
  },
  async revokeInvite({ rootState }, userID) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const response = await this.$axios.$delete<APIErrorOrSuccessBool>(
      `/api/businesses/${businessId}/invitations/${userID}`
    )
    if ('errors' in response && response.errors.length > 0) {
      return Promise.reject(response.errors)
    } else {
      return response
    }
  },
  async getAdmins({ rootState }): Promise<Admin[]> {
    const businessId = rootState.user?.attributes.member_of?.[0]
    if (!businessId) return []
    const response = await this.$axios.$get<{ admins: Admin[] }>(`/api/businesses/${businessId}/admins`)
    return response.admins
  },
  makeAdmin({ commit, rootState }, data) {
    const businessId = rootState.user?.attributes.member_of?.[0]

    return businessRepository
      .makeAdmin(businessId, data)
      .then(response => {
        commit('SET_BUSINESS', response.business)
        if (response.errors?.length) {
          return Promise.reject(response.errors)
        }
        return response
      })
      .catch(error => Promise.reject(error))
  },
  revokeAdmin({ commit, rootState }, data) {
    const businessId = rootState.user?.attributes.member_of?.[0]

    return businessRepository
      .revokeAdmin(businessId, data)
      .then(response => {
        commit('SET_BUSINESS', response.business)
        if (response.errors?.length) {
          return Promise.reject(response.errors)
        }
        return response
      })
      .catch(error => Promise.reject(error))
  },
  createSubscription({ commit, rootState }, { memberEmail, subscriptionType }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const userBilling = { users: [[memberEmail, subscriptionType]] }
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/api/businesses/${businessId}/subscriptions`, userBilling)
        .then(response => {
          commit('SET_BUSINESS', response.business)
          if (typeof response.errors !== 'undefined' && response.errors.length > 0) reject(response.errors)
          else resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  cancelSubscription({ commit, rootState }, userId) {
    const businessId = rootState.user?.attributes.member_of?.[0]

    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`/api/businesses/${businessId}/subscriptions/${userId}`)
        .then(response => {
          commit('SET_BUSINESS', response.business)
          if (typeof response.errors !== 'undefined' && response.errors.length > 0) reject(response.errors)
          else resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getSettings({ commit, rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    // Only members of a business should request the BA's settings.
    if (businessId) {
      return new Promise<void>((resolve, reject) => {
        this.$axios
          .$get(`/api/businesses/${businessId}/settings`)
          .then(response => {
            commit('SET_SETTINGS', response.settings)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return Promise.resolve
    }
  },
  addDomain({ commit, state, rootState }, domain) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$post(`/api/businesses/${businessId}/domains`, domain)
        .then((response: { business: { domains: string[] } }) => {
          // retrieve new list of domains
          // everything else should have stayed the same
          const updatedBiz = state.company
          updatedBiz.domains = response.business.domains
          commit('SET_BUSINESS', updatedBiz)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removeDomain({ commit, state, rootState }, domain) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    this.$axios
      .$delete(`/api/businesses/${businessId}/domains/${encodeURIComponent(domain)}`)
      .then((response: { business: { domains: string[] } }) => {
        // retrieve new list of domains
        // everything else should have stayed the same
        const updatedBiz = state.company
        updatedBiz.domains = response.business.domains
        commit('SET_BUSINESS', updatedBiz)
      })
      .catch(() => {
        // TODO: handle error
      })
  },
  getApiUsers({ rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$get(`/api/businesses/${businessId}/apis`)
  },
  createApiUser({ rootState }, data) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/apis`, data)
  },
  deleteApiUser({ rootState }, username) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$delete(`/api/businesses/${businessId}/apis/${username}`)
  },
  resetApiUser({ rootState }, username) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$put(`/api/businesses/${businessId}/apis/${username}/reset`)
  },
  updateApiUser({ rootState }, { username, ...data }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$put(`/api/businesses/${businessId}/apis/${username}`, data)
  },
  cancelTrial({ rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/cancel-trial`)
  },
  cancelBizSubscription({ rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/cancel-subscription`)
  },
  reactivateBizSubscription({ rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/reactivate-subscription`)
  },
  /**
   * In the response there are two arrays of payment methods:
   * 'cards' and 'sepa_debits'. There is only one method flagged as
   * default_payment_method. This function also stores this payment method
   * to the state as: "defaultMethod".
   */
  async getStripePaymentMethods({ commit, rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const response: Payment = await this.$axios.$get(`/api/businesses/${businessId}/billing/payment`)
    let defaultMethod: DefaultPaymentMethod | null = null
    for (const paymentInformation of response.cards) {
      if (paymentInformation.default_payment_method === true) {
        defaultMethod = {
          type: 'card',
          paymentInformation,
        }
      }
    }
    if (!defaultMethod) {
      for (const paymentInformation of response.sepa_debits) {
        if (paymentInformation.default_payment_method === true) {
          defaultMethod = {
            type: 'sepa',
            paymentInformation,
          }
        }
      }
    }
    commit('SET_DEFAULT_PAYMENT_METHOD', defaultMethod)
    return response
  },
  getBilling({ commit, rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$get(`/api/businesses/${businessId}/billing/information`)
        .then(response => {
          /**
           * This won't have a value if the customer doesn't
           * pay via stripe (e.g. old enterprise customers)
           */
          commit('SET_BILLING', response)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // gets seals with information for business where user is admin of
  getSealsByBusiness({ commit, rootState }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    // handle if businessId not present (e2e tests for example):
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (!businessId) return Promise.reject(new Error('businessID not present'))

    const endpointUrl = `/api/businesses/${businessId}/seals`
    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$get(endpointUrl)
        .then(
          (response: {
            seals: {
              name: string
              label: string
              n_api: number
              n_members: number
            }[]
          }) => {
            const mappedSeals = response.seals.map(seal => {
              return {
                accountName: seal.name,
                displayName: seal.label,
                amountApiKeys: seal.n_api,
                amountMembers: seal.n_members,
              }
            })
            commit('SET_SEALS', mappedSeals)
            resolve()
          }
        )
        .catch(error => {
          reject(error)
        })
    })
  },
  getStripeSetupIntent({ rootState }, { country }: { country?: string }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/billing/setup-intent`, { country })
  },
  confirmStripeSetupIntent({ rootState }, { id, country }: { id: string; country?: string }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/billing/setup-intent/${id}`, { country })
  },
  async getAndConfirmStripeSetupIntent(
    { dispatch },
    {
      billingDetails,
      stripeElements,
      country,
    }: {
      billingDetails: StripeBillingDetails
      stripeElements: StripeElements
      country: string
    }
  ) {
    await dispatch('setupStripe', null, { root: true })
    if (!this.app.$stripe) throw new StripePaymentError('Stripe not found', 'general')

    const returnUrl = window.location.href

    const response = (await this.app.$stripe.confirmSetup({
      // `Elements`
      elements: stripeElements,
      confirmParams: {
        return_url: returnUrl,
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
      redirect: 'if_required',
    })) as undefined | { setupIntent: { id: string } } | { error: { message: string; code: string } }

    if (response && 'error' in response) {
      throw new StripePaymentError(response.error.message ?? 'General Stripe error', response.error.code)
    }

    const id = response?.setupIntent?.id

    if (id) await dispatch('confirmStripeSetupIntent', { id, country })
  },
  setBillingCycle({ rootState }, data) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    return this.$axios.$post(`/api/businesses/${businessId}/billing/cycle`, data)
  },
  async [actionTypes.FETCH_SETTINGS]({ commit }, { businessId }) {
    const payload: { settings: SettingsPayload } = await this.$axios.$get(`/api/businesses/${businessId}/settings`)
    commit(mutationTypes.SET_SETTINGS, payload.settings)
    return payload.settings
  },
  async [actionTypes.SAVE_SETTINGS]({ commit }, { businessId, settings }) {
    const payload: { settings: SettingsPayload } = await this.$axios.$put(
      `/api/businesses/${businessId}/settings`,
      settings
    )
    commit(mutationTypes.SET_SETTINGS, payload.settings)
  },
}

const mutations: MutationTree<BusinessState> = {
  SET_BUSINESS(state, business) {
    // TODO: clean duplicated company keys
    Object.assign(state.company, business as Partial<Company>)
    Object.assign(state, {
      created_at: (business.created_at as string) || '',
      trial_end: (business.trial_end as string) || '',
      billing_status: (business.billing_status as string) || '',
    })
  },
  RESET_BUSINESS(state) {
    Object.assign(state, getInitialState())
  },
  SET_SETTINGS(state, settings: BusinessSettings) {
    state.settings = settings
  },
  SET_BILLING(state, billing: Partial<BusinessBilling>) {
    state.billing = billing
  },
  SET_DEFAULT_PAYMENT_METHOD(state, defaultPaymentMethod: DefaultPaymentMethod) {
    state.defaultPaymentMethod = defaultPaymentMethod
  },
  SET_SEALS(state, seals: BusinessSealListItem[]) {
    state.seals = seals
  },
}

const getters: GetterTree<BusinessState, RootState> = {
  isPrePricing2023(state) {
    return !(state.company?.price_plan as PricePlanInfo)?.current?.base?.type
  },
  companyIsSME: state => {
    if (state.company.price_plan.name) {
      return state.company.price_plan.name.substring(0, 3) === 'sme'
    } else {
      return false
    }
  },
  companyIsEnterprise: state => {
    if (state.company.price_plan.name) {
      return state.company.price_plan.name.substring(0, 3) === 'ent'
    } else {
      return false
    }
  },
  // DateTime from backend comes in UTC
  createdAtUtc: state => {
    if (!state.created_at) return null
    return utcToZonedTime(state.created_at, Intl.DateTimeFormat().resolvedOptions().timeZone, {
      timeZone: 'UTC',
    })
  },
  billingCycle: state => {
    return state.company.price_plan.billing_cycle
  },
  billingStatus: state => {
    return state.billing_status
  },
  companyHasAES4BizEnabled: state => {
    if (state.company.aes4biz.enabled) {
      return state.company.aes4biz.enabled
    } else {
      return false
    }
  },
  companyHasManualInvoicingEnabled: state => {
    return state.billing_status === 'manual' && !state.company.settings.stripe?.status
  },
  isBusinessInTrial: state => {
    return state.billing_status === 'trialing'
  },
  isBusinessLocked: state => {
    return state.billing_status === 'needs_payment_method'
  },
  companyHasAutoInvoicingEnabled: state => {
    return typeof state.company.settings.stripe?.status === 'string'
  },
  companyHasAutoDeletionEnabled: state => {
    return state.settings?.signature_requests?.retention?.enabled
  },
  companyHasLegacyAutoDeletion: state => {
    return state.settings?.signature_requests?.retention?.terminal_state
  },
  defaultPaymentMethod: state => {
    return state.defaultPaymentMethod
  },
  userIsMember: (_, _0, rootState) => {
    let isBusinessMember = false
    if (rootState.user) {
      if (rootState.user.emailVerified) {
        // TODO: Change to verify that the ID is the one of current business, not just if an id exists
        isBusinessMember =
          typeof rootState.user.attributes.member_of !== 'undefined' && rootState.user.attributes.member_of.length > 0
      }
    }
    return isBusinessMember
  },
  userIsAdmin: (_, _0, rootState) => {
    let isBusinessAdmin = false
    if (rootState.user) {
      if (rootState.user.emailVerified) {
        // TODO: Change to verify that the ID is the one of current business, not just if an id exists
        isBusinessAdmin =
          typeof rootState.user.attributes.admin_of !== 'undefined' && rootState.user.attributes.admin_of.length > 0
      }
    }
    return isBusinessAdmin
  },
  userIsOnlyAdmin: (state, getters) => {
    return getters.userIsAdmin && state.company.admins.length === 1
  },
  domains: state => {
    return state.company.domains
  },
  branding: state => {
    if ('company_branding' in state.company.settings) {
      if (state.company.settings.company_branding?.logo !== undefined) {
        return state.company.settings.company_branding
      } else {
        return Object.assign({}, state.company.settings.company_branding, {
          logo: { url: '' },
        })
      }
    }
    return {
      logo: {
        url: '',
      },
    }
  },
  billing: state => {
    return state.billing
  },
  seals: state => {
    return state.seals
  },
  hasPaymentIssues: state => {
    return !!(
      state.stripe?.subscriptionStatus && !['active', 'incomplete_expired'].includes(state.stripe?.subscriptionStatus)
    )
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

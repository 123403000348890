import { defineStore } from 'pinia'

interface AuthData {
  token: {
    expiration: string
    value: string
  }
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthData => ({
    token: {
      expiration: '',
      value: '',
    },
  }),
  actions: {
    /**
     * Stores the token in the session or local storage.
     *
     * Tokens can never live longer than 2 days (2 * 24 * 60 * 60 * 1000 = 172800000),
     * but they can be limited to the user's browsing session or be kept open for
     * later usage using localStorage.
     *
     * @param token
     * @param stayLoggedIn
     */
    storeToken(token: string, stayLoggedIn = false) {
      this.$nuxt.$axios.setToken(token, 'bearer')
      this.$nuxt.$http.setToken(token, 'bearer')

      const stringified = JSON.stringify({
        value: token,
        expiration: Date.now() + 172800000,
      })

      if (stayLoggedIn) {
        localStorage.setItem('accessToken', stringified)
      } else {
        sessionStorage.setItem('accessToken', stringified)
      }
    },
    getStoredToken() {
      const stringified = sessionStorage.getItem('accessToken') ?? localStorage.getItem('accessToken')

      if (!stringified) {
        // We have no token found but it could still be stored in sessionStorage
        // in another tab as they don't share sessionStorage among them. Let's
        // communicate via storage events based on
        // https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs.
        localStorage.setItem('getSessionStorage', 'syncTabs')
        localStorage.removeItem('getSessionStorage')

        return null
      }

      const token = JSON.parse(stringified) as { expiration: number; value: string }

      if (token.expiration > Date.now()) {
        return token.value
      } else {
        return null
      }
    },
    removeStoredToken() {
      this.$nuxt.$axios.setToken(false)
      this.$nuxt.$http.setToken(false)

      sessionStorage.removeItem('accessToken')
      localStorage.removeItem('accessToken')

      // Tell other tabs to remove their sessionStorage tokens as well.
      localStorage.setItem('removeSessionStorage', 'syncTabs')
      localStorage.removeItem('removeSessionStorage')

      this.$reset()
    },
  },
})

/*
 * checks if the user is member of a business
 * otherwise redirects all /business/* paths to /business (registration steps)
 */
import type { Middleware } from '@nuxt/types'

import { useUserStore } from '@/stores/user'

const business: Middleware = ({ store, route, redirect }) => {
  // routes available if user is NOT member of business
  const routesForNonBusinessMembers = [
    'business', // This will now be redirected by default
    'business-register',
    'business-signup',
    'business-signup-mail-sent',
    'business-signup-steps',
  ]

  // As the base route component was removed, we need to check the corresponding path instead
  if (/\/business\/?$/.test(route.path)) {
    // Set the appropriate name to not mess up existing logic
    route.name = 'business'
  }

  // The `/business/members/activity` page was removed because it was a duplicate of
  // `business/activity`, so we redirect there to be safe
  if (route.path === '/business/members/activity') {
    redirect('/business/activity')
    return
  }

  const userStore = useUserStore()

  // if route is part of business routes
  if (route.name && route.name.startsWith('business')) {
    // if user IS business admin
    if (userStore.isBusinessAdmin) {
      // if route is for NON business members send to profile
      if (routesForNonBusinessMembers.includes(route.name)) {
        redirect('/business/profile')
      }
    } else if (userStore.isBusinessMember) {
      // if user IS a member, but NOT admin redirect back to regular skribble
      void store.dispatch('setSnackbar', {
        message: { key: 'business.not_allowed' },
      })
      redirect('/')
    } else if (!routesForNonBusinessMembers.includes(route.name)) {
      // if user is NOT business member and route is NOT available for non business members send to biz registration screen
      redirect('/business/register')
    } else if (route.name === 'business') {
      // For regular users, redirect directly to the registration screen
      redirect('/business/register')
    }
  }
}

export default business

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export function getOffset(element, container) {
  var offsets = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  };
  var currentElement = element;
  while (currentElement) {
    if (currentElement === container) {
      break;
    }
    offsets.top += currentElement.offsetTop;
    offsets.right += currentElement.offsetLeft + currentElement.clientWidth;
    offsets.left += currentElement.offsetLeft;
    offsets.bottom += currentElement.offsetTop + currentElement.clientHeight;
    currentElement = currentElement.parentElement;
  }
  return offsets;
}
export default function useElementSize(element, offsetContainer) {
  var width = ref(0);
  var height = ref(0);
  var offsetTop = ref(0);
  var offsetRight = ref(0);
  var offsetLeft = ref(0);
  var offsetBottom = ref(0);
  var observer = new ResizeObserver(function (entries) {
    var _iterator = _createForOfIteratorHelper(entries),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _unref;
        var entry = _step.value;
        var _entry$contentRect = entry.contentRect,
          w = _entry$contentRect.width,
          h = _entry$contentRect.height;
        width.value = w;
        height.value = h;
        var targetElement = entry.target;
        var offsets = getOffset(targetElement, (_unref = unref(offsetContainer)) !== null && _unref !== void 0 ? _unref : document.body);
        offsetTop.value = offsets.top;
        offsetRight.value = offsets.right;
        offsetLeft.value = offsets.left;
        offsetBottom.value = offsets.bottom;
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  });
  watchEffect(function () {
    var el = unref(element);
    if (el) {
      width.value = el.clientWidth;
      height.value = el.clientHeight;
      observer.observe(el);
    }
  });
  onUnmounted(function () {
    observer.disconnect();
  });
  return {
    width: width,
    height: height,
    offsetTop: offsetTop,
    offsetRight: offsetRight,
    offsetLeft: offsetLeft,
    offsetBottom: offsetBottom
  };
}
import { defineStore } from 'pinia'

import { getDateTime } from '@/repository/util'

interface UtilData {
  now: string
  lastUpdatedNow: number
}

export const useUtilStore = defineStore('util', {
  state: (): UtilData => ({
    now: '',
    lastUpdatedNow: 0,
  }),
  actions: {
    async updateTime() {
      if ((Date.now() - this.lastUpdatedNow) * 0.001 < 2) return
      const { now } = await getDateTime()
      this.now = now
      this.lastUpdatedNow = Date.now()
    },
  },
})

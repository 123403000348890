import "core-js/modules/es.regexp.exec.js";
export var useSystemStore = defineStore('system', function () {
  var _useNuxtApp = useNuxtApp(),
    $config = _useNuxtApp.$config;
  var domainName = computed(function () {
    return $config.public.domainName;
  });
  var platform = computed(function () {
    if (/\.(de|eu)$/.test(domainName.value)) {
      return 'EU';
    } else return 'CH';
  });
  var emails = reactive({
    support: 'support@' + domainName.value,
    contact: 'contact@' + domainName.value,
    success: 'success@' + domainName.value
  });
  var redirectUrls = reactive({
    homepage: 'https://' + domainName.value
  });
  return {
    emails: emails,
    redirectUrls: redirectUrls,
    platform: platform
  };
});
import "core-js/modules/es.function.name.js";
// This is to prevent malicious actors from spooking users about their account being deleted

export default defineNuxtRouteMiddleware(function (to) {
  if (to.name === 'bye') {
    var userHasJustBeenDeleted = sessionStorage.getItem('userHasJustBeenDeleted') === 'true';
    if (userHasJustBeenDeleted) return;
    return navigateTo({
      name: 'index'
    });
  }
});
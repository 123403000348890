import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.object.assign.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { Userpilot } from 'userpilot';
export default defineNuxtPlugin(function (nuxtApp) {
  var userpilotActive = false;
  var additionalData = {};
  var anonymousUser = function anonymousUser() {
    Userpilot.anonymous();
    Userpilot.reload();
  };
  var reload = function reload() {
    if (!userpilotActive) return;
    var _useUserStore = useUserStore(),
      userId = _useUserStore.id,
      isBusinessAdmin = _useUserStore.isBusinessAdmin,
      email = _useUserStore.email,
      signerIdentityEmail = _useUserStore.signerIdentityEmail;
    if (!userId) return anonymousUser();
    var _useLanguageStore = useLanguageStore(),
      language = _useLanguageStore.language;
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId,
      planStart = _useUserPlanInfo.planStart;
    var _useBusinessStore = useBusinessStore(),
      business = _useBusinessStore.id;
    var data = _objectSpread({
      email: signerIdentityEmail || email,
      // first value is used by NAS for emails, otherwise use the email
      business: business,
      username: userId,
      role: isBusinessAdmin ? 'admin' : 'user',
      plan: activePricePlanId.value,
      locale_code: language,
      plan_start: planStart.value
    }, additionalData);
    Userpilot.identify(userId, data);
    Userpilot.reload();
  };
  var initialize = function initialize() {
    var _settings$userPilot, _nuxtApp$$config, _nuxtApp$$config$publ, _nuxtApp$$config$publ2;
    var _useBusinessStore2 = useBusinessStore(),
      settings = _useBusinessStore2.settings;
    var _useUserPlanInfo2 = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo2.activePricePlanId;
    var isFreeLegacy = activePricePlanId.value === 'free_legacy';
    var enabled = (isFreeLegacy || Boolean((_settings$userPilot = settings.userPilot) === null || _settings$userPilot === void 0 ? void 0 : _settings$userPilot.enabled)) && Boolean((_nuxtApp$$config = nuxtApp.$config) === null || _nuxtApp$$config === void 0 ? void 0 : (_nuxtApp$$config$publ = _nuxtApp$$config.public) === null || _nuxtApp$$config$publ === void 0 ? void 0 : (_nuxtApp$$config$publ2 = _nuxtApp$$config$publ.features) === null || _nuxtApp$$config$publ2 === void 0 ? void 0 : _nuxtApp$$config$publ2.userpilot);
    if (enabled === userpilotActive) {
      // ... nothing to do
    } else if (enabled) {
      Userpilot.initialize('NX-b832279d');
      userpilotActive = true;
      reload();
    } else {
      Userpilot.destroy();
      userpilotActive = false;
    }
  };
  var setData = function setData(data) {
    Object.assign(additionalData, data);
  };
  nuxtApp.provide('userpilot', {
    initialize: initialize,
    reload: reload,
    setData: setData
  });
});
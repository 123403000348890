import { objectToCamelCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

interface GetDirectUserResponse {
  attributes: {
    lang: Language[]
    immutable_eid_fields: string[]
    signer_identity_email: string[]
    visual_signature_name: string[]
    visual_signature_display_qr: string[]
    visual_signature_display_claim: string[]
    visual_signature_display_standards: string[]
    avatar: string[]
    email_notification_level: string[]
  }
  emailVerified: boolean
  username: string
  retention: number
  disabled_qualities: string[]
  has_sso: boolean
  email: string
}

// const attributesSchema = object({
//   lang: string(),
//   signerIdentityEmail: string(),
// })

export async function getUser(): Promise<Partial<IUser>> {
  const response = await $http.$get<GetDirectUserResponse>('/v1/direct/user')

  const baseData = { ...objectToCamelCase(response), attributes: response.attributes }

  const signatureQualityResponse = await $http.$get<SignatureQualities>('v1/direct/user/signature-qualities-detail')

  const signatureQualityData = {
    ...objectToCamelCase<SignatureQualityData>(signatureQualityResponse),
    seal: signatureQualityResponse.seal,
  }

  return {
    ...baseData,
    id: baseData.username,
    signatureQualities: signatureQualityData,
  }
}

export async function updateUser(data: DirectUserUpdateData): Promise<void> {
  await $http.$put('v1/direct/user', data)
}

import { $http } from '@/plugins/http'

export async function decline(id: string, message?: string): Promise<{ status: DocumentActionResult }> {
  return await $http.$post(`/v1/signature-requests/${id}/decline`, { message })
}

export async function withdraw(id: string, message?: string): Promise<{ status: DocumentActionResult }> {
  return await $http.$post(`/v1/signature-requests/${id}/withdraw`, { message })
}

export async function remove(id: string): Promise<{ status: DocumentActionResult }> {
  return await $http.$delete(`/v1/signature-requests/${id}`)
}

export async function remindSigners(id: string): Promise<void> {
  const response = await $http.post(`/v1/signature-requests/${id}/remind`)
  if (!response.ok) throw new Error('Failed to remind signers')
}

export async function delegate(
  srId: string,
  delegateData: SignatureDelegateData
): Promise<{ status: DocumentActionResult }> {
  return await $http.$post(`/v1/signature-requests/${srId}/delegate`, delegateData)
}

export async function inviteSigner(srId: string, email: string): Promise<void> {
  const response = await $http.post(`/v2/signature-requests/${srId}/signatures`, {
    account_email: email,
    signer_identity_data: {
      email_address: email,
    },
  })
  if (!response.ok) throw new Error('Failed to invite signer')
}

export async function removeSigner(id: string, signatureId: string): Promise<{ status: DocumentActionResult }> {
  return await $http.$delete(`/v1/signature-requests/${id}/signatures/${signatureId}`)
}

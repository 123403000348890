import Vue from 'vue'
import VueSanitize from 'vue-sanitize'

const defaultOptions = {
  allowedTags: ['a', 'b', 'br', 'em', 'li', 'p', 'strong', 'ul'],
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
Vue.use(VueSanitize, defaultOptions)
Vue.config.productionTip = false

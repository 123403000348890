import { objectToSnakeCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export interface CustomImageData {
  contentType: string
  content: string
}

export async function setSignatureImage(signatureId: string, imageData: CustomImageData): Promise<void> {
  await $http.$post(`/v1/images/signature/${signatureId}`, objectToSnakeCase(imageData))
}

export async function deleteSignatureImage(signatureId: string): Promise<void> {
  await $http.delete(`/v1/images/signature/${signatureId}`)
}

export async function getPredefinedVisualSignature(
  signatureId: string,
  params: Record<Extract<VisualSignatureParam, 'qual' | 'leg' | 'prov'>, string>
): Promise<Blob> {
  const query = new URLSearchParams(params)
  const response = await $http.get(`/v1/user/visual-signatures/${signatureId}?${query.toString()}`)
  return await response.blob()
}

export async function generateVisualSignature(params: Record<VisualSignatureParam, string>): Promise<Blob> {
  const query = new URLSearchParams(params)
  const response = await $http.get(`/v1/user/visual-signature?${query.toString()}`)
  return await response.blob()
}

export async function generatePlaceholderVisualSignature(params: Record<VisualSignatureParam, string>): Promise<Blob> {
  const query = new URLSearchParams(params)
  const response = await $http.get(`/v1/user/preview-visual-signature?${query.toString()}`)
  return await response.blob()
}

export async function generatePreviewVisualSignature(
  params: Record<VisualSignatureParam, string>,
  imageData?: CustomImageData | null
): Promise<Blob> {
  const query = new URLSearchParams(params)
  const response = await $http.post(
    `/v1/user/preview-visual-signature?${query.toString()}`,
    imageData ? { image: objectToSnakeCase(imageData) } : {}
  )
  return await response.blob()
}

interface GetVisualSignaturesResponse {
  default_signature: string // The ID of the default signature in the `signatures` array
  signatures: {
    id: string
    alias: string
    name?: string
    optional_line?: string
    location?: string
    display_date: boolean
    display_claim: boolean
    display_standards: boolean
    default: boolean
    has_custom_image?: boolean
  }[]
}

export async function getVisualSignatures(): Promise<{
  defaultSignature: string
  signatures: VisualSignatureDetails[]
}> {
  const response = await $http.$get<GetVisualSignaturesResponse>('/v1/user/visual-signatures')

  const signatures = response.signatures.map<VisualSignatureDetails>(signature => ({
    id: signature.id,
    alias: signature.alias,
    name: signature.name ?? '',
    company: signature.optional_line ?? '',
    location: signature.location ?? '',
    hasCustomImage: Boolean(signature.has_custom_image), // FIXME: Backend sends `null` instead of `false`
    displayDate: Boolean(signature.display_date), // FIXME: Backend may send `null` instead of `false`
    displayClaim: Boolean(signature.display_claim), // FIXME: Backend may send `null` instead of `false`
    displayStandards: Boolean(signature.display_standards), // FIXME: Backend may send `null` instead of `false`
  }))

  return {
    defaultSignature: response.default_signature,
    signatures,
  }
}

export async function createVisualSignature(
  data: Omit<VisualSignatureDetails, 'id'> & { customImage?: CustomImageData }
): Promise<void> {
  // TODO: Make this more DRY
  const payload = {
    alias: data.alias,
    name: data.name,
    optional_line: data.company,
    location: data.location,
    display_date: data.displayDate,
    display_claim: data.displayClaim,
    display_standards: data.displayStandards,
  } as Record<string, unknown>

  if (data.customImage) {
    payload.custom_image = {
      content_type: data.customImage.contentType,
      content: data.customImage.content,
    }
  }

  await $http.$post('/v1/user/visual-signatures', payload)
}

export async function updateVisualSignature(
  signatureId: string,
  data: Partial<Omit<VisualSignatureDetails, 'id'>>
): Promise<void> {
  const payload = {
    alias: data.alias,
    name: data.name,
    optional_line: data.company,
    location: data.location,
    display_date: data.displayDate,
    display_claim: data.displayClaim,
    display_standards: data.displayStandards,
    has_custom_image: data.hasCustomImage,
    default: data.isDefault,
  }

  await $http.$put(`/v1/user/visual-signatures/${signatureId}`, payload)
}

export async function deleteVisualSignature(signatureId: string): Promise<void> {
  await $http.$delete(`/v1/user/visual-signatures/${signatureId}`)
}

export async function getSealImage(businessId: string, accountName: string): Promise<Blob> {
  const response = await $http.get(`/v2/businesses/${businessId}/seals/${accountName}/image`)

  return response.blob()
}

import { defineStore } from 'pinia'

export const useSnackbarStore = defineStore('snackbar', {
  actions: {
    async setSnackbar(data: Partial<SnackBar>) {
      await this.$nuxt.store.dispatch('setSnackbar', data)
    },
    async clearSnackbar(onlyPermanent = false) {
      await this.$nuxt.store.dispatch('resetSnackbar', onlyPermanent)
    },
  },
})

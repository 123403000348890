/*
 * Checks if the url points to view and has an exitURL query param and
 * stores exitURL for the current session. If errorURL and/or declineURL
 * are supplied as well, they get stored too.
 */

import type { Middleware } from '@nuxt/types'

/**
 * javascript: is a bad time, can be used by malicious actors
 * but this can be obfuscated in too many ways for blacklisting
 * so instead use a whitelist to only allow URLS starting with http: or https:
 */
const skipInvalidURL = (value: string | undefined) =>
  value && (value.startsWith('http:') || value.startsWith('https:')) ? value : undefined

/* maybe the url doesn't exist, check for it here */
const valueOrUndef = (url: string | (string | null)[]) => (Array.isArray(url) ? url[0] : url) ?? undefined

/* wrap the two fns in a convenience fn */
const parseURL = (url: string | (string | null)[]) => skipInvalidURL(valueOrUndef(url))

const exitURL: Middleware = ({ store, route }) => {
  if (route.name === 'view-srid' || route.name === 'view-id-userid') {
    if (route.query.exitURL || route.query.exiturl || route.query.exitUrl) {
      const signatureRequest = route.params.srid || route.params.id

      const exitURL = parseURL(route.query.exitURL || route.query.exiturl || route.query.exitUrl)

      // exitURL can be overwritten with specific errorURL for error case
      const errorURL = parseURL(route.query.errorURL || route.query.errorurl || route.query.errorUrl) || exitURL

      // exitURL can be overwritten with specific declineURL for decline case
      const declineURL = parseURL(route.query.declineURL || route.query.declineurl || route.query.declineUrl) || exitURL

      // The duration until an automatic redirection takes place can be
      // set in seconds by a URL parameter named 'redirectTimeout'.
      // This field cannot be set higher than 90s and defaults to 45s.
      // A value of 0 or less is equivalent to an automatic redirect
      let timeout = 45
      if (typeof route.query.redirectTimeout === 'string' || typeof route.query.redirecttimeout === 'string') {
        let tmpTimeout = parseInt(route.query.redirectTimeout?.toString() || route.query.redirecttimeout?.toString())
        // Timeout needs to be a number otherwise we ignore it.
        if (!isNaN(tmpTimeout)) {
          // Enforce maximum time constraint.
          tmpTimeout = Math.min(tmpTimeout, 90)

          timeout = tmpTimeout
        }
      }

      const declineRedirect =
        declineURL && (route.query.declineredirect || route.query.declineRedirect) ? true : undefined

      const exitURLs = {
        signatureRequest,
        exitURL,
        errorURL,
        declineURL,
        timeout,
        declineRedirect,
      }

      void store.dispatch('setExitURLs', exitURLs)
    }
  }
}

export default exitURL

export default function useDragDrop(element, dragImage, dragData, dragHandlers) {
  var isDragging = ref(false);
  var dragId = ref('');
  var referenceElement = computed(function () {
    return unref(element);
  });
  var cleanUp = function cleanUp() {
    if (dragImage && dragId.value) {
      var dragImageElement = document.querySelector("#".concat(dragId.value));
      if (dragImageElement) {
        document.body.removeChild(dragImageElement);
        dragId.value = '';
      }
    }
    document.body.style.cursor = 'auto';
  };
  var onDragStart = function onDragStart(event) {
    isDragging.value = true;
    document.body.style.cursor = 'grabbing';
    if (dragImage) {
      var dragImageElement = unref(dragImage);
      if (dragImageElement && referenceElement.value) {
        var _event$dataTransfer;
        dragId.value = "drag-image-".concat(Date.now());
        dragImageElement.id = dragId.value;
        document.body.appendChild(dragImageElement);
        var _referenceElement$val = referenceElement.value.getBoundingClientRect(),
          x = _referenceElement$val.x,
          y = _referenceElement$val.y;
        (_event$dataTransfer = event.dataTransfer) === null || _event$dataTransfer === void 0 ? void 0 : _event$dataTransfer.setDragImage(dragImageElement, event.x - x, event.y - y);
      }
    }
    if (dragData) {
      var _referenceElement$val2, _referenceElement$val3, _event$dataTransfer2;
      var data = unref(dragData);
      var _ref = (_referenceElement$val2 = (_referenceElement$val3 = referenceElement.value) === null || _referenceElement$val3 === void 0 ? void 0 : _referenceElement$val3.getBoundingClientRect()) !== null && _referenceElement$val2 !== void 0 ? _referenceElement$val2 : {
          x: 0,
          y: 0
        },
        _x = _ref.x,
        _y = _ref.y;
      data.offsetX = event.x - _x;
      data.offsetY = event.y - _y;
      (_event$dataTransfer2 = event.dataTransfer) === null || _event$dataTransfer2 === void 0 ? void 0 : _event$dataTransfer2.setData('text/plain', JSON.stringify(data));
    }
  };
  var onDragEnd = function onDragEnd() {
    isDragging.value = false;
    cleanUp();
  };
  onMounted(function () {
    if (!referenceElement.value) return;
    referenceElement.value.addEventListener('dragstart', onDragStart);
    referenceElement.value.addEventListener('dragend', onDragEnd);
    if (dragHandlers !== null && dragHandlers !== void 0 && dragHandlers.onDragOver) {
      referenceElement.value.addEventListener('dragover', dragHandlers.onDragOver);
    }
  });
  onBeforeUnmount(function () {
    if (!referenceElement.value) return;
    referenceElement.value.removeEventListener('dragstart', onDragStart);
    referenceElement.value.removeEventListener('dragend', onDragEnd);
    if (isDragging.value) {
      cleanUp();
    }
    if (dragHandlers !== null && dragHandlers !== void 0 && dragHandlers.onDragOver) {
      referenceElement.value.removeEventListener('dragover', dragHandlers.onDragOver);
    }
  });
  return {
    isDragging: isDragging
  };
}
import { objectToCamelCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export type SuggestionType = 'recent' | 'prefix' | 'suggest' | 'domain' | 'exact' | 'none'

interface EmailSuggestionsResponse {
  input: string
  type: SuggestionType
  values: string[]
}

export async function getEmailSuggestions(input: string): Promise<EmailSuggestionsResponse> {
  const response = await $http.$get<EmailSuggestionsResponse>(`/v1/email_input_autocomplete?input=${input}`)

  return objectToCamelCase(response)
}

import { format } from 'date-fns';
import { de, enGB, frCH, it } from 'date-fns/locale';
import { useI18n } from 'nuxt-i18n-composable';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useDate() {
  // This looks more cumbersome than it should,
  // but TS fails to handle this properly
  var i18n = useI18n();
  var localeData = computed(function () {
    switch (i18n.locale.value) {
      case 'de-ch':
        return {
          config: de,
          format: 'd. LLLL yyyy'
        };
      case 'fr-ch':
        return {
          config: frCH,
          format: 'd LLLL yyyy'
        };
      case 'it':
        return {
          config: it,
          format: 'd LLLL yyyy'
        };
      case 'en-gb':
      default:
        return {
          config: enGB,
          format: 'd LLLL yyyy'
        };
    }
  });
  var formatDate = function formatDate(date, pattern) {
    return format(date, pattern !== null && pattern !== void 0 ? pattern : localeData.value.format, {
      locale: localeData.value.config
    });
  };
  var localeToLocaleForDateFns = function localeToLocaleForDateFns(i18nLocale) {
    switch (i18nLocale) {
      case 'de-ch':
        return de;
      case 'fr-ch':
        return frCH;
      case 'en-gb':
        return enGB;
      case 'it':
        return it;
      default:
        return enGB;
    }
  };
  return {
    formatDate: formatDate,
    localeToLocaleForDateFns: localeToLocaleForDateFns
  };
}
/*
 * Middleware to handle maintenance windows
 *
 * Define windowStart and windowEnd during which the maintenance page will be
 * shown to users. Outside this period users will be sent to / when trying to
 * access /maintenance to prevent users being fooled into thinking Skribble is
 * in maintenance mode when it is actually not by sending a link to this page.
 */

import { Middleware } from '@nuxt/types'

const maintenanceWindow: Middleware = ({ route, redirect }) => {
  // // Start and end of maintenance window in UTC
  // const windowStart = Date.parse('2020-11-28T17:00:00.000Z')
  // const windowEnd = Date.parse('2020-11-28T21:30:00.000Z')

  // const now = Date.now()
  // const maintenanceOngoing = now >= windowStart && now < windowEnd

  const maintenanceOngoing = false

  if (maintenanceOngoing) {
    // Redirect everything to /maintenance during maintenance windows
    if (route.name !== 'maintenance') {
      redirect('/maintenance')
    }
  } else if (route.name === 'maintenance') {
    // Redirect /maintenance to /
    redirect('/')
  }
}

export default maintenanceWindow

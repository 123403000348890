import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import DialogControl from '@/components/dialogs/DialogControl.vue';
import OverlayControl from '@/components/overlays/OverlayControl.vue';
import SiteSnackbar from '@/components/SiteSnackbar.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useTracking from '@/composables/useTracking';
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';
export default defineComponent({
  components: {
    DialogControl: DialogControl,
    OverlayControl: OverlayControl,
    SiteSnackbar: SiteSnackbar
  },
  setup: function setup() {
    useErrorHandling();
    useTracking();
    var userStore = useUserStore();
    var businessStore = useBusinessStore();
    return {
      userStore: userStore,
      businessStore: businessStore
    };
  },
  data: function data() {
    return {
      bizInviteLoading: false,
      bizLeaveLoading: false,
      gtcLoading: false,
      isAlertRoute: false,
      showStagingMsg: false,
      showDeleteBusinessDialogFlag: false
    };
  },
  computed: {
    showAlert: function showAlert() {
      return this.$store.state.alert.value && this.isAlertRoute;
    }
  },
  watch: {
    // Called whenever route changes
    $route: {
      immediate: true,
      handler: function handler(to) {
        var alertRoutes = ['index', 'profile', 'profile-settings', 'profile-signature-standards', 'profile-visual-signature', 'billing', 'billing-settings', 'billing-usage'];

        // The home route name is used for other index pages, so we
        // check for the full path here
        this.isAlertRoute = alertRoutes.includes(to.name);
      }
    }
  },
  mounted: function mounted() {
    // Show staging alert when we are not on PROD. On STAGING the message can
    // be disabled by setting a variable 'hide-staging-alert' in
    // localStorage.
    var hideStagingAlert = localStorage.getItem('hide-staging-alert') !== null;
    if (this.$config.public.mode !== 'production' && !hideStagingAlert) {
      this.showStagingMsg = true;
    }
  }
});
import SkribbleLogo from '@/components/SkribbleLogo.vue';
export default defineComponent({
  name: 'Logo',
  components: {
    SkribbleLogo: SkribbleLogo
  },
  props: {
    business: String,
    image: String
  }
});
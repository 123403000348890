import format from 'date-fns/format';
export function useApiDataTable(provider, dates) {
  var businessStore = useBusinessStore();
  var businessId = computed(function () {
    return businessStore.id;
  });
  var eventDataProvider = provider;
  var pageItems = ref([]);
  var loadingTable = ref(false);
  var initialized = false;
  var currentPageInfo = ref({
    currentPage: 1,
    numberOfItemsPerPage: 25,
    totalItems: 0,
    sorting: 'desc'
  });
  var time = function time(_time) {
    return format(new Date(_time), 'kk:mm');
  };

  // return to first page and update table
  // when we need to reset the table:
  // 1. date range is changed
  // 2. sorting is changed
  // 3. itemsPerPage is changed
  var resetTable = function resetTable() {
    currentPageInfo.value.currentPage = 1;
    updateTable();
  };

  // get data from server and update table
  var updateTable = function updateTable() {
    getData(currentPageInfo.value.currentPage, currentPageInfo.value.sorting, currentPageInfo.value.numberOfItemsPerPage);
  };
  var getData = function getData(page, sort, numberOfItemsPerPage) {
    loadingTable.value = true;
    eventDataProvider.getEvents(businessId.value, {
      page: page,
      size: numberOfItemsPerPage,
      startDate: dates.value[0],
      endDate: dates.value.length === 1 ? dates.value[0] : dates.value[1],
      sortOrder: sort
    }).then(function (response) {
      pageItems.value = response.activities;
      currentPageInfo.value.totalItems = response.totalItems;
      loadingTable.value = false;
      initialized = true;
    }).catch(function (error) {
      throw new Error('error in use API data table get method: ' + error);
    });
  };

  // event on date range change
  watch(dates, function () {
    resetTable();
  });

  // event on :
  // 0. initial load - skipped for now
  // 1. page change - next, previous
  // 2. change number of rows per page
  // 3. can happen after getData method - not sure why, but it shouldn't happen
  var pageChange = function pageChange(options) {
    if (!initialized) {
      return;
    }
    if (currentPageInfo.value.currentPage !== options.page) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currentPageInfo.value.currentPage = options.page;
      // when number of items per page is changed on ui, if page is not first, then both page and items per page will be changed
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currentPageInfo.value.numberOfItemsPerPage = options.itemsPerPage;
      updateTable();
    } else if (currentPageInfo.value.numberOfItemsPerPage !== options.itemsPerPage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      currentPageInfo.value.numberOfItemsPerPage = options.itemsPerPage;
      resetTable();
    }
  };

  // event on sorting change
  var handleSort = function handleSort(sortByDesc) {
    currentPageInfo.value.sorting = sortByDesc ? 'desc' : 'asc';
    resetTable();
  };
  return {
    initialized: initialized,
    currentPageInfo: currentPageInfo,
    pageChange: pageChange,
    handleSort: handleSort,
    resetTable: resetTable,
    updateTable: updateTable,
    time: time,
    pageItems: pageItems,
    loadingTable: loadingTable
  };
}
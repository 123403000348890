import { defineStore } from 'pinia'

import useSignatureHelpers from '@/composables/useSignatureHelpers'
import { updateUser } from '@/repository/direct-sign/user'
import { resendTan } from '@/repository/tan'
import { useSnackbarStore } from '@/stores/snackbar'
import { useUserStore } from '@/stores/user'

interface DirectSignState {
  username: string
  password: string
  tanEntry: boolean
  tan: string
  needTan: number
}

export const useDirectSignStore = defineStore('directSign', {
  state: (): DirectSignState => ({
    username: '',
    password: '',
    tanEntry: false,
    tan: '',
    needTan: 0,
  }),
  getters: {
    auth(): { username: string; password: string } {
      if (this.username && this.password) {
        return { username: this.username, password: this.password }
      } else if (
        this.$nuxt.store.state.directSignSession?.auth &&
        this.$nuxt.store.state.directSignSession.auth.username &&
        this.$nuxt.store.state.directSignSession.auth.password
      ) {
        return {
          username: this.$nuxt.store.state.directSignSession.auth.username as string,
          password: this.$nuxt.store.state.directSignSession.auth.password as string,
        }
      }
      return { username: '', password: '' }
    },
    directSignB64AuthHeader(): string {
      let str = ''
      const { username, password } = this.auth
      if (username && password) {
        str = btoa(`${username}:${password}`)
      }
      return str
    },
    directSignSession(): DirectSignSession | null {
      return this.$nuxt.store.state.directSignSession
    },
    isDirectSign(): boolean {
      return Boolean(this.directSignSession)
    },
    immutableEidFields(): string[] {
      const userStore = useUserStore()

      const fields = userStore.attributes.immutable_eid_fields

      return fields?.length ? fields : ['all']
    },
  },
  actions: {
    async setDirectSign(username: string, password: string) {
      this.username = username
      this.password = password
      await this.$nuxt.store.dispatch('setDirectSign', { auth: { username, password } })
    },
    // TODO: Combine with user store
    async update(data: DirectUserUpdateData): Promise<void> {
      const userStore = useUserStore()
      await updateUser(data)
      await userStore.fetchUser()
    },
    setTan(tan: string) {
      this.tan = tan
    },
    updateNeedTan() {
      this.needTan += 1
    },
    async sendTan(showSnackbar = true): Promise<void> {
      const { tanMailData } = useSignatureHelpers()
      await resendTan(tanMailData.value.subject ?? '', window.location.href, tanMailData.value.companyBranding)
      if (showSnackbar) {
        const { setSnackbar } = useSnackbarStore()
        void setSnackbar({
          message: { key: 'viewer.tan.invalid.feedback.success' },
        })
      }
    },
  },
})

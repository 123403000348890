import { $http } from '@/plugins/http'
import { verificationUrl } from '@/plugins/verification'

export async function requestTan(challengeId: string): Promise<void> {
  await fetch(`${verificationUrl}/api/resend/${challengeId}`, { method: 'POST' })
}

export async function verifyTan(challengeId: string, secret: string): Promise<{ status: string }> {
  const response = await fetch(`${verificationUrl}/api/respond/${challengeId}`, {
    method: 'POST',
    body: JSON.stringify({
      secret,
    }),
  })

  return await response.json()
}

export async function cancelVerification(challengeId: string): Promise<void> {
  await fetch(`${verificationUrl}/api/cancel/${challengeId}`, { method: 'POST' })
}

export async function resendTan(subject: string, url: string, branding?: CompanyBranding): Promise<void> {
  await $http.$post('v1/direct/tan/resend', {
    url,
    subject,
    company_branding: branding,
  })
}

export async function checkTan(tan: string): Promise<CheckTanResponse> {
  return await $http.$post<CheckTanResponse>('v1/direct/tan/check', { tan })
}

import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import { storeToRefs } from 'pinia';
import { objectToSnakeCase, stringToCamelCase } from '@/composables/useCaseConvert';
import { useBusinessStore } from '@/stores/business';
import { useDirectSignStore } from '@/stores/directSign';
import { useDocumentStore } from '@/stores/document';
import { useSnackbarStore } from '@/stores/snackbar';
import { useUserStore } from '@/stores/user';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useSignatureHelpers() {
  var documentStore = useDocumentStore();
  var _storeToRefs = storeToRefs(documentStore),
    activeSignatureRequest = _storeToRefs.activeSignatureRequest,
    activeDocument = _storeToRefs.activeDocument;
  var userStore = useUserStore();
  var _storeToRefs2 = storeToRefs(userStore),
    email = _storeToRefs2.email,
    signatureQualities = _storeToRefs2.signatureQualities,
    signerIdentityEmail = _storeToRefs2.signerIdentityEmail,
    emailVerified = _storeToRefs2.emailVerified,
    hasSigningCredit = _storeToRefs2.hasSigningCredit;
  var directSignStore = useDirectSignStore();
  var _storeToRefs3 = storeToRefs(directSignStore),
    isDirectSign = _storeToRefs3.isDirectSign;
  var _useSnackbarStore = useSnackbarStore(),
    setSnackbar = _useSnackbarStore.setSnackbar;
  var businessStore = useBusinessStore();
  var _storeToRefs4 = storeToRefs(businessStore),
    seals = _storeToRefs4.seals;
  var openForInvitation = computed(function () {
    var _activeSignatureReque;
    return ((_activeSignatureReque = activeSignatureRequest.value) === null || _activeSignatureReque === void 0 ? void 0 : _activeSignatureReque.signatures.length) === 0 && activeSignatureRequest.value.statusOverall === 'OPEN';
  });
  var userIsSrOwner = computed(function () {
    var _activeSignatureReque2;
    return Boolean(email.value && ((_activeSignatureReque2 = activeSignatureRequest.value) === null || _activeSignatureReque2 === void 0 ? void 0 : _activeSignatureReque2.writeAccess.includes(email.value.toLowerCase())));
  });
  var srIsTerminalState = computed(function () {
    var _activeSignatureReque3, _activeSignatureReque4;
    return Boolean(((_activeSignatureReque3 = activeSignatureRequest.value) === null || _activeSignatureReque3 === void 0 ? void 0 : (_activeSignatureReque4 = _activeSignatureReque3.statusOverall) === null || _activeSignatureReque4 === void 0 ? void 0 : _activeSignatureReque4.toLowerCase()) !== 'open');
  });
  var setupDirectSign = function setupDirectSign(isFreeUser, requestedQuality) {
    // When a user sets the quality to any quality we will automatically try
    // to create a directSign unless they are a free user because free users
    // can not pay for the invitees which would be required for directSign.
    return !isFreeUser && ['ses', 'aes', 'qes'].includes(requestedQuality);
  };
  var standardizedUpdateURL = computed(function () {
    var _activeSignatureReque5, _activeSignatureReque6, _activeSignatureReque7, _activeSignatureReque8;
    // Construct URL for signature standards update based on the current SR
    var standardsUpdateURL = "/profile/signature-standards/update?sr=".concat((_activeSignatureReque5 = activeSignatureRequest.value) === null || _activeSignatureReque5 === void 0 ? void 0 : _activeSignatureReque5.id);
    standardsUpdateURL += "&q=".concat((_activeSignatureReque6 = activeSignatureRequest.value) === null || _activeSignatureReque6 === void 0 ? void 0 : _activeSignatureReque6.quality.substring(0, 3).toLowerCase());
    if ((_activeSignatureReque7 = activeSignatureRequest.value) !== null && _activeSignatureReque7 !== void 0 && _activeSignatureReque7.legislation) standardsUpdateURL += "&l=".concat((_activeSignatureReque8 = activeSignatureRequest.value) === null || _activeSignatureReque8 === void 0 ? void 0 : _activeSignatureReque8.legislation.toLowerCase());
    return standardsUpdateURL;
  });
  var userWillSealSRWith = computed(function () {
    var _activeSignatureReque9, _activeSignatureReque10, _signatureQualities$v;
    var requestQuality = (_activeSignatureReque9 = activeSignatureRequest.value) === null || _activeSignatureReque9 === void 0 ? void 0 : (_activeSignatureReque10 = _activeSignatureReque9.quality) === null || _activeSignatureReque10 === void 0 ? void 0 : _activeSignatureReque10.toLowerCase();
    if (!requestQuality || !(signatureQualities !== null && signatureQualities !== void 0 && (_signatureQualities$v = signatureQualities.value) !== null && _signatureQualities$v !== void 0 && _signatureQualities$v.seal)) return [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    var sealList = signatureQualities.value.seal[requestQuality];
    if (!sealList) return [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    var sealNamesForSrQuality = Object.keys(sealList) || [];
    return seals.value.filter(function (_ref) {
      var accountName = _ref.accountName;
      return accountName !== 'ais_demo_seal' && sealNamesForSrQuality.includes(accountName);
    }).map(function (_ref2) {
      var displayName = _ref2.displayName,
        accountName = _ref2.accountName;
      return {
        accountName: accountName,
        displayName: displayName,
        quality: sealList[accountName].quality.toLocaleLowerCase(),
        legislation: sealList[accountName].legislation,
        provider: sealList[accountName].provider
      };
    });
  });

  // Simplified version with only quality and legislation was introduced for no
  // account batch signature requests which also rely on this function but don't
  // have a fully fledged signature request to work with.
  var userWillSignSRWith = computed(function () {
    if (activeSignatureRequest.value.id && signatureQualities !== null && signatureQualities !== void 0 && signatureQualities.value) {
      var _activeSignatureReque11, _signatureQualities$v2;
      var srQuality = stringToCamelCase(activeSignatureRequest.value.quality.toLowerCase());
      var srLegislation = srQuality === 'demo' || srQuality === 'aes' || srQuality === 'ses' || srQuality === 'part11' ? 'any' : ((_activeSignatureReque11 = activeSignatureRequest.value.legislation) === null || _activeSignatureReque11 === void 0 ? void 0 : _activeSignatureReque11.toLowerCase()) || 'any';

      // SES directSign users won't have their email verified on first visit
      // which will result in bbq sending null for all qualities. wave will fix
      // this by setting ses.any: 'ses' in this case.
      if (srQuality === 'ses' && Boolean(signerIdentityEmail.value)) {
        return {
          legislation: 'any',
          quality: 'ses',
          provider: 'AIS'
        };
      }

      // aes_minimal, qes and everything else
      if ((_signatureQualities$v2 = signatureQualities.value[srQuality]) !== null && _signatureQualities$v2 !== void 0 && _signatureQualities$v2[srLegislation]) {
        var _ref3, _signatureQualities$v3, _signatureQualities$v4, _ref4, _signatureQualities$v5, _ref5, _signatureQualities$v6, _signatureQualities$v7;
        return {
          quality: (_ref3 = (_signatureQualities$v3 = signatureQualities.value[srQuality][srLegislation]) === null || _signatureQualities$v3 === void 0 ? void 0 : (_signatureQualities$v4 = _signatureQualities$v3.quality) === null || _signatureQualities$v4 === void 0 ? void 0 : _signatureQualities$v4.toLocaleLowerCase()) !== null && _ref3 !== void 0 ? _ref3 : null,
          provider: (_ref4 = (_signatureQualities$v5 = signatureQualities.value[srQuality][srLegislation]) === null || _signatureQualities$v5 === void 0 ? void 0 : _signatureQualities$v5.provider) !== null && _ref4 !== void 0 ? _ref4 : null,
          legislation: (_ref5 = (_signatureQualities$v6 = signatureQualities.value[srQuality][srLegislation]) === null || _signatureQualities$v6 === void 0 ? void 0 : (_signatureQualities$v7 = _signatureQualities$v6.legislation) === null || _signatureQualities$v7 === void 0 ? void 0 : _signatureQualities$v7.toLocaleLowerCase()) !== null && _ref5 !== void 0 ? _ref5 : null
        };
      }
    }
    return {
      quality: null,
      legislation: null,
      provider: null
    };
  });
  var displayStatusOverallFn = function displayStatusOverallFn(signatureRequest) {
    var statesMapping = {
      OPEN: 'pending',
      DECLINED: 'declined',
      SIGNED: 'completed',
      WITHDRAWN: 'withdrawn'
    };
    var statusOverall = signatureRequest === null || signatureRequest === void 0 ? void 0 : signatureRequest.statusOverall;
    if (!statusOverall) return '';
    // Uploaded documents are handled only in the frontend.
    // Definition: status_overall === 'OPEN' and no signatures set yet.
    if (statusOverall === 'OPEN' && (signatureRequest === null || signatureRequest === void 0 ? void 0 : signatureRequest.signatures.length) === 0) return 'uploaded';
    return statesMapping[statusOverall] || '';
  };
  var displayStatusOverall = computed(function () {
    var _activeSignatureReque12, _activeSignatureReque13;
    var statusOverall = (_activeSignatureReque12 = activeSignatureRequest.value) === null || _activeSignatureReque12 === void 0 ? void 0 : _activeSignatureReque12.statusOverall;
    if (!statusOverall) return '';
    // Uploaded documents are handled only in the frontend.
    // Definition: status_overall === 'OPEN' and no signatures set yet.
    if (statusOverall === 'OPEN' && ((_activeSignatureReque13 = activeSignatureRequest.value) === null || _activeSignatureReque13 === void 0 ? void 0 : _activeSignatureReque13.signatures.length) === 0) return 'uploaded';else if (statusOverall === 'OPEN') return 'pending';else if (statusOverall === 'DECLINED') return 'declined';else if (statusOverall === 'SIGNED') return 'completed';else if (statusOverall === 'WITHDRAWN') return 'withdrawn';else return '';
  });
  var documentTitle = computed(function () {
    var _activeDocument$value;
    return ((_activeDocument$value = activeDocument.value) === null || _activeDocument$value === void 0 ? void 0 : _activeDocument$value.title) || '';
  });
  var hasBrandingLogoInSr = computed(function () {
    var _activeSignatureReque14, _activeSignatureReque15;
    return Boolean((_activeSignatureReque14 = activeSignatureRequest.value) === null || _activeSignatureReque14 === void 0 ? void 0 : (_activeSignatureReque15 = _activeSignatureReque14.branding) === null || _activeSignatureReque15 === void 0 ? void 0 : _activeSignatureReque15.logo);
  });
  var userIsSrObserver = computed(function () {
    return email.value && activeSignatureRequest.value ? activeSignatureRequest.value.ccEmailAddresses.includes(email.value) : false;
  });
  var requestOwnerName = computed(function () {
    var ownerName = formattedSigReqOwnerName.value;
    return ownerName || '';
  });
  var srBrandingLogo = computed(function () {
    var _activeSignatureReque16, _activeSignatureReque17, _activeSignatureReque18;
    var logo = (_activeSignatureReque16 = activeSignatureRequest.value) === null || _activeSignatureReque16 === void 0 ? void 0 : (_activeSignatureReque17 = _activeSignatureReque16.branding) === null || _activeSignatureReque17 === void 0 ? void 0 : (_activeSignatureReque18 = _activeSignatureReque17.logo) === null || _activeSignatureReque18 === void 0 ? void 0 : _activeSignatureReque18.url;
    return logo || undefined;
  });
  var userCanAddSigners = computed(function () {
    return userIsSrOwner.value && !srIsTerminalState.value;
  });
  var userCanChangeSigners = computed(function () {
    return !srIsTerminalState.value && !isDirectSign.value && displayStatusOverall.value !== 'uploaded' && !userIsSrObserver.value && userHasOpenSignature.value;
  });
  var userCanDeclineDocument = computed(function () {
    if (!activeSignatureRequest.value || !email.value) return false;
    return userHasOpenSignature.value && !userIsSrOwner.value;
  });
  var userCanWithdrawDocument = computed(function () {
    if (!activeSignatureRequest.value || !email.value) return false;
    return !srIsTerminalState.value && userIsSrOwner.value && displayStatusOverall.value !== 'uploaded' && !userIsSrObserver.value;
  });
  var userCanDeleteDocument = computed(function () {
    if (!activeSignatureRequest.value || !email.value) return false;
    return Boolean(!isDirectSign.value && srIsTerminalState.value || displayStatusOverall.value === 'uploaded' && !userIsSrObserver.value);
  });
  var formattedSigReqOwnerName = computed(function () {
    var _activeSignatureReque19;
    // if activeSigReq is undefined, return blank
    if (!activeSignatureRequest.value.id) return '';

    // else, determine if owner is API user and, if so, return biz name instead of owner name
    var isAPISigReq = activeSignatureRequest.value.owner.substring(0, 4) === 'api_';
    return isAPISigReq ? (_activeSignatureReque19 = activeSignatureRequest.value.bizName) !== null && _activeSignatureReque19 !== void 0 ? _activeSignatureReque19 : '' : activeSignatureRequest.value.owner;
  });

  /**
   * This function will only be called when a snackbar has to be shown
   * for directSign cases where the required quality (QES) or legislation is not met.
   * This can only happen in QES cases since for AES we provide the mobile AES flow.
   */
  var showCannotSignSnackbarForDs = function showCannotSignSnackbarForDs(requestedQuality, requestedLegislation) {
    var isQes = requestedQuality === 'qes';
    if (!isDirectSign.value || !isQes) return;
    // Because the legislation is no longer required, it is not always part
    // of the error message and only added if specified and the SR quality
    // is QES.
    var computeTranslations = function computeTranslations() {
      if (requestedLegislation === 'zertes') return {
        messageText: 'signature_request.errors.no_data_match.direct_sign.qes.zertes.message_text',
        actionText: 'signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_text',
        actionHref: 'signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_link'
      };
      if (requestedLegislation === 'eidas') return {
        messageText: 'signature_request.errors.no_data_match.direct_sign.qes.eidas.message_text',
        actionText: 'signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_text',
        actionHref: 'signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_link'
      };
    };
    var translations = computeTranslations();
    if (translations) void setSnackbar({
      action: {
        text: {
          key: translations.actionText
        },
        href: {
          key: translations.actionHref
        }
      },
      message: {
        key: translations.messageText
      },
      timeout: 0
    });
  };
  var tanMailData = computed(function () {
    var _activeSignatureReque20, _activeSignatureReque21;
    var data = {
      subject: (_activeSignatureReque20 = activeSignatureRequest.value) === null || _activeSignatureReque20 === void 0 ? void 0 : _activeSignatureReque20.title,
      companyBranding: (_activeSignatureReque21 = activeSignatureRequest.value) === null || _activeSignatureReque21 === void 0 ? void 0 : _activeSignatureReque21.branding
    };
    return objectToSnakeCase(data);
  });
  var userIsPartOfSR = computed(function () {
    var _activeSignatureReque22;
    if (!activeSignatureRequest.value) return false;
    return (_activeSignatureReque22 = activeSignatureRequest.value) === null || _activeSignatureReque22 === void 0 ? void 0 : _activeSignatureReque22.signatures.some(function (signature) {
      return signature.accountEmail === email.value;
    });
  });
  var userHasSigningCredit = computed(function () {
    var _activeSignatureReque23;
    // If this user hasSigningCredit then they will never be unable to sign
    // this SR because of payment issues.
    // However, users without signing credits which are the owner of a SR
    // won't be able to sign in any case.
    return Boolean(Boolean(hasSigningCredit.value) || ((_activeSignatureReque23 = activeSignatureRequest.value) === null || _activeSignatureReque23 === void 0 ? void 0 : _activeSignatureReque23.owner) !== email.value);
  });
  var userHasOpenSignature = computed(function () {
    var _activeSignatureReque24;
    return Boolean((_activeSignatureReque24 = activeSignatureRequest.value) === null || _activeSignatureReque24 === void 0 ? void 0 : _activeSignatureReque24.signatures.some(function (signature) {
      var _signature$statusCode;
      return signature.accountEmail === email.value && ((_signature$statusCode = signature.statusCode) === null || _signature$statusCode === void 0 ? void 0 : _signature$statusCode.toLowerCase()) === 'open';
    }));
  });
  var userCannotSign = computed(function () {
    var _ref6;
    // directSign users will never be blocked from signing by not having
    // their email verified as all of them will not have it verified.
    var emailNotVerified = !isDirectSign.value && !emailVerified.value;
    var userCannotSignQuality = userWillSignSRWith.value && !userWillSignSRWith.value.quality;
    return (_ref6 = emailNotVerified || userCannotSignQuality || !userHasSigningCredit.value) !== null && _ref6 !== void 0 ? _ref6 : false;
  });
  return {
    openForInvitation: openForInvitation,
    userIsSrOwner: userIsSrOwner,
    srIsTerminalState: srIsTerminalState,
    setupDirectSign: setupDirectSign,
    standardizedUpdateURL: standardizedUpdateURL,
    userWillSealSRWith: userWillSealSRWith,
    userWillSignSRWith: userWillSignSRWith,
    displayStatusOverall: displayStatusOverall,
    displayStatusOverallFn: displayStatusOverallFn,
    documentTitle: documentTitle,
    hasBrandingLogoInSr: hasBrandingLogoInSr,
    userIsSrObserver: userIsSrObserver,
    requestOwnerName: requestOwnerName,
    srBrandingLogo: srBrandingLogo,
    userCanAddSigners: userCanAddSigners,
    userCanChangeSigners: userCanChangeSigners,
    userCanDeclineDocument: userCanDeclineDocument,
    userCanWithdrawDocument: userCanWithdrawDocument,
    userCanDeleteDocument: userCanDeleteDocument,
    formattedSigReqOwnerName: formattedSigReqOwnerName,
    showCannotSignSnackbarForDs: showCannotSignSnackbarForDs,
    tanMailData: tanMailData,
    userIsPartOfSR: userIsPartOfSR,
    userHasSigningCredit: userHasSigningCredit,
    userHasOpenSignature: userHasOpenSignature,
    userCannotSign: userCannotSign
  };
}
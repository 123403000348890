import "core-js/modules/es.function.name.js";
import { storeToRefs } from 'pinia';
import { useBusinessStore } from '@/stores/business';
export default defineComponent({
  name: 'AvatarBusiness',
  props: {
    companyName: {
      type: String,
      default: null
    }
  },
  setup: function setup(props) {
    var _storeToRefs = storeToRefs(useBusinessStore()),
      name = _storeToRefs.name;
    var businessName = computed(function () {
      var _props$companyName;
      return (_props$companyName = props.companyName) !== null && _props$companyName !== void 0 ? _props$companyName : name.value;
    });
    return {
      businessName: businessName
    };
  },
  computed: {
    backgroundColors: function backgroundColors() {
      return [this.$vuetify.theme.themes.light['avatar-0'], this.$vuetify.theme.themes.light['avatar-1'], this.$vuetify.theme.themes.light['avatar-2'], this.$vuetify.theme.themes.light['avatar-3'], this.$vuetify.theme.themes.light['avatar-4'], this.$vuetify.theme.themes.light['avatar-5'], this.$vuetify.theme.themes.light['avatar-6'], this.$vuetify.theme.themes.light['avatar-7'], this.$vuetify.theme.themes.light['avatar-8'], this.$vuetify.theme.themes.light['avatar-9']];
    },
    getBackground: function getBackground() {
      var companyName = this.businessName;
      var hash = 0;
      var chr;

      // set default color and give users with skribble email the black insignia
      if (companyName === null || companyName.length === 0) {
        return this.$vuetify.theme.themes.light['avatar-0'];
      }

      // set hash based color
      for (var i = 0; i < companyName.length; i++) {
        chr = companyName.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        // Convert to 32bit integer
        hash |= 0;
      }
      return this.backgroundColors[Math.abs(hash % 10)];
    }
  }
});
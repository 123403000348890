import type { Stripe } from '@stripe/stripe-js'
import type { ActionTree, GetterTree, MutationTree } from 'vuex'

enum ActionTypes {
  UPDATING_USAGE = 'UPDATING_USAGE',
  SET_USAGE = 'SET_USAGE',
  DEFAULT_USAGE = 'DEFAULT_USAGE',
  FAILED_USAGE = 'FAILED_USAGE',
}

export enum BillingUsageType {
  default,
  updating,
  updated,
  failed,
}

const legacyPricingStructure: LegacyPricingStructure = {
  CHF: {
    fairFlat: {
      cap: 29,
      signatures: {
        QES: 2.4,
        AES: 1.9,
        SES: 1.1,
      },
    },
    business: {
      monthlyFee: 100,
      yearlyFee: 85,
      signatures: {
        QES: 2.4,
        AES: 1.6,
        SES: 1.1,
      },
    },
  },
  EUR: {
    fairFlat: {
      cap: 29,
      signatures: {
        QES: 2.4,
        AES: 1.9,
        SES: 1.1,
      },
    },
    business: {
      monthlyFee: 90,
      yearlyFee: 79,
      signatures: {
        QES: 2.4,
        AES: 1.6,
        SES: 1.1,
      },
    },
  },
}

// In the future, we would ideally get this from the backend,
// in one form or another
const pricingStructure: PricingStructure = {
  EUR: {
    fairFlat: {
      cap: 39,
      signatures: {
        QES: 3.0,
        AES: 2.4,
        SES: 1.5,
      },
    },
    business: {
      monthlyFee: 129,
      yearlyFee: 99,
      signatures: {
        QES: 3.0,
        AES: 2.4,
        SES: 1.5,
      },
    },
  },
}

const getInitialUsageState = () => ({
  state: BillingUsageType.default,
  amount: 0,
  currency: '',
  date: 0,
})

const getInitialState = (): BillingState => ({
  usage: getInitialUsageState(),
  vatIncludedCountries: ['CH', 'LI'],
  defaultCurrency: 'EUR',
  stripeInstance: null as Stripe | null,
  pricingStructure,
})

const state: () => BillingState = getInitialState

/**
 * It's true that the type is ActionTree
 * It's not true that the Store type is "any"
 * This is a temp measure until there's a type
 * interface for the Store for these generics
 */
const actions: ActionTree<BillingState, RootState> = {
  async getStripeInstance({ state }): Promise<Stripe> {
    if (state.stripeInstance) return state.stripeInstance
    const stripe = await import(/* webpackChunkName: "stripe-loader" */ '@stripe/stripe-js')
    const stripeInstance = await stripe.loadStripe(this.$config.public.stripePublishableKey || '')
    if (!stripeInstance) throw new Error('Failed to load Stripe')
    return stripeInstance
  },
  setStripeCustomer(_, data) {
    return this.$axios.$post('/api/billing/address', data)
  },
  getStripeCustomer(_) {
    return this.$axios.$get('/api/billing/address')
  },
  getStripeCard(_) {
    return this.$axios.$get('/api/billing/cards')
  },
  removeStripeCard({ dispatch }) {
    // currently you can only delete a card by cancelling your subscription
    return this.$axios.$delete('/api/billing/subscription').then(() => {
      // we manually remove stripe_subscription_id from the user in store to
      // simulate a coherent state with what the user will end up as soon as
      // stripe calls phoebe's webhook which will do the actual work
      // immediately getting the user will not work reliably because the time
      // it will take to remove these attributes varies
      void dispatch('removeStripeSubscriptionId', null, { root: true })
    })
  },
  getStripeSetupIntent(_, { country }: { country?: string }) {
    return this.$axios.$post('/api/billing/setup-intent', { country })
  },
  confirmStripeSetupIntent(_, { id, currency, country }: { id: string; currency: string; country?: string }) {
    // always send request to set currency as euros
    return this.$axios.$post(`/api/billing/setup-intent/${id}`, { currency, country })
  },
  /**
   * Returns current prices for a given plan.
   */
  getPlanPrices({ getters }, planName: BillingPlan) {
    return pricingStructure[getters.usedCurrency][planName]
  },
}

const mutations: MutationTree<BillingState> = {
  UPDATING_USAGE(state) {
    Object.assign(state.usage, {
      state: BillingUsageType.updating,
    })
  },
  SET_USAGE(state, usage: BillingUsage) {
    Object.assign(state.usage, {
      state: BillingUsageType.updated,
      amount: usage.amount,
      currency: usage.currency,
      date: usage.date,
    })
  },
  DEFAULT_USAGE(state) {
    Object.assign(state.usage, getInitialUsageState())
  },
  FAILED_USAGE(state) {
    Object.assign(state.usage, {
      state: BillingUsageType.failed,
    })
  },
}

const getters: GetterTree<BillingState, RootState> = {
  isFairFlat(_, _0, rootState, rootGetters) {
    if (rootGetters['business/userIsMember']) return false
    return Boolean(rootState.user?.attributes.stripe_subscription_id?.length)
  },
  isLegacyPricing(_, getters, rootState, rootGetters): boolean {
    if (rootGetters['business/userIsMember']) {
      return !rootState.business?.company.price_plan.pricing_2023
    }
    return getters.isFairFlat && !rootState.user?.attributes.pricing_2023
  },
  usedCurrency(state, _, rootState): Currency {
    const businessCurrency = rootState.business?.billing.next_invoice?.currency
    const userCurrency = state.usage.currency

    return (businessCurrency || userCurrency || state.defaultCurrency).toUpperCase() as Currency
  },
  pricingStructure(_, getters): PricingStructure {
    return getters.isLegacyPricing ? legacyPricingStructure : pricingStructure
  },
  planPrices(_, getters): PricingStructure[keyof PricingStructure] {
    return getters.pricingStructure[getters.usedCurrency]
  },
  defaultCurrencyOptions(_, getters): Intl.NumberFormatOptions {
    return {
      style: 'currency',
      currency: getters.usedCurrency as Currency,
      currencyDisplay: 'code',
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b58f305a = () => interopDefault(import('../src/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _b4495aee = () => interopDefault(import('../src/pages/bye.vue' /* webpackChunkName: "pages/bye" */))
const _51e0b917 = () => interopDefault(import('../src/pages/component-test.vue' /* webpackChunkName: "pages/component-test" */))
const _261d769c = () => interopDefault(import('../src/pages/cookiesblocked.vue' /* webpackChunkName: "pages/cookiesblocked" */))
const _126c6480 = () => interopDefault(import('../src/pages/expired.vue' /* webpackChunkName: "pages/expired" */))
const _261b18c4 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2b4def82 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _7a050da4 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _d5c91e56 = () => interopDefault(import('../src/pages/password-reset/index.vue' /* webpackChunkName: "pages/password-reset/index" */))
const _9ef2dc72 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _1baae590 = () => interopDefault(import('../src/pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _ec556490 = () => interopDefault(import('../src/pages/sorry.vue' /* webpackChunkName: "pages/sorry" */))
const _e3492592 = () => interopDefault(import('../src/pages/upgrade.vue' /* webpackChunkName: "pages/upgrade" */))
const _0cd42594 = () => interopDefault(import('../src/pages/upload.vue' /* webpackChunkName: "pages/upload" */))
const _07d74906 = () => interopDefault(import('../src/pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _04c016d9 = () => interopDefault(import('../src/pages/business/activity.vue' /* webpackChunkName: "pages/business/activity" */))
const _a3cd0b38 = () => interopDefault(import('../src/pages/business/billing/index.vue' /* webpackChunkName: "pages/business/billing/index" */))
const _67d35d70 = () => interopDefault(import('../src/pages/business/extras/index.vue' /* webpackChunkName: "pages/business/extras/index" */))
const _f47a18fc = () => interopDefault(import('../src/pages/business/members/index.vue' /* webpackChunkName: "pages/business/members/index" */))
const _e2917fdc = () => interopDefault(import('../src/pages/business/profile/index.vue' /* webpackChunkName: "pages/business/profile/index" */))
const _77c62930 = () => interopDefault(import('../src/pages/business/register/index.vue' /* webpackChunkName: "pages/business/register/index" */))
const _64d90fbf = () => interopDefault(import('../src/pages/business/seals/index.vue' /* webpackChunkName: "pages/business/seals/index" */))
const _78f79426 = () => interopDefault(import('../src/pages/business/settings.vue' /* webpackChunkName: "pages/business/settings" */))
const _22d5c1e5 = () => interopDefault(import('../src/pages/business/signup/index.vue' /* webpackChunkName: "pages/business/signup/index" */))
const _5dd9207c = () => interopDefault(import('../src/pages/business/upgrade.vue' /* webpackChunkName: "pages/business/upgrade" */))
const _3ecc5e08 = () => interopDefault(import('../src/pages/business/welcome.vue' /* webpackChunkName: "pages/business/welcome" */))
const _812acdfa = () => interopDefault(import('../src/pages/password-reset/confirm.vue' /* webpackChunkName: "pages/password-reset/confirm" */))
const _991fdb1e = () => interopDefault(import('../src/pages/password-reset/mail-sent.vue' /* webpackChunkName: "pages/password-reset/mail-sent" */))
const _d6810d3a = () => interopDefault(import('../src/pages/password-reset/new.vue' /* webpackChunkName: "pages/password-reset/new" */))
const _fc645466 = () => interopDefault(import('../src/pages/profile/change-password.vue' /* webpackChunkName: "pages/profile/change-password" */))
const _a6dd7516 = () => interopDefault(import('../src/pages/profile/confirm.vue' /* webpackChunkName: "pages/profile/confirm" */))
const _e9e1c684 = () => interopDefault(import('../src/pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _e7db718e = () => interopDefault(import('../src/pages/profile/signature-standards/index.vue' /* webpackChunkName: "pages/profile/signature-standards/index" */))
const _3505bc06 = () => interopDefault(import('../src/pages/profile/visual-signature.vue' /* webpackChunkName: "pages/profile/visual-signature" */))
const _40206a04 = () => interopDefault(import('../src/pages/signup/confirm.vue' /* webpackChunkName: "pages/signup/confirm" */))
const _711e9ca8 = () => interopDefault(import('../src/pages/signup/mail-sent.vue' /* webpackChunkName: "pages/signup/mail-sent" */))
const _007d25cd = () => interopDefault(import('../src/pages/signup/sso.vue' /* webpackChunkName: "pages/signup/sso" */))
const _c5f94536 = () => interopDefault(import('../src/pages/signup/steps.vue' /* webpackChunkName: "pages/signup/steps" */))
const _334ba6a6 = () => interopDefault(import('../src/pages/sso/error/index.vue' /* webpackChunkName: "pages/sso/error/index" */))
const _6d9871bb = () => interopDefault(import('../src/pages/super-admin/ident-reset.vue' /* webpackChunkName: "pages/super-admin/ident-reset" */))
const _20b4b318 = () => interopDefault(import('../src/pages/business/billing/payment.vue' /* webpackChunkName: "pages/business/billing/payment" */))
const _767b4207 = () => interopDefault(import('../src/pages/business/billing/plan.vue' /* webpackChunkName: "pages/business/billing/plan" */))
const _1409a8be = () => interopDefault(import('../src/pages/business/developers/api.vue' /* webpackChunkName: "pages/business/developers/api" */))
const _16f7b25f = () => interopDefault(import('../src/pages/business/members/domains.vue' /* webpackChunkName: "pages/business/members/domains" */))
const _6af8a772 = () => interopDefault(import('../src/pages/business/profile/aes-for-business.vue' /* webpackChunkName: "pages/business/profile/aes-for-business" */))
const _4a7b5b81 = () => interopDefault(import('../src/pages/business/signup/mail-sent.vue' /* webpackChunkName: "pages/business/signup/mail-sent" */))
const _b7a38c8c = () => interopDefault(import('../src/pages/business/signup/steps.vue' /* webpackChunkName: "pages/business/signup/steps" */))
const _6841bb37 = () => interopDefault(import('../src/pages/direct-sign/identification/eidas/index.vue' /* webpackChunkName: "pages/direct-sign/identification/eidas/index" */))
const _0ed71cf2 = () => interopDefault(import('../src/pages/direct-sign/identification/id-austria-success.vue' /* webpackChunkName: "pages/direct-sign/identification/id-austria-success" */))
const _67d3e48c = () => interopDefault(import('../src/pages/direct-sign/identification/zertes/index.vue' /* webpackChunkName: "pages/direct-sign/identification/zertes/index" */))
const _d7dfdac8 = () => interopDefault(import('../src/pages/profile/signature-standards/eidas/index.vue' /* webpackChunkName: "pages/profile/signature-standards/eidas/index" */))
const _0b1cee82 = () => interopDefault(import('../src/pages/profile/signature-standards/id-austria-success.vue' /* webpackChunkName: "pages/profile/signature-standards/id-austria-success" */))
const _b3556130 = () => interopDefault(import('../src/pages/profile/signature-standards/legislation.vue' /* webpackChunkName: "pages/profile/signature-standards/legislation" */))
const _8831c1e6 = () => interopDefault(import('../src/pages/profile/signature-standards/mobile-id.vue' /* webpackChunkName: "pages/profile/signature-standards/mobile-id" */))
const _8aaa581c = () => interopDefault(import('../src/pages/profile/signature-standards/update.vue' /* webpackChunkName: "pages/profile/signature-standards/update" */))
const _945f0c72 = () => interopDefault(import('../src/pages/profile/signature-standards/zertes/index.vue' /* webpackChunkName: "pages/profile/signature-standards/zertes/index" */))
const _5f8fcdd1 = () => interopDefault(import('../src/pages/sso/error/company.vue' /* webpackChunkName: "pages/sso/error/company" */))
const _1a5a9577 = () => interopDefault(import('../src/pages/direct-sign/identification/eidas/self.vue' /* webpackChunkName: "pages/direct-sign/identification/eidas/self" */))
const _3052ab40 = () => interopDefault(import('../src/pages/direct-sign/identification/eidas/video.vue' /* webpackChunkName: "pages/direct-sign/identification/eidas/video" */))
const _5a979265 = () => interopDefault(import('../src/pages/direct-sign/identification/zertes/auto.vue' /* webpackChunkName: "pages/direct-sign/identification/zertes/auto" */))
const _a56cff2a = () => interopDefault(import('../src/pages/direct-sign/identification/zertes/personal-details.vue' /* webpackChunkName: "pages/direct-sign/identification/zertes/personal-details" */))
const _312e5896 = () => interopDefault(import('../src/pages/direct-sign/identification/zertes/video.vue' /* webpackChunkName: "pages/direct-sign/identification/zertes/video" */))
const _e13d851c = () => interopDefault(import('../src/pages/profile/signature-standards/eidas/self.vue' /* webpackChunkName: "pages/profile/signature-standards/eidas/self" */))
const _13a501c5 = () => interopDefault(import('../src/pages/profile/signature-standards/eidas/video.vue' /* webpackChunkName: "pages/profile/signature-standards/eidas/video" */))
const _f3342c6c = () => interopDefault(import('../src/pages/profile/signature-standards/zertes/auto.vue' /* webpackChunkName: "pages/profile/signature-standards/zertes/auto" */))
const _356568f0 = () => interopDefault(import('../src/pages/profile/signature-standards/zertes/video.vue' /* webpackChunkName: "pages/profile/signature-standards/zertes/video" */))
const _2b75d082 = () => interopDefault(import('../src/pages/signup/flows/eidplus/confirm.vue' /* webpackChunkName: "pages/signup/flows/eidplus/confirm" */))
const _818293c2 = () => interopDefault(import('../src/pages/signup/flows/eidplus/new.vue' /* webpackChunkName: "pages/signup/flows/eidplus/new" */))
const _5c7375ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0b4ca8c8 = () => interopDefault(import('../src/pages/business/seals/_accountname.vue' /* webpackChunkName: "pages/business/seals/_accountname" */))
const _4721a496 = () => interopDefault(import('../src/pages/direct-sign/identification/_id/_userid/index.vue' /* webpackChunkName: "pages/direct-sign/identification/_id/_userid/index" */))
const _1f44d6eb = () => interopDefault(import('../src/pages/direct-sign/identification/_id/_userid/preview.vue' /* webpackChunkName: "pages/direct-sign/identification/_id/_userid/preview" */))
const _7bb3bcac = () => interopDefault(import('../src/pages/invite/_id/index.vue' /* webpackChunkName: "pages/invite/_id/index" */))
const _615f95e7 = () => interopDefault(import('../src/pages/view/_srid/index.vue' /* webpackChunkName: "pages/view/_srid/index" */))
const _643d8384 = () => interopDefault(import('../src/pages/view/_id/_userid/index.vue' /* webpackChunkName: "pages/view/_id/_userid/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _b58f305a,
    name: "404"
  }, {
    path: "/bye",
    component: _b4495aee,
    name: "bye"
  }, {
    path: "/component-test",
    component: _51e0b917,
    name: "component-test"
  }, {
    path: "/cookiesblocked",
    component: _261d769c,
    name: "cookiesblocked"
  }, {
    path: "/expired",
    component: _126c6480,
    name: "expired"
  }, {
    path: "/login",
    component: _261b18c4,
    name: "login"
  }, {
    path: "/logout",
    component: _2b4def82,
    name: "logout"
  }, {
    path: "/maintenance",
    component: _7a050da4,
    name: "maintenance"
  }, {
    path: "/password-reset",
    component: _d5c91e56,
    name: "password-reset"
  }, {
    path: "/profile",
    component: _9ef2dc72,
    name: "profile"
  }, {
    path: "/signup",
    component: _1baae590,
    name: "signup"
  }, {
    path: "/sorry",
    component: _ec556490,
    name: "sorry"
  }, {
    path: "/upgrade",
    component: _e3492592,
    name: "upgrade"
  }, {
    path: "/upload",
    component: _0cd42594,
    name: "upload"
  }, {
    path: "/welcome",
    component: _07d74906,
    name: "welcome"
  }, {
    path: "/business/activity",
    component: _04c016d9,
    name: "business-activity"
  }, {
    path: "/business/billing",
    component: _a3cd0b38,
    name: "business-billing"
  }, {
    path: "/business/extras",
    component: _67d35d70,
    name: "business-extras"
  }, {
    path: "/business/members",
    component: _f47a18fc,
    name: "business-members"
  }, {
    path: "/business/profile",
    component: _e2917fdc,
    name: "business-profile"
  }, {
    path: "/business/register",
    component: _77c62930,
    name: "business-register"
  }, {
    path: "/business/seals",
    component: _64d90fbf,
    name: "business-seals"
  }, {
    path: "/business/settings",
    component: _78f79426,
    name: "business-settings"
  }, {
    path: "/business/signup",
    component: _22d5c1e5,
    name: "business-signup"
  }, {
    path: "/business/upgrade",
    component: _5dd9207c,
    name: "business-upgrade"
  }, {
    path: "/business/welcome",
    component: _3ecc5e08,
    name: "business-welcome"
  }, {
    path: "/password-reset/confirm",
    component: _812acdfa,
    name: "password-reset-confirm"
  }, {
    path: "/password-reset/mail-sent",
    component: _991fdb1e,
    name: "password-reset-mail-sent"
  }, {
    path: "/password-reset/new",
    component: _d6810d3a,
    name: "password-reset-new"
  }, {
    path: "/profile/change-password",
    component: _fc645466,
    name: "profile-change-password"
  }, {
    path: "/profile/confirm",
    component: _a6dd7516,
    name: "profile-confirm"
  }, {
    path: "/profile/settings",
    component: _e9e1c684,
    name: "profile-settings"
  }, {
    path: "/profile/signature-standards",
    component: _e7db718e,
    name: "profile-signature-standards"
  }, {
    path: "/profile/visual-signature",
    component: _3505bc06,
    name: "profile-visual-signature"
  }, {
    path: "/signup/confirm",
    component: _40206a04,
    name: "signup-confirm"
  }, {
    path: "/signup/mail-sent",
    component: _711e9ca8,
    name: "signup-mail-sent"
  }, {
    path: "/signup/sso",
    component: _007d25cd,
    name: "signup-sso"
  }, {
    path: "/signup/steps",
    component: _c5f94536,
    name: "signup-steps"
  }, {
    path: "/sso/error",
    component: _334ba6a6,
    name: "sso-error"
  }, {
    path: "/super-admin/ident-reset",
    component: _6d9871bb,
    name: "super-admin-ident-reset"
  }, {
    path: "/business/billing/payment",
    component: _20b4b318,
    name: "business-billing-payment"
  }, {
    path: "/business/billing/plan",
    component: _767b4207,
    name: "business-billing-plan"
  }, {
    path: "/business/developers/api",
    component: _1409a8be,
    name: "business-developers-api"
  }, {
    path: "/business/members/domains",
    component: _16f7b25f,
    name: "business-members-domains"
  }, {
    path: "/business/profile/aes-for-business",
    component: _6af8a772,
    name: "business-profile-aes-for-business"
  }, {
    path: "/business/signup/mail-sent",
    component: _4a7b5b81,
    name: "business-signup-mail-sent"
  }, {
    path: "/business/signup/steps",
    component: _b7a38c8c,
    name: "business-signup-steps"
  }, {
    path: "/direct-sign/identification/eidas",
    component: _6841bb37,
    name: "direct-sign-identification-eidas"
  }, {
    path: "/direct-sign/identification/id-austria-success",
    component: _0ed71cf2,
    name: "direct-sign-identification-id-austria-success"
  }, {
    path: "/direct-sign/identification/zertes",
    component: _67d3e48c,
    name: "direct-sign-identification-zertes"
  }, {
    path: "/profile/signature-standards/eidas",
    component: _d7dfdac8,
    name: "profile-signature-standards-eidas"
  }, {
    path: "/profile/signature-standards/id-austria-success",
    component: _0b1cee82,
    name: "profile-signature-standards-id-austria-success"
  }, {
    path: "/profile/signature-standards/legislation",
    component: _b3556130,
    name: "profile-signature-standards-legislation"
  }, {
    path: "/profile/signature-standards/mobile-id",
    component: _8831c1e6,
    name: "profile-signature-standards-mobile-id"
  }, {
    path: "/profile/signature-standards/update",
    component: _8aaa581c,
    name: "profile-signature-standards-update"
  }, {
    path: "/profile/signature-standards/zertes",
    component: _945f0c72,
    name: "profile-signature-standards-zertes"
  }, {
    path: "/sso/error/company",
    component: _5f8fcdd1,
    name: "sso-error-company"
  }, {
    path: "/direct-sign/identification/eidas/self",
    component: _1a5a9577,
    name: "direct-sign-identification-eidas-self"
  }, {
    path: "/direct-sign/identification/eidas/video",
    component: _3052ab40,
    name: "direct-sign-identification-eidas-video"
  }, {
    path: "/direct-sign/identification/zertes/auto",
    component: _5a979265,
    name: "direct-sign-identification-zertes-auto"
  }, {
    path: "/direct-sign/identification/zertes/personal-details",
    component: _a56cff2a,
    name: "direct-sign-identification-zertes-personal-details"
  }, {
    path: "/direct-sign/identification/zertes/video",
    component: _312e5896,
    name: "direct-sign-identification-zertes-video"
  }, {
    path: "/profile/signature-standards/eidas/self",
    component: _e13d851c,
    name: "profile-signature-standards-eidas-self"
  }, {
    path: "/profile/signature-standards/eidas/video",
    component: _13a501c5,
    name: "profile-signature-standards-eidas-video"
  }, {
    path: "/profile/signature-standards/zertes/auto",
    component: _f3342c6c,
    name: "profile-signature-standards-zertes-auto"
  }, {
    path: "/profile/signature-standards/zertes/video",
    component: _356568f0,
    name: "profile-signature-standards-zertes-video"
  }, {
    path: "/signup/flows/eidplus/confirm",
    component: _2b75d082,
    name: "signup-flows-eidplus-confirm"
  }, {
    path: "/signup/flows/eidplus/new",
    component: _818293c2,
    name: "signup-flows-eidplus-new"
  }, {
    path: "/",
    component: _5c7375ad,
    name: "index"
  }, {
    path: "/business/seals/:accountname",
    component: _0b4ca8c8,
    name: "business-seals-accountname"
  }, {
    path: "/direct-sign/identification/:id?/:userid",
    component: _4721a496,
    name: "direct-sign-identification-id-userid"
  }, {
    path: "/direct-sign/identification/:id?/:userid?/preview",
    component: _1f44d6eb,
    name: "direct-sign-identification-id-userid-preview"
  }, {
    path: "/invite/:id",
    component: _7bb3bcac,
    name: "invite-id"
  }, {
    path: "/view/:srid",
    component: _615f95e7,
    name: "view-srid"
  }, {
    path: "/view/:id?/:userid",
    component: _643d8384,
    name: "view-id-userid"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

<template>
  <header
    class="heading"
    :class="[
      headingBottomPaddingClass ? headingBottomPaddingClass : defaultPadding ? 'pb-12' : '',
      {
        center: center,
        padded: padded,
        truncated: truncated,
      },
    ]"
  >
    <div class="heading__container">
      <h1 v-if="title" data-cy="title" class="mb-3 | heading__title">
        {{ title }}
        <span v-if="titleDetail" class="heading__title-detail">
          {{ titleDetail }}
        </span>
      </h1>
      <div class="heading__subtitle">
        <p v-if="hasSubtitle" data-cy="subtitle" class="mb-0" v-html="$sanitize(subtitle)" />
        <p v-if="hasSlot && !hasSubtitle" data-cy="subtitleSlot" class="mb-0">
          <slot name="subtitle_slot" />
        </p>
        <p v-if="hintTitle" class="mt-4 mb-0" data-cy="hintTitle">
          <strong>{{ hintTitle }}</strong>
        </p>
        <p v-if="hintText" class="mb-0" data-cy="hintText">{{ hintText }}</p>
      </div>
      <div :class="['heading__slot', { 'ml-md-6': $slots.default }]">
        <slot />
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'Heading',
  inject: {
    headingBottomPaddingClass: {
      default: '',
    },
  },
  props: {
    title: String,
    titleDetail: String,
    subtitle: String,
    hintTitle: {
      type: String,
      default: '',
    },
    hintText: {
      type: String,
      default: '',
    },
    // The center prop should be removed once all centerd layouts have been
    // changed to the side by side layouts
    center: Boolean,
    truncated: Boolean,
    // Adds lateral padding, used for heading in profile
    padded: {
      type: Boolean,
      default: false,
    },
    defaultPadding: {
      type: Boolean,
      default: true,
    },
    // Moves buttons to bottom of page on mobile screens
    // fixActionsOnMobile: false,
  },
  computed: {
    /**
     * Subtitle takes precedence if there's both subtitle prop and a slot
     * But we make sure to only show one.
     */
    hasSubtitle(): boolean {
      return !!this.subtitle
    },
    hasSlot(): boolean {
      return !!this.$slots.subtitle_slot
    },
  },
})
</script>

<style lang="sass">

.heading
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%

  + .v-text-field
    margin-top: 0
    padding-top: 0

  &__container
    display: grid
    width: 100%
    grid-template-columns: 1fr auto
    grid-template-rows: 1fr auto
    width: 100%

  &.center
    justify-content: center
    text-align: center

  &.padded
    padding-left: 2rem
    padding-right: 2rem
    +maw(sm)
      padding-left: 1rem
      padding-right: 1rem

  &.truncated
    h1
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

  &__slot
    grid-column: 2 / 3
    grid-row: 1 / 3
    align-self: center

  &__title
    +h1
    line-height: 1.2
    color: $c-text-alt
    grid-column: 1 / 2
    grid-row: 1 / 2
    +max-width
      line-height: 1.0666

    &-detail
      font-weight: 100

  &__subtitle
    font-size: 1.25rem
    +max-width
      font-size: 1.125rem
      line-height: 1.3333

  .v-btn
    margin: 0.375rem 0.5rem
</style>

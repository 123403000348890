<template>
  <v-main class="layout3-content" :class="name">
    <!-- As for why there is an inner element, read the comment in Layout3Inner -->
    <layout-3-inner
      :title="title"
      :subtitle="subtitle"
      :hint-title="hintTitle"
      :hint-text="hintText"
      :image="image"
      :content-flex-start="contentFlexStart"
      :equal-width="equalWidth"
      :hide-image-on-mobile="hideImageOnMobile"
    >
      <slot />
      <!-- Check Layout3Inner for slot details -->
      <template slot="right">
        <slot name="right" />
      </template>
    </layout-3-inner>
  </v-main>
</template>

<script lang="ts">
import Layout3Inner from '~/components/layouts/Layout3Inner.vue'

interface Img {
  src: string
  width: number
  height: number
}

export default {
  name: 'Layout3',
  components: {
    Layout3Inner,
  },
  props: {
    contentFlexStart: Boolean,
    equalWidth: Boolean,
    hideImageOnMobile: Boolean,
    name: String,
    title: String,
    subtitle: String,
    hintTitle: {
      type: String,
      default: '',
    },
    hintText: {
      type: String,
      default: '',
    },
    image: Object as Vue.PropType<Img>,
  },
}
</script>

<style lang="sass">
.layout3-content > .v-main__wrap
  +maw(sm)
    width: 100%
  +min-width
    display: flex
</style>

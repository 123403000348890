import Error404 from '@/components/Error404.vue';
import useErrorHandling from '@/composables/useErrorHandling';
export default defineComponent({
  components: {
    Error404: Error404
  },
  props: {
    error: Object
  },
  setup: function setup() {
    useErrorHandling();
  }
});
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"heading",class:[
    _vm.headingBottomPaddingClass ? _vm.headingBottomPaddingClass : _vm.defaultPadding ? 'pb-12' : '',
    {
      center: _vm.center,
      padded: _vm.padded,
      truncated: _vm.truncated,
    },
  ]},[_c('div',{staticClass:"heading__container"},[(_vm.title)?_c('h1',{staticClass:"mb-3 | heading__title",attrs:{"data-cy":"title"}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n      "),(_vm.titleDetail)?_c('span',{staticClass:"heading__title-detail"},[_vm._v("\n        "+_vm._s(_vm.titleDetail)+"\n      ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"heading__subtitle"},[(_vm.hasSubtitle)?_c('p',{staticClass:"mb-0",attrs:{"data-cy":"subtitle"},domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.subtitle))}}):_vm._e(),_vm._v(" "),(_vm.hasSlot && !_vm.hasSubtitle)?_c('p',{staticClass:"mb-0",attrs:{"data-cy":"subtitleSlot"}},[_vm._t("subtitle_slot")],2):_vm._e(),_vm._v(" "),(_vm.hintTitle)?_c('p',{staticClass:"mt-4 mb-0",attrs:{"data-cy":"hintTitle"}},[_c('strong',[_vm._v(_vm._s(_vm.hintTitle))])]):_vm._e(),_vm._v(" "),(_vm.hintText)?_c('p',{staticClass:"mb-0",attrs:{"data-cy":"hintText"}},[_vm._v(_vm._s(_vm.hintText))]):_vm._e()]),_vm._v(" "),_c('div',{class:['heading__slot', { 'ml-md-6': _vm.$slots.default }]},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
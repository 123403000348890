import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import auth from '@/store/modules/auth'
import billing from '@/store/modules/billing'
import business from '@/store/modules/business'
import dialogs from '@/store/modules/dialogs'
import feedback from '@/store/modules/feedback'
import sealing from '@/store/modules/sealing'
import signatureRequestInvitation from '@/store/modules/signatureRequestInvitation'
import tan from '@/store/modules/tan'
import verification from '@/store/modules/verification'

import { actions } from './actions'
import { getters, mutations, state } from './mutations'

Vue.use(Vuex)

const store = (): Store<RootState> =>
  new Store<RootState>({
    state,
    getters,
    mutations,
    actions,
    modules: {
      auth,
      billing,
      business,
      dialogs,
      feedback,
      sealing,
      signatureRequestInvitation,
      tan,
      verification,
    },
  })

export default store

import { defineStore } from 'pinia'

import { getSsoUrl } from '@/repository/sso'

export const useSsoStore = defineStore('sso', {
  actions: {
    async getLoginUrl(email: string) {
      const nextLocation = sessionStorage.getItem('redirectURL') ? `&next=${sessionStorage.getItem('redirectURL')}` : ''

      const loginUrl = await getSsoUrl(email)

      if (!loginUrl) return null

      return `${loginUrl}${nextLocation}`
    },
  },
})

import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { storeToRefs } from 'pinia';
import InPageNav from '@/components/in-page-nav/InPageNav.vue';
import InPageNavBottom from '@/components/in-page-nav/InPageNavBottom.vue';
import PopOverNav from '@/components/in-page-nav/PopOverNav.vue';
import { useUserStore } from '@/stores/user';
export default defineComponent({
  name: 'ProfileNav',
  components: {
    InPageNav: InPageNav,
    InPageNavBottom: InPageNavBottom,
    PopOverNav: PopOverNav
  },
  props: {
    bottom: Boolean,
    popover: Boolean
  },
  setup: function setup() {
    var _storeToRefs = storeToRefs(useUserStore()),
      isBusinessMember = _storeToRefs.isBusinessMember;
    return {
      userIsBusinessMember: isBusinessMember
    };
  },
  computed: {
    navArray: function navArray() {
      var nav = [{
        routeName: 'profile',
        displayTitle: this.$t('global.navigation.personal_data'),
        icon: '$vuetify.icons.circle_profile',
        cypressIdentifier: 'personalDataButton'
      }, {
        routeName: 'profile-signature-standards',
        displayTitle: this.$t('global.navigation.signature_standards'),
        icon: '$vuetify.icons.signature_standards',
        cypressIdentifier: 'signatureStandardsButton'
      }, {
        routeName: 'profile-visual-signature',
        displayTitle: this.$t('global.navigation.visual_signature'),
        icon: '$vuetify.icons.artboard',
        cypressIdentifier: 'visualSignatureButton'
      }, {
        routeName: 'profile-settings',
        displayTitle: this.$t('global.navigation.settings'),
        icon: '$vuetify.icons.gear',
        cypressIdentifier: 'settingsButton'
      }, {
        divider: true
      }, {
        routeName: 'logout',
        displayTitle: this.$t('global.navigation.logout'),
        icon: '$vuetify.icons.leave',
        cypressIdentifier: 'logoutButton'
      }];

      // remove the dividers in the popover menu
      if (this.popover) {
        nav.forEach(function (item, index, object) {
          if ('divider' in item) object.splice(index, 1);
        });
      }
      return nav;
    }
  }
});
import type { ActionTree, GetterTree, MutationTree } from 'vuex'

const getInitialState = (): SealingState => ({
  isSealing: false,
  accountName: '',
})

const state: () => SealingState = getInitialState

const actions: ActionTree<BusinessState, RootState> = {
  // 'sign' or 'seal'
  setIsSealing({ commit }, isSealing: boolean) {
    commit('SET_IS_SEALING', isSealing)
  },
  setaccountName({ commit }, accountName: string) {
    commit('SET_SELECTED_SEAL_ACCOUNT_NAME', accountName)
  },
}

const mutations: MutationTree<SealingState> = {
  SET_IS_SEALING(state, isSealing: boolean) {
    state.isSealing = isSealing
  },
  SET_SELECTED_SEAL_ACCOUNT_NAME(state, method: string) {
    state.accountName = method
  },
}

const getters: GetterTree<SealingState, RootState> = {
  isSealing: state => {
    return state.isSealing
  },
  accountName: state => {
    return state.accountName
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

import { $http } from '@/plugins/http'

export function extractFilename(response: Response): string | undefined {
  return response.headers.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1]
}

export async function getDocumentData(
  documentId: string,
  options = { isSigned: false, isInline: false }
): Promise<BlobResponse> {
  const params = new URLSearchParams()
  if (options.isSigned) params.append('signed', 'true')
  if (options.isInline) params.append('inline', 'true')

  const response = await $http.get(`v1/documents/${documentId}/download${params.size ? `?${params}` : ''}`)

  const data = await response.blob()
  const name = extractFilename(response)

  return {
    data,
    name: name ?? `${documentId}.pdf`,
  }
}

export async function getProtocol(srId: string, type: SignatureProtocolType): Promise<BlobResponse> {
  const response = await $http.get(`v1/signature-requests/${srId}/report?type=${type}`)

  const data = await response.blob()
  const name = extractFilename(response)

  return {
    data,
    name: name ?? `${srId}_protocol.pdf`,
  }
}

export async function getAttachment(srId: string, attachmentId: string): Promise<BlobResponse> {
  const response = await $http.get(`v1/signature-requests/${srId}/attachments/${attachmentId}/content`)

  const data = await response.blob()
  const dataType = data.type.split('/')[1]
  const name = extractFilename(response)

  return {
    data,
    name: name ?? `${srId}_attachment-${attachmentId}.${dataType}`,
  }
}

export async function getDocumentPage(documentId: string, pageNumber: number, scale = 100): Promise<Blob> {
  const response = await $http.get(`v1/documents/${documentId}/pages/${pageNumber}?scale=${scale}`, { retry: 10 })

  return response.blob()
}

export async function getDocumentPageByUrl(url: string): Promise<Blob> {
  const response = await $http.get(url, { retry: 10 })

  return await response.blob()
}

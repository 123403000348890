import { ref } from 'vue'

export default function useCookieConsent() {
  const cookieConsentData = ref<CookieConsentData | null>(null)

  const setCookieConsentDataValue = () => {
    const cookiebot: CookieConsentData | undefined = window.Cookiebot
    if (cookiebot !== undefined) {
      cookieConsentData.value = {
        consent: cookiebot.consent,
        consented: cookiebot.consented,
        declined: cookiebot.declined,
        hasResponse: cookiebot.hasResponse,
        doNotTrack: cookiebot.doNotTrack,
        regulations: cookiebot.regulations,
      }
    }
  }

  const cookiebotEventTypes = [
    'CookiebotOnDialogInit',
    'CookiebotOnConsentReady',
    'CookiebotOnAccept',
    'CookiebotOnDecline',
  ]
  cookiebotEventTypes.forEach(eventType => window.addEventListener(eventType, () => setCookieConsentDataValue()))

  return {
    cookieConsentData,
  }
}

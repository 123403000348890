import Vue from 'vue'
import type { ActionTree, GetterTree, MutationTree } from 'vuex'

const getInitialState = (): SignatureRequestInvitationDraftsState => ({})

const state: () => SignatureRequestInvitationDraftsState = getInitialState

const actions: ActionTree<SignatureRequestInvitationDraftsState, RootState> = {
  // sets or updates a signature request invitation flow
  setSignagureRequestDraft({ commit }, data) {
    commit('SET_SIGNATURE_REQUEST_DRAFT', data)
  },
  // removes a signature request invitation draft
  deleteSignatureRequestDraft({ commit }, data) {
    commit('DELETE_SIGNATURE_REQUEST_DRAFT', data)
  },
}

const mutations: MutationTree<SignatureRequestInvitationDraftsState> = {
  SET_SIGNATURE_REQUEST_DRAFT(state, signatureRequestInvitationDraft: SignatureRequestInvitationDraft) {
    const srId = signatureRequestInvitationDraft?.signatureRequest?.id
    if (state && srId)
      Vue.set(state, signatureRequestInvitationDraft?.signatureRequest?.id, signatureRequestInvitationDraft)
  },
  DELETE_SIGNATURE_REQUEST_DRAFT(state, signatureRequestInvitationDraft: SignatureRequestInvitationDraft) {
    delete state[signatureRequestInvitationDraft.signatureRequest?.id]
  },
}

const getters: GetterTree<SignatureRequestInvitationDraftsState, RootState> = {
  signatureRequestInvitationDrafts: state => {
    return state
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

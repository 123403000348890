import { objectToCamelCase, objectToSnakeCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

export async function getPricePlans(): Promise<Omit<PricePlan, 'name'>[]> {
  const response = await $http.$get('/v2/businesses/price_plans')

  return objectToCamelCase(response)
}

export async function getStripeSetupIntent(
  businessId: string,
  country?: string
): Promise<GetStripeSetupIntentResponse> {
  const response = await $http.$post(`/v2/businesses/${businessId}/subscriptions/stripe_setup_intent`, { country })

  return objectToCamelCase(response)
}

export async function getDirectStripeSetupIntent(country?: string): Promise<GetStripeSetupIntentResponse> {
  const response = await $http.$post('/v2/businesses/direct_upgrade/stripe_setup_intent', { country })

  return objectToCamelCase(response)
}

export async function previewUpgradeSubscriptionPrice(
  businessId: string,
  promoCode: string,
  plan: string,
  cycle: string,
  amountSeats: number,
  currency: Currency
): Promise<PreviewUpgradeSubscriptionPriceResponse> {
  const payload = {
    promoCode,
    plan,
    cycle,
    amountSeats,
    currency,
  }
  const payloadSnakeCased = objectToSnakeCase(payload)
  // If the user is not part of any business yet, use the 'direct_upgrade' endpoint
  if (businessId === '')
    return objectToCamelCase(await $http.$post('/v2/businesses/direct_upgrade/preview', payloadSnakeCased))
  else
    return objectToCamelCase(
      await $http.$post(`/v2/businesses/${businessId}/subscriptions/upgrade/preview`, payloadSnakeCased)
    )
}

export async function upgradeSubscription(
  businessId: string,
  subscriptionPayload: UpgradeSubscriptionPayload
): Promise<PricePlanInfo> {
  const updatedPricePlan = await $http.$put(
    `/v2/businesses/${businessId}/subscriptions/upgrade`,
    objectToSnakeCase(subscriptionPayload)
  )

  return objectToCamelCase(updatedPricePlan)
}

export async function directUpgradeSubscription(upgradePayload: DirectUpgradeSubscriptionPayload): Promise<void> {
  // TypeScript acting out again, so we need to force a generic type in the transformation
  await $http.$post('/v2/businesses/direct_upgrade/confirm', objectToSnakeCase<Record<string, unknown>>(upgradePayload))
}

interface PreviewSeatsUpgradePriceResponse {
  amountDue: number
  amountSubtotal: number
  amountDiscountApplied: number
  currency: Lowercase<Currency>
  timestamp: string
  percentOff: number
}

export async function previewSeatsUpgradePrice(
  businessId: string,
  seats: number
): Promise<PreviewSeatsUpgradePriceResponse> {
  return objectToCamelCase(
    await $http.$post<PreviewSeatsUpgradePriceResponse>(`/v2/businesses/${businessId}/subscriptions/seats/preview`, {
      seats,
    })
  )
}

interface validatePromoCodeResponse {
  valid: boolean
  message: string
}

export async function validatePromoCode(
  businessId: string,
  promo_code: string,
  plan: string,
  cycle: string
): Promise<validatePromoCodeResponse> {
  return await $http.$post<validatePromoCodeResponse>(
    `/v2/businesses/${businessId}/subscriptions/coupons/validate_promo`,
    { promo_code, plan, cycle }
  )
}

export default function useErrorHandling() {
  var _useNuxtApp = useNuxtApp(),
    $config = _useNuxtApp.$config,
    $sentry = _useNuxtApp.$sentry;
  var logError = function logError(info, error, vm) {
    if ($config.public.mode === 'staging' || $config.public.mode === 'production') {
      $sentry.captureException(error, {
        extra: {
          info: info,
          vm: vm
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.error(info, error);
    }
  };
  if (getCurrentInstance()) {
    onErrorCaptured(function (error, vm, info) {
      logError(info, error, vm);
    });
  }
  return {
    logError: logError
  };
}
import { render, staticRenderFns } from "./PersonSeveral.vue?vue&type=template&id=d65abb28"
import script from "./PersonSeveral.vue?vue&type=script&setup=true&lang=js"
export * from "./PersonSeveral.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
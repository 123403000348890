import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import { storeToRefs } from 'pinia';
import { useDirectSignStore } from '@/stores/directSign';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useDocumentUrl() {
  var _useNuxtApp = useNuxtApp(),
    $http = _useNuxtApp.$http;
  var directSignStore = useDirectSignStore();
  var _storeToRefs = storeToRefs(directSignStore),
    directSignB64AuthHeader = _storeToRefs.directSignB64AuthHeader,
    isDirectSign = _storeToRefs.isDirectSign;
  var downloadFile = function downloadFile(blob, name) {
    var a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.target = '_blank';
    a.rel = 'noopener';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  var getPagePreviewUrl = function getPagePreviewUrl(documentId, page, scale) {
    var params = new URLSearchParams();
    params.append('scale', scale.toString());
    if (isDirectSign.value) {
      params.append('token', directSignB64AuthHeader.value);
      return "".concat($http.getBaseURL(), "/v1/direct/documents/").concat(documentId, "/pages/").concat(page, "?").concat(params.toString());
    } else {
      return "".concat($http.getBaseURL(), "/v1/documents/").concat(documentId, "/pages/").concat(page, "?").concat(params.toString());
    }
  };
  return {
    downloadFile: downloadFile,
    // For backwards compatibility
    documentPagePreviewURL: getPagePreviewUrl
  };
}
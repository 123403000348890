import { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { ActionTree } from 'vuex'

const subdomain = 'verification'
const hostName = (axios: NuxtAxiosInstance) =>
  [subdomain, '.', axios.defaults?.baseURL?.replace('https://', '').replace('/phoebe', '')].join('')

const actions: ActionTree<BaseState, RootState> = {
  async sendTan(_, data: { challengeId: string; secret: string }) {
    const axiosInstance = this.$axios.create()
    const verificationServiceURL = `https://${hostName(this.$axios)}/api`
    delete axiosInstance.defaults.headers.common.Authorization
    return await axiosInstance.$post(`${verificationServiceURL}/respond/${data.challengeId}`, {
      secret: data.secret,
    })
  },
  async requestNewTan(_, data: { challengeId: string }) {
    const axiosInstance = this.$axios.create()
    const verificationServiceURL = `https://${hostName(this.$axios)}/api`
    delete axiosInstance.defaults.headers.common.Authorization
    return await axiosInstance.$post(`${verificationServiceURL}/resend/${data.challengeId}`)
  },
  async cancel(_, data: { challengeId: string }) {
    const axiosInstance = this.$axios.create()
    const verificationServiceURL = `https://${hostName(this.$axios)}/api`
    delete axiosInstance.defaults.headers.common.Authorization
    return await axiosInstance.$post(`${verificationServiceURL}/cancel/${data.challengeId}`)
  },
}

export default {
  namespaced: true,
  actions,
}

import { objectToCamelCase, objectToSnakeCase } from '@/composables/useCaseConvert'
import { $http } from '@/plugins/http'

/* eslint-disable @typescript-eslint/naming-convention */
export interface GetUserResponse {
  email: string
  firstName: string
  lastName: string
  entireName: string
  attributes: {
    mobile: string[]
    avatar: string[]
    lang: Language[]
    default_legislation: string[]
    default_quality: string[]
    seen_aes_ready: string[]
    visual_signature_name: string[]
    visual_signature_company: string[]
    visual_signature_location: string[]
    visual_signature_display_date: string[]
    accepted_gtc: string[]
    free_signatures_remaining: string[]
    signature_key_type: string[]
    visual_signature_display_qr: string[]
    visual_signature_display_claim: string[]
    visual_signature_display_standards: string[]
    email_notification_level: string[]
    stripe_subscription_id?: string[]
    signer_identity_email?: string[]
    accepted_business_invite?: string[]
    removed_from_business?: string[]
    immutable_eid_fields?: string[]
    // Legacy attributes
    member_of: string[]
    admin_of: string[]
  }
  permissions: { [index: string]: boolean }
  emailVerified: boolean
  username: string
  retention: number
  disabled_qualities: string[]
  has_sso: boolean
}

/*
const attributesSchema = object({
  stripeSubscriptionId: string().optional(),
  lang: string(),
  defaultLegislation: string().optional(),
  defaultQuality: string().optional(),
  paidBy: string().optional(),
  immutableEidFields: array(string()).optional(),
  acceptedGtc: boolean(),
})

function parseAttributes(attributes: Camelized<GetUserResponse>['attributes']): Partial<UserAttributes> {
  return attributesSchema.cast({
    stripeSubscriptionId: attributes.stripeSubscriptionId?.[0],
    lang: attributes.lang[0],
    defaultLegislation: attributes.defaultLegislation?.[0],
    defaultQuality: attributes.defaultQuality?.[0],
    paidBy: attributes.paidBy?.[0],
    immutableEidFields: attributes.immutableEidFields,
    acceptedGtc: Boolean(attributes.acceptedGtc?.[0]),
  })
}
*/

/* eslint-enable @typescript-eslint/naming-convention */

export async function login(email: string, password: string, isFirstLogin = false): Promise<string> {
  const { token } = await $http.$post<{ token: string }>('/v1/login', {
    email,
    password,
    ...(isFirstLogin && { first_login: isFirstLogin }),
  })
  return token
}

export async function logout(): Promise<void> {
  await $http.$post('/v1/logout')
}

export async function register(registerData: RegisterUserPayload): Promise<void> {
  await $http.$post('/v1/register', {
    firstName: registerData.firstName,
    lastName: registerData.lastName,
    password: registerData.password,
    token: registerData.token,
    gtc: registerData.isGtcAccepted,
    lang: registerData.language,
    mobile: '', // FIXME: The backend requires a mobile number, but we actually never ask for it
    avatar: 'f2', // TODO: No idea what this is, and whether we still need it
  })
}

export async function getUser(): Promise<IUser> {
  const response = await $http.$get<GetUserResponse>('/v1/user')

  const baseData = { ...objectToCamelCase(response), attributes: response.attributes }

  const signatureQualityResponse = await $http.$get<SignatureQualities>('v1/user/signature-qualities-detail')

  const signatureQualityData = {
    ...objectToCamelCase<SignatureQualityData>(signatureQualityResponse),
    seal: signatureQualityResponse.seal,
  }

  return {
    id: baseData.username,
    email: baseData.email,
    emailVerified: baseData.emailVerified,
    firstName: baseData.firstName,
    lastName: baseData.lastName,
    attributes: baseData.attributes,
    signatureQualities: signatureQualityData,
    disabledQualities: baseData.disabledQualities,
    retention: baseData.retention,
    hasSso: baseData.hasSso,
    signatures: [
      {
        name: baseData.attributes.visual_signature_name?.[0],
        company: baseData.attributes.visual_signature_company?.[0],
        language: baseData.attributes.lang?.[0] ?? 'en',
        location: baseData.attributes.visual_signature_location?.[0],
        displayClaim: baseData.attributes.visual_signature_display_claim?.[0] === 'true',
        displayStandards: baseData.attributes.visual_signature_display_standards?.[0] === 'true',
        displayQr: baseData.attributes.visual_signature_display_qr?.[0] === 'true',
        displayDate: baseData.attributes.visual_signature_display_date?.[0] === 'true',
      },
    ],
    permissions: baseData.permissions,
  }
}

export async function getUserBusiness(): Promise<BusinessData> {
  const businessData = await $http.$get('/v2/businesses/user')

  return objectToCamelCase<BusinessData>(businessData)
}

// TODO: Combine with `updateUser`
export async function updateProfileData(
  updateData: Partial<IUser> & { currentPassword?: string }
): Promise<{ status: string; message: string }> {
  const payload = {
    ...objectToSnakeCase(updateData),
    // There is a casing mismatch here (backend expects mixed case in payload 🙄)
    firstName: updateData.firstName,
    lastName: updateData.lastName,
  }

  return await $http.$put('/v1/user', payload)
}

export async function updateUser(attributes: Partial<Record<keyof UserAttributes, unknown>>): Promise<void> {
  return await $http.$put('/v1/user', { attributes })
}

async function _accept(terms: { [index: string]: boolean }): Promise<void> {
  return await $http.$post('/v1/user/accept', terms)
}

export async function acceptGTC(): Promise<void> {
  return await _accept({ gtc: true })
}

export async function acceptBizInvite(): Promise<void> {
  return await _accept({ business_invite: true })
}

export async function acceptToUSwisscom(): Promise<void> {
  return await _accept({ tou_swisscom: true })
}

export async function requestConfirmationEmail(
  email: string,
  language: string,
  flow: string = 'free',
  confirm = false
): Promise<void> {
  await $http.$post('/v1/request-confirmation', {
    email,
    flow,
    lang: language,
    ...(confirm && { confirm }),
  })
}

export async function verifyConfirmationToken(token: string, language: string): Promise<void> {
  await $http.post('/v1/valid-confirmation', {
    token,
    language,
  })
}

export async function validatePassword(
  password: string,
  token: string | null = null
): Promise<{ isValid: boolean; errorCode?: PasswordErrorCode }> {
  const { valid_password: isValid, error: errorCode } = await $http.$post<{
    valid_password: boolean
    error?: PasswordErrorCode
  }>('/v1/check-password', { password, ...(token && { token }) })

  return { isValid, errorCode }
}

/**
 * Changes the password for the logged-in user.
 */
export async function changePassword(
  newPassword: string,
  oldPassword: string
): Promise<{ status: string; message: string }> {
  return await $http.$post('/v1/change-password', {
    new: newPassword,
    old: oldPassword,
  })
}

export async function requestPasswordReset(email: string): Promise<void> {
  await $http.$post('/v1/request-reset', {
    email,
  })
}

/**
 * Resets the password for a logged-out user.
 */
export async function resetPassword(token: string, newPassword: string): Promise<void> {
  await $http.$post('/v1/reset-password', {
    token,
    new: newPassword,
  })
}

/**
 * Deletes the user
 */
export async function deleteUser(password?: string): Promise<void> {
  return await $http.$post('/v1/user/delete', {
    password,
  })
}

import type { ActionTree } from 'vuex'

const actions: ActionTree<BaseState, RootState> = {
  sendDataToHubspot(_, { portalId, formId, data }) {
    return this.$axios.$post(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, data)
  },
}

export default {
  namespaced: true,
  actions,
}

import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
/*
 * protects billing routes
 */

var billing = function billing(_ref) {
  var store = _ref.store,
    route = _ref.route,
    redirect = _ref.redirect;
  // routes available only for NON biz members
  var routesForNonBusinessMembersOnly = ['billing-settings', 'billing-usage'];

  // prevent user who belongs to a business of opening these pages
  if (route.name && routesForNonBusinessMembersOnly.includes(route.name) && store.getters['business/userIsMember']) redirect('/billing');
  if (route.path === '/business/billing') {
    var companyHasManualInvoicingEnabled = store.getters['business/companyHasManualInvoicingEnabled'];
    var _useUserPlanInfo = useUserPlanInfo(),
      isLegacyPlan = _useUserPlanInfo.isLegacyPlan;
    if (companyHasManualInvoicingEnabled || isLegacyPlan) {
      redirect('/business/activity?tab=1');
    } else {
      redirect('/business/billing/plan');
    }
  }
};
export default billing;
import cookies from 'js-cookie';
import useCookieConsent from '@/composables/useCookieConsent';
import { sendData as sendHubspotData } from '@/repository/hubspot';
export default function useTracking() {
  var _useNuxtApp = useNuxtApp(),
    $config = _useNuxtApp.$config,
    $matomo = _useNuxtApp.$matomo;
  var _useCookieConsent = useCookieConsent(),
    cookieConsentData = _useCookieConsent.cookieConsentData;
  var envIsTracked = $config.public.mode === 'production';
  var _storeToRefs = storeToRefs(useUserStore()),
    id = _storeToRefs.id;
  var userIsLoggedIn = computed(function () {
    return id.value !== '';
  });
  var _storeToRefs2 = storeToRefs(useBusinessStore()),
    settings = _storeToRefs2.settings;
  var userIsInUserpilotBlocklist = computed(function () {
    var _settings$value, _settings$value$userP;
    return ((_settings$value = settings.value) === null || _settings$value === void 0 ? void 0 : (_settings$value$userP = _settings$value.userPilot) === null || _settings$value$userP === void 0 ? void 0 : _settings$value$userP.enabled) === false;
  });

  // Only enable global tracking logic if environment is tracked, user is logged in and is not part of the blocklist
  var trackingEnabled = computed(function () {
    return envIsTracked && userIsLoggedIn.value && !userIsInUserpilotBlocklist.value;
  });

  // Dynamically show/hide cookie banner depending on global tracking settings
  var setCookieBannerVisibility = function setCookieBannerVisibility() {
    var cookieBannerElement = document.querySelector('#CybotCookiebotDialog');
    if (cookieBannerElement) {
      if (trackingEnabled.value) cookieBannerElement.style.display = 'flex';else cookieBannerElement.style.display = 'none';
    }
  };

  // Show/hide cookie banner on first cookiebot load
  window.addEventListener('CookiebotOnDialogDisplay', function () {
    setCookieBannerVisibility();
  });

  // Show/hide cookie banner on each change to global tracking settings
  watch(trackingEnabled, function () {
    setCookieBannerVisibility();
  });
  var userConsentedToTracking = computed(function () {
    var _cookieConsentData$va, _cookieConsentData$va2, _cookieConsentData$va3, _cookieConsentData$va4;
    var userRespondedToCookieBanner = (_cookieConsentData$va = (_cookieConsentData$va2 = cookieConsentData.value) === null || _cookieConsentData$va2 === void 0 ? void 0 : _cookieConsentData$va2.hasResponse) !== null && _cookieConsentData$va !== void 0 ? _cookieConsentData$va : false;
    var userconsentedToStatistics = (_cookieConsentData$va3 = (_cookieConsentData$va4 = cookieConsentData.value) === null || _cookieConsentData$va4 === void 0 ? void 0 : _cookieConsentData$va4.consent.statistics) !== null && _cookieConsentData$va3 !== void 0 ? _cookieConsentData$va3 : false;
    return userRespondedToCookieBanner && userconsentedToStatistics;
  });

  // Watch cookie consent data to enable/disable matomo consent
  watch(cookieConsentData, function () {
    $matomo === null || $matomo === void 0 ? void 0 : $matomo.setConsent(userConsentedToTracking.value);
  });
  var loadScriptToHTML = function loadScriptToHTML(scriptSource, options) {
    var _s$parentNode;
    // Based on Google Tag's script to load external js scripts. Originally used by Matomo
    if (options !== null && options !== void 0 && options.pushMatomoCall) {
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({
        'mtm.startTime': new Date().getTime(),
        event: 'mtm.Start'
      });
    }
    var d = document;
    var g = d.createElement('script');
    var s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = scriptSource;
    (_s$parentNode = s.parentNode) === null || _s$parentNode === void 0 ? void 0 : _s$parentNode.insertBefore(g, s);
  };
  var loadWelcomeTrackers = function loadWelcomeTrackers() {
    // Matomo Tag Manager
    // This script is not the general matomo tracker. It is an additional event to track conversions on the /welcome page
    if ($matomo) {
      loadScriptToHTML('https://analytics.skribble.com/js/container_CFEkdfSo.js', {
        pushMatomoCall: true
      });
    }

    // Hubspot Script Loader, only on user's welcome page
    // Update the csp-policy in the header when file js.hs-scripts.com/5051692.js changes
    loadScriptToHTML('https://js.hs-scripts.com/5051692.js');
  };
  var dispatchHubspotSignupTracker = function dispatchHubspotSignupTracker(email) {
    var hubspotTrackingCode = cookies.get('hubspotutk');
    var hubspotData = {
      fields: [{
        objectTypeId: '0-1',
        name: 'email',
        value: email
      }],
      context: {
        hutk: hubspotTrackingCode,
        pageUri: 'my.skribble.com/business/signup/',
        pageName: 'Business Signup Start Page'
      },
      legalConsentOptions: {
        legitimateInterest: {
          value: true,
          subscriptionTypeId: 5692350,
          legalBasis: 'LEAD',
          text: 'Legitimate interest by signing up to Skribble'
        }
      }
    };
    void sendHubspotData($config.public.hubspotPortalId, '24b2cb64-7486-4bc8-8751-6c876c34b191', hubspotData);
  };
  return {
    dispatchHubspotSignupTracker: dispatchHubspotSignupTracker,
    loadWelcomeTrackers: loadWelcomeTrackers
  };
}
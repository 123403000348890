/* if fails we either do nothing, or we do API req */
/* only relevant if image wasn't scaled 2x */
/* max scaling mem:
  max_bytes = 110491360
  width * height < 6905710

  max pixels = 27622840

  if img w * img h
    is within under 1% of max pixels, then there's chance it was scaled down
    so, we should ping bbq for original size (if we sign old documents)
    else just return image width / height
*/

export default function useExif() {
  function c(arr: Uint8Array, start: number, end?: number): string {
    return !end ? String.fromCharCode(arr[start]) : String.fromCharCode(...arr.slice(start, end))
  }

  async function getWebPDimensionsFromEXIFBlob(blob: Blob) {
    const buf = await new Response(blob).arrayBuffer()
    return getWebPDimensionsFromEXIF(buf)
  }

  /**
   * if exif, we know by flag at 16th byte
   * exif in webp is likely to be at the end of the file,
   * so look at last 1000 bytes
   */
  function isWebPWithEXIF(arr: Uint8Array): number {
    const riff = c(arr, 0, 4)
    const webp = c(arr, 8, 12)
    const vp8x = c(arr, 12, 16)
    let hasEXIF = 0
    hasEXIF += arr[16]
    hasEXIF &= 8
    hasEXIF = Number(!!hasEXIF)
    if (`${riff}${webp}${vp8x}${hasEXIF}` === 'RIFFWEBPVP8X1') {
      for (let i = arr.length - 1; i > arr.length - 1000; i -= 1) {
        if (c(arr, i, i + 4) === 'EXIF') {
          return i + 4
        }
      }
    }

    return -1
  }

  /**
   * if exif, we know by the marker 0xFFE1 [255, 225] (a jpeg thing)
   * exif in jpeg is likely to be at the start of the file,
   * so look at first 1000 bytes
   */
  function isJPEGWithEXIF(arr: Uint8Array): number {
    for (let i = 0; i < 1000; i += 1) {
      if (arr[i] === 255 && arr[i + 1] === 225) {
        const exif = c(arr, i + 4, i + 10)
        if (exif === 'Exif\0\0') {
          return i + 10
        }
      }
    }
    return -1
  }

  const getExifStart = (arr: Uint8Array) => {
    const webp = isWebPWithEXIF(arr)
    const jpeg = isJPEGWithEXIF(arr)
    return webp !== -1 ? webp : jpeg
  }

  function getWebPDimensionsFromEXIF(buf: ArrayBuffer): { width: number; height: number } {
    const arr = new Uint8Array(buf)
    const fail = { width: -1, height: -1 }

    let start = getExifStart(arr)
    if (start === -1) return fail
    let end = 0
    let i = 0
    let j = 0

    for (i = start; i < arr.length; i += 1) {
      if (c(arr, i) === 'd' && c(arr, i + 1) === ':') {
        start = i + 2
        for (j = start; j < arr.length; j += 1) {
          if (c(arr, j) === ':' && c(arr, j + 1) === 'd') {
            end = j
            break
          }
        }

        if (end) {
          /* there is actually a table where values can be referenced
              by key, but that would involve used a whole table and
              parsing data, where this just looks fro the expected
              characters, as its just ascii
          */
          const dim = c(arr, start, end).split('x')
          if (dim.length === 2) {
            return { width: Number(dim[0]), height: Number(dim[1]) }
          }
        }
      }
    }
    return fail
  }

  return {
    getWebPDimensionsFromEXIFBlob,
    getWebPDimensionsFromEXIF,
  }
}

import type { ActionTree } from 'vuex'

/**
 * Used for things like exponential backoff with re-requests
 * @param time milliseconds to wait
 * @returns promise
 */
function wait(time: number) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

/**
 * Type guard to ensure error is of type "AxiosError"
 * @param error
 * @returns
 */
function isAxiosError(error: unknown): error is AxiosError<unknown, unknown> {
  return 'response' in (error as HasResponse<unknown>) || 'request' in (error as HasRequest<unknown>)
}

export const actions: ActionTree<BaseState, RootState> = {
  // get current datetime
  async getDateTime({ commit, rootState }) {
    // don't bother updating if we already updated recently
    if ((Date.now() - rootState.now.lastUpdated) * 0.001 < 2) return
    const response: { now: string } = await this.$axios.$get('/api/datetime')
    commit('SET_NOW', response.now)
  },
  // remove stripe subscription ID from user to instantly update the FE
  removeStripeSubscriptionId({ commit }) {
    commit('REMOVE_STRIPE_SUBSCRIPTION_ID')
  },
  createSignatureRequest(_, data) {
    return this.$axios.$post('/api/signature-requests', data)
  },
  // update a signature request
  updateSignatureRequest({ state }, data: ActiveSignatureRequest) {
    // augment state.activeSignatureRequest with data and send to phoebe
    const srData: ActiveSignatureRequest = {
      ...state.activeSignatureRequest,
      ...data,
    }
    return this.$axios.$put('/api/signature-requests', srData)
  },
  // sends email for password reset
  requestPasswordResetMail(_, data) {
    return this.$axios.$post('/api/request-reset', data)
  },
  // sends email for confirmation
  requestConfirmationOfMail(_, data) {
    localStorage.setItem('signup-email', data.email)
    return this.$axios.$post('/api/request-confirmation', data)
  },
  // verifies confirmation token
  verifyConfirmationToken(_, data) {
    localStorage.removeItem('signup-email')
    const lang = this.app.i18n.locale.substring(0, 2)
    return this.$axios.$post('/api/valid-confirmation', { ...data, lang })
  },
  resetUser({ commit, dispatch }) {
    commit('RESET_USER')
    this.$axios.setToken(false)
    this.$http.setToken(false)
    dispatch('auth/removeStoredToken').catch(() => {
      // TODO: handle error
    })

    window.location.href = '/login?logged-out'
  },
  // gets signature requests of current user
  getSignatureRequests({ commit, state }, data) {
    // construct params for querying signature requests
    // - all:       /signature-requests
    // TODO: adapt next line when bbq has a dedicated endpoint for this
    // - empty:     /signature-requests and filter in JS for signatures.length === 0
    // - toSign:    /signature-requests?status_overall=OPEN&signature_status=OPEN&account_email={user ID}
    // - pending:   /signature-requests?status_overall=OPEN
    // - declined:  /signature-requests?status_overall=DECLINED
    // - completed: /signature-requests?status_overall=SIGNED
    let params = {}
    switch (data.status) {
      case 'all':
        break
      case 'toSign':
        params = {
          status_overall: 'OPEN',
          signature_status: 'OPEN',
          account_email: state.user?.email,
        }
        break
      case 'pending':
      case 'empty':
        params = { status_overall: 'OPEN' }
        break
      case 'declined':
        params = { status_overall: 'DECLINED,WITHDRAWN' }
        break
      case 'completed':
        params = { status_overall: 'SIGNED' }
        break
    }

    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$get('/api/signature-requests', {
          params,
        })
        .then((response: ActiveSignatureRequest[]) => {
          // filter if status 'empty' was asked
          // TODO: remove when bbq has a dedicated endpoint for this
          if (data.status === 'empty') {
            const filteredResults = response.filter(sr => {
              return sr.signatures.length === 0
            })
            response = filteredResults
          } else if (data.status === 'pending') {
            const filteredResults = response.filter(sr => {
              return sr.signatures.length > 0
            })
            response = filteredResults
          }
          commit('SET_DISPLAYED_SIGNATURE_REQUESTS', response)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // gets a signature request
  getSignatureRequest(_, data: { auth: BasicAuth; srid: string } | string) {
    // regular signing will provide just the SR id in data
    // NAS will provide {srid: ID, auth: { username: USER, password: PW }}
    let basicAuthHeader = {}
    let endpointURL = ''
    if (typeof data === 'object') {
      // NAS will need to add http basic auth header
      basicAuthHeader = { auth: data.auth }
      endpointURL = `direct/signature-requests/${data.srid}`
    } else {
      // just append string in data which corresponds to SR id
      endpointURL = `signature-requests/${data}`
    }

    return this.$axios.$get(`/api/${endpointURL}`, basicAuthHeader)
  },
  // resets processed signature requests
  resetProcessedSignatureRequests({ commit }) {
    commit('RESET_PROCESSED_SIGNATURE_REQUESTS')
    return Promise.resolve()
  },
  // resets active signature request
  resetActiveSignatureRequest({ commit, dispatch }) {
    commit('RESET_ACTIVE_SIGNATURE_REQUEST')
    dispatch('resetActiveDocument').catch(() => {
      // TODO: handle error
    })
    return Promise.resolve()
  },
  // set visual signature to active signature request
  setVisualSignature({ commit }, data) {
    commit('SET_VISUAL_SIGNATURE', data)
    return Promise.resolve()
  },
  // reset visual signature to active signature request
  resetVisualSignature({ commit }) {
    commit('RESET_VISUAL_SIGNATURE')
    return Promise.resolve()
  },
  // gets active document
  getActiveDocument({ commit }, data: { auth?: BasicAuth; document_id: string }) {
    const endpointURL = `documents/${data.document_id}`

    let prefix = ''
    let auth = {}
    if (data.auth) {
      // this is a NAS request
      prefix = 'direct/'
      auth = { auth: data.auth }
    }
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/api/${prefix}${endpointURL}`, auth)
        .then(response => {
          commit('SET_ACTIVE_DOCUMENT', response)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // resets active signature request
  resetActiveDocument({ commit }) {
    commit('RESET_ACTIVE_DOCUMENT')
    return Promise.resolve()
  },
  // gets a document by its id
  getDocument(_, data) {
    return this.$axios.$get(`/api/documents/${data}`)
  },
  // declines given signature request
  declineSignatureRequest(
    _,
    data: {
      auth?: string
      signature_request_id: string
      decline_message_text: string
    }
  ) {
    let prefix = ''
    let auth = {}
    if (data.auth) {
      // this is a NAS signing
      prefix = 'direct/'
      auth = { auth: data.auth }
      delete data.auth
    }

    return this.$axios.$post<{ status: string }>(
      `/api/${prefix}signature-requests/${data.signature_request_id}/decline`,
      { message: data.decline_message_text },
      auth
    )
  },
  // withdraws given signature request
  withdrawSignatureRequest(_, data: { signature_request_id: string; withdraw_message_text: string }) {
    return this.$axios.$post<{ status: string }>(`/api/signature-requests/${data.signature_request_id}/withdraw`, {
      message: data.withdraw_message_text,
    })
  },
  // deletes given signature request
  deleteSignatureRequest(_, data) {
    return this.$axios.$delete<{ status: string }>(`/api/signature-requests/${data}`)
  },
  // deletes given document
  async deleteDocument({ commit }, data) {
    await this.$axios.$delete(`/api/documents/${data}`)
    commit('RESET_ACTIVE_DOCUMENT')
    return Promise.resolve() // TODO: check if necessary in async fn
  },
  // reset UI components
  async resetUI({ dispatch }) {
    // hide overlays
    await dispatch('closeAllOverlays')
    return Promise.resolve() // TODO: check if necessary in async fn
  },
  setProcessedSignatureRequest({ commit }, data) {
    commit('SET_PROCESSED_SIGNATURE_REQUESTS', data)
  },
  // failed SR because of locked, error, cancel
  showSigningFailure({ commit, dispatch }, data) {
    // show overlay failure
    commit('SET_SIGNING_STATUS', data)
    commit('SHOW_OVERLAY', 'signingFailure')
    dispatch('closeSigningOverlay').catch(() => {
      // TODO: handle error
    })
  },
  removeFromDisplayedSignatureRequests({ commit }, data) {
    commit('REMOVE_FROM_DISPLAYED_SIGNATURE_REQUESTS', data)
    return Promise.resolve()
  },
  setDisplayedSignatureRequestTo({ commit }, data: { signatureRequestId: string; stateToSet: string }) {
    commit('SET_DISPLAYED_SIGNATURE_REQUEST_TO', data)
    return Promise.resolve()
  },
  setExitURLs({ commit }, data) {
    commit('SET_EXIT_URLS', data)
    return Promise.resolve()
  },
  resetExitURLs({ commit }) {
    commit('SET_EXIT_URLS', null)
    return Promise.resolve()
  },
  // sets the currently active tab
  setActiveTab({ commit }, data) {
    commit('SET_ACTIVE_TAB', data)
    return Promise.resolve()
  },
  // resets the currently active tab
  resetActiveTab({ commit }) {
    commit('RESET_ACTIVE_TAB')
    return Promise.resolve()
  },
  // set visual signature specs on activeSignatureRequest
  setVisualSignatureSpec({ commit, dispatch, state }) {
    if (state.activeSignatureRequest) {
      dispatch('constructVisualSignatureSpec', {
        signatureRequest: state.activeSignatureRequest,
        document: state.activeDocument,
        email: state.user?.email || '', // an undefined state.user.email is possible for a NAS
      })
        .then(response => {
          commit('SET_VISUAL_SIGNATURE_SPEC', response)
          return Promise.resolve()
        })
        .catch(() => {
          // TODO: handle error
        })
    }
  },
  constructVisualSignatureSpec(
    { dispatch },
    data: {
      email: string
      signatureRequest: ActiveSignatureRequest
      document: SkrDocument
    }
  ) {
    // construct the visual signature specs of given signature request
    // for given email
    const visSigSpec: VisualSignatureSpec = {
      applicable: false,
      positions: [],
      presetImage: false,
      pagesWithPreplacedSignatures: [],
    }

    // Construct array of pages which have a signature preplaced in order to
    // not lazy load them later on. This affects the VisSig of the current
    // user and the VisSigs of the co-signers.
    const pages: number[] = []
    data.signatureRequest?.signatures?.forEach(signature => {
      if (!pages.includes(parseInt(signature.visual_signature?.position?.page.toString()))) {
        pages.push(parseInt(signature.visual_signature?.position?.page.toString()))
      }
    })
    visSigSpec.pagesWithPreplacedSignatures = pages

    // is the overall status still "OPEN"?
    if (data.signatureRequest.status_overall === 'OPEN') {
      // does this user have an open signature status?
      dispatch('hasToSign', {
        signatures: data.signatureRequest.signatures,
        email: data.email,
      })
        .then((response: Signature) => {
          if (response) {
            // this user has an open signature request
            visSigSpec.applicable = true

            // if there is a position set on signer, we use this
            // there can only be one position in this case
            if (response.visual_signature) {
              // determine preset position
              if (response.visual_signature.position) {
                // there is a preset position
                visSigSpec.positions = [response.visual_signature.position]
              } else if (response.visual_signature.form_field) {
                // there is a preset form_field
                // get positions from active document
                const field = data.document.signature_fields.find(field => {
                  return field.name === response.visual_signature.form_field
                })
                if (field) {
                  // there is a corresponding form field on the document
                  // get its position data
                  visSigSpec.positions = field.positions
                }
              }

              // determine preset image
              if ('image' in response.visual_signature) {
                // there is a preset image
                visSigSpec.presetImage = true
              }
            }
          }
        })
        .catch(() => {
          // TODO: handle error
        })
    }
    return Promise.resolve(visSigSpec)
  },
  // checks if data.email is present with status_code OPEN in data.signatures
  hasToSign(_, data: { signatures: Signature[]; email: string }) {
    const signer = data.signatures.find(signature => {
      return signature.account_email === data.email && signature.status_code === 'OPEN'
    })
    return Promise.resolve(signer)
  },
  goToDocumentsTab({ dispatch }, data) {
    dispatch('setActiveTab', data)
      .then(() => void this.$router.push({ name: 'index' }))
      .catch(() => {
        // TODO: handle error
      })
  },
  // show overlays which don't need any setup instructions
  showOverlay({ commit }, data) {
    commit('SHOW_OVERLAY', data)
  },
  // close overlay which don't need any teardown instructions
  closeOverlay({ commit }, data) {
    commit('HIDE_OVERLAY', data)
  },
  // close signing overlay
  closeSigningOverlay({ commit }) {
    commit('HIDE_OVERLAY', 'signing')
    commit('RESET_CONTINUATION_DATA')
  },
  // close failure overlay
  closeSigningFailureOverlay({ commit }) {
    commit('HIDE_OVERLAY', 'signingFailure')
    commit('RESET_SIGNING_STATUS')
  },
  // close all overlays
  closeAllOverlays({ dispatch }) {
    void dispatch('closeSigningOverlay')
    void dispatch('closeSigningFailureOverlay')
    void dispatch('closeOverlay', 'tanExpired')
    void dispatch('closeOverlay', 'tanInvalid')
    void dispatch('closeOverlay', 'tanLocked')
    void dispatch('closeOverlay', 'mobileAes')
    void dispatch('resetSnackbar')
  },
  setLockStatus({ commit }, data) {
    commit('SET_LOCK_STATUS', data)
  },
  resetLockStatus({ commit }) {
    commit('RESET_LOCK_STATUS')
  },
  // gets current lock status given SR
  getLockStatus(_, data: { auth: BasicAuth; srid: string } | string) {
    // regular signing will provide just the SR id in data
    // NAS will provide {srid: ID, auth: { username: USER, password: PW }}
    let basicAuthHeader = {}
    let endpointURL = ''
    if (typeof data === 'object') {
      // NAS will need to add http basic auth header
      basicAuthHeader = { auth: data.auth }
      endpointURL = `direct/signature-requests/${data.srid}/lock-status`
    } else {
      // just append string in data which corresponds to SR id
      endpointURL = `signature-requests/${data}/lock-status`
    }

    // don't show nuxt's progress bar here because will poll this
    return this.$axios.$get<{ locked: boolean }>(`/api/${endpointURL}`, {
      // https://github.com/nuxt-community/axios-module/issues/258
      // also types not applying here
      // progress: false,
      ...basicAuthHeader,
    })
  },
  // send pwd in PWD/OTP flow
  sendPwd(_, data) {
    return this.$axios.$post('/api/swisscom/pwdotp/pwd', data)
  },
  // send otp in PWD/OTP flow
  sendOtp(_, data) {
    return this.$axios.$post('/api/swisscom/pwdotp/otp', data)
  },
  // send userCancel in PWD/OTP flow
  sendUserCancel(_, data) {
    return this.$axios.$post('/api/swisscom/pwdotp/cancel', data)
  },
  setUserForgotPwd({ commit }) {
    commit('SET_USER_FORGOT_PWD')
  },
  resetUserForgotPwd({ commit }) {
    commit('RESET_USER_FORGOT_PWD')
  },
  setSnackbar({ commit, dispatch }, data) {
    void dispatch('resetSnackbar')
    commit('SET_SNACKBAR', data)
  },
  resetSnackbar({ commit, state }, onlyPermanent) {
    if (!onlyPermanent || state.snackbar.timeout === 0) {
      commit('RESET_SNACKBAR')
    }
  },
  setAlert({ commit }, data) {
    commit('SET_ALERT', data)
  },
  resetAlert({ commit }) {
    commit('RESET_ALERT')
  },
  setDirectSign({ commit }, data) {
    commit('SET_DIRECTSIGN', data)
    return Promise.resolve()
  },
  resetDirectSign({ commit }) {
    commit('RESET_DIRECTSIGN')
  },
  acceptBizInvite() {
    return this.$axios.$post('/api/user/accept', { business_invite: true })
  },
  acceptToUSwisscom() {
    return this.$axios.$post('/api/user/accept', { tou_swisscom: true })
  },
  checkCannotSignAlert({ dispatch, getters, state }) {
    if (state.user && !getters.user.highestQuality) {
      if (!state.user.emailVerified) {
        // this user cannot sign because they need to verify their mail first
        void dispatch('setAlert', {
          priority: 4,
          message: { key: 'global.profile.email_verification_alert.text' },
          color: 'warning',
          action: {
            text: { key: 'global.profile.email_verification_alert.cta' },
            href: '/profile',
            target: null,
          },
        })
      } else {
        // this user cannot sign because their E-ID is not ready
        void dispatch('setAlert', {
          priority: 4,
          message: { key: 'global.profile.cannot_sign_alert.text' },
          color: 'warning',
          action: {
            text: { key: 'global.profile.cannot_sign_alert.cta' },
            href: { key: 'global.profile.cannot_sign_alert.link' },
            target: '_blank',
          },
        })
      }
    } else {
      void dispatch('resetAlert')
    }
  },
  async getSignatureImageOfUser({ commit, dispatch }, data) {
    // reset possibly previously set image
    commit('RESET_SIGNATURE_IMAGE')

    try {
      const hasImage = !!(await dispatch(
        'getDocumentData',
        `${this.$axios.defaults.baseURL}/v1/images/signature/${data}`
      ))
      commit('SET_SIGNATURE_IMAGE', hasImage)
    } catch {
      commit('SET_SIGNATURE_IMAGE', false)
    }
  },
  setSignatureImage({ commit }, data) {
    // this is used to directly set the user's signature image in store
    // directly after they uploaded it to avoid the roundtrip to retreive this
    // image again from the backend
    commit('SET_SIGNATURE_IMAGE', data)
  },
  uploadSignatureImage(_, data) {
    return this.$axios.$post('/api/images/signature', data)
  },
  async deleteSignatureImage({ commit }, data) {
    await this.$axios.delete(`images/signature/${data}`)
    commit('RESET_SIGNATURE_IMAGE')
  },
  getSealDetails({ commit, rootState }, accountName) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const endpointUrl = `/api/businesses/${businessId}/seals/${accountName}`
    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$get(endpointUrl)
        .then(response => {
          commit('SET_SEAL_DETAILS', response)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateSealDetail({ commit, rootState }, { accountName, sealDetails }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const endpointUrl = `/api/businesses/${businessId}/seals/${accountName}`
    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$put(endpointUrl, sealDetails)
        .then(response => {
          commit('SET_SEAL_DETAILS', response)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteSealMembers({ rootState }, { accountName, sealMembers }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const endpointUrl = `/api/businesses/${businessId}/seals/${accountName}/members`
    return new Promise<void>((resolve, reject) => {
      this.$axios
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        .$delete(endpointUrl, { data: { members: sealMembers } })
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  addSealMembers({ rootState }, { accountName, sealMembers }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    const endpointUrl = `/api/businesses/${businessId}/seals/${accountName}/members`
    return new Promise<void>((resolve, reject) => {
      this.$axios
        .$post(endpointUrl, sealMembers)
        .then(() => resolve())
        .catch(error => {
          reject(error)
        })
    })
  },
  async getSealImage({ rootState, commit, dispatch }, { accountname }) {
    const businessId = rootState.user?.attributes.member_of?.[0]
    // reset possibly previously set image
    commit('RESET_SEAL_IMAGE')
    const sealURL = `${this.$axios.defaults.baseURL}/v2/businesses/${businessId}/seals/${accountname}/image`
    try {
      const res = (await dispatch('getDocumentData', sealURL)) as Response
      const blob = await res.blob()
      await (() => {
        return new Promise<void>((resolve, reject) => {
          const img = new Image()
          img.onload = () => {
            const drawingCanvas = document.createElement('canvas')
            drawingCanvas.width = img.width
            drawingCanvas.height = img.height
            const ctx = drawingCanvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            commit('SET_SEAL_IMAGE', {
              content: drawingCanvas.toDataURL(),
              width: img.width,
              height: img.height,
            })
            resolve()
          }
          img.onerror = reject
          img.src = URL.createObjectURL(blob)
        })
      })()
    } catch {
      // silently fail
    }
  },
  setSealImage({ commit }, data) {
    // this is used to directly set the user's seal image in store
    // directly after they uploaded it to avoid the roundtrip to retreive this
    // image again from the backend
    commit('SET_SEAL_IMAGE', data)
  },
  uploadSealImage(_, { data, endPointUrl }) {
    return this.$axios.$post(`/api/${endPointUrl}`, data)
  },
  deleteSealImage({ commit }, endpointUrl) {
    void this.$axios.delete(endpointUrl)
    commit('RESET_SEAL_IMAGE')
  },
  async getVisualSignature(
    { state },
    data: {
      name?: string
      company?: string
      language?: string
      location?: string
      quality?: string
      legislation?: string
      provider?: string
      displayClaim?: boolean
      displayStandards?: boolean
      displayQr?: boolean
      displayDate?: boolean
      placeholder?: boolean
      backoff?: () => number
    }
  ) {
    if (!data.language) data.language = state.user?.attributes?.lang?.[0] || 'en'

    const query = new URLSearchParams()
    const isDirectSignEndpoint = (state as RootState).directSignSession && !data.placeholder
    const allowedParams = isDirectSignEndpoint
      ? ['leg', 'qual']
      : ['name', 'opt', 'lang', 'loc', 'qual', 'leg', 'prov', 'claim', 'std', 'qr', 'date', 'ph']
    const lowerParams = ['lang', 'log', 'qual', 'prov']
    const setParam = (param, value) => {
      if (allowedParams.includes(param)) {
        if (typeof value === 'boolean') query.set(param, value.toString())
        else if (typeof value === 'string' && lowerParams.includes(param)) query.set(param, value.toLowerCase())
        else query.set(param, value ?? '')
      }
    }
    const mapKey = key => {
      const map = {
        name: 'name',
        company: 'opt',
        language: 'lang',
        location: 'loc',
        quality: 'qual',
        legislation: 'leg',
        provider: 'prov',
        displayClaim: 'claim',
        displayStandards: 'std',
        displayQr: 'qr',
        displayDate: 'date',
        placeholder: 'ph',
      }
      return map[key]
    }
    for (const [key, value] of Object.entries(data)) {
      setParam(mapKey(key), value)
    }

    const pixel =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2P4DwQACfsD/Z8fLAAAAAAASUVORK5CYII='
    const backoff = data.backoff?.()
    if (backoff === undefined || backoff === -1) return pixel

    try {
      if (backoff > 0) await wait(backoff)
      const blob = await this.$axios.$get<Blob>(
        `/api/${isDirectSignEndpoint ? 'direct/' : 'user/'}${
          data.placeholder ? 'preview-' : ''
        }visual-signature?${query.toString()}`,
        /**
         * This method has a built-in retry mechanism because
         * it not only needs to retry but also cancel previous
         * requests whenever a new request is made before an old
         * request has not finished. It functions fairly similarly
         * to the axios-retry system, so they should be fairly
         * indistinguishable operationally.
         */
        {
          'axios-retry': {
            retries: 0,
          },
          responseType: 'blob',
          ...(isDirectSignEndpoint && ((state as RootState).directSignSession as { auth: BasicAuth })),
        }
      )
      const dataURL = await ((blobInner: Blob) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result as string)
          reader.onerror = reject
          reader.readAsDataURL(blobInner)
        }))(blob)
      return dataURL
    } catch (error) {
      if (isAxiosError(error) && backoff < 3000) {
        // if error.response
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // if error.request
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return await this.dispatch('getVisualSignature', data)
      } else {
        // If build is running in production, send to error tracker
        if (this.$config.public.mode === 'production') {
          void this.$axios.post('93476/wave-error', error)
        } else {
          // Otherwise print to browser console
          // eslint-disable-next-line no-console
          console.error(error)
        }
        // Something happened in setting up the request that triggered an Error
        // return white pixel
        return pixel
      }
    }
  },
  // TODO: this should be cancellable
  async getDocumentData({ rootState, dispatch }, url: string) {
    const token = (await dispatch('auth/getStoredToken')) as string
    // direct sign URLs use a token for validation,
    // so the auth header isn't necessary
    const directSign = rootState.directSignSession !== false
    const headers = directSign
      ? undefined
      : {
          authorization: `bearer ${token}`,
        }

    // replicate axios' 3 retry implementation
    for (let i = 0; i < 4; i += 1) {
      if (i > 0) await wait(Math.pow(i, 2) + Math.random() * 1000)
      try {
        /*
        what fetch and not axios?
        - we can set the mode and credentials values, which afaik are necessary
        - its a native function and nicer to use
        */
        const res = await fetch(url, {
          mode: 'cors',
          credentials: 'include',
          headers,
        })
        if (res.status !== 200) continue
        return res
      } catch {
        continue
      }
    }
  },
  async setupStripe(): Promise<void> {
    if (this.app.$stripe) return
    try {
      const stripe = await import(/* webpackChunkName: "stripe-loader" */ '@stripe/stripe-js')
      this.app.$stripe = await stripe.loadStripe(this.$config.public.stripePublishableKey || '')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('stripe initialization error:', error)
    }
  },
}

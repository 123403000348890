import { defineStore } from 'pinia'

import { legacyGetBusinessInfo } from '@/repository/legacy'

interface LegacyBusinessState {
  defaultCode: string
  billing?: Partial<BusinessBilling>
}

export const useLegacyBusinessStore = defineStore('legacyBusiness', {
  state: (): LegacyBusinessState => ({
    defaultCode: 'eur',
    billing: {},
  }),

  getters: {
    businessCurrency(): string {
      return this.billing?.next_invoice?.currency ?? this.defaultCode
    },
  },

  actions: {
    async getBilling(businessId: string) {
      try {
        /**
         * This won't have a value if the customer doesn't
         * pay via stripe (e.g. old enterprise customers)
         */
        const res = await legacyGetBusinessInfo(businessId)
        this.$patch({ billing: res })
        this.$nuxt.store.commit('business/SET_BILLING', res)
      } catch {}
    },
  },
})

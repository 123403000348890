import { useI18n } from 'nuxt-i18n-composable';
import Avatar from '@/components/Avatar.vue';
import ProfileNav from '@/components/in-page-nav/ProfileNav.vue';
import useUserPlanInfo from '@/composables/useUserPlanInfo';
import { useBusinessStore } from '@/stores/business';
import { useUserStore } from '@/stores/user';
import { useSystemStore } from '~/stores/system';
export default defineComponent({
  name: 'MiniNav',
  components: {
    Avatar: Avatar,
    ProfileNav: ProfileNav
  },
  setup: function setup() {
    var businessStore = useBusinessStore();
    var _storeToRefs = storeToRefs(useUserStore()),
      isBusinessAdmin = _storeToRefs.isBusinessAdmin;
    var _useUserPlanInfo = useUserPlanInfo(),
      activePricePlanId = _useUserPlanInfo.activePricePlanId,
      isNewFreePlan = _useUserPlanInfo.isNewFreePlan;
    var systemStore = useSystemStore();
    var platform = computed(function () {
      return systemStore.platform || 'CH';
    });
    var supportContact = computed(function () {
      return businessStore.settings.supportContact;
    });
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var getEmailContactLink = computed(function () {
      var _supportContact$value;
      return (_supportContact$value = supportContact.value) !== null && _supportContact$value !== void 0 && _supportContact$value.email ? "mailto:".concat(supportContact.value.email) : platform.value === 'EU' ? t('global.navigation.contact.link_form_de') : t('global.navigation.contact.link');
    });
    var getSupportContactLink = computed(function () {
      var _supportContact$value2;
      return (_supportContact$value2 = supportContact.value) !== null && _supportContact$value2 !== void 0 && _supportContact$value2.phone ? "tel:".concat(supportContact.value.phone) : platform.value === 'EU' ? t('global.navigation.call.call_support_form_de') : t('global.navigation.call.call_support_form');
    });
    return {
      userIsAdmin: isBusinessAdmin,
      isNewFreePlan: isNewFreePlan,
      activePricePlanId: activePricePlanId,
      supportContact: supportContact,
      platform: platform,
      getEmailContactLink: getEmailContactLink,
      getSupportContactLink: getSupportContactLink
    };
  },
  methods: {
    clickHelpIcon: function clickHelpIcon() {
      // Do not open link on smaller screens to allow touchscreens to hover
      if (this.$vuetify.breakpoint.mdAndUp) {
        window.open(this.$tc('global.navigation.helpcenter.link'));
      }
    }
  }
});
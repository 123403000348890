import type { NuxtHTTPInstance } from '@nuxt/http'
import type { Plugin } from '@nuxt/types'

import { useUserStore } from '@/stores/user'

// eslint-disable-next-line import/no-mutable-exports
export let $http: NuxtHTTPInstance

const http: Plugin = ({ $http: httpInstance, route, redirect }) => {
  httpInstance.onError(error => {
    if (error?.response?.status >= 500 && route.name !== 'sorry') {
      // We want to give the server some time to recover and react locally if it doesn't
      if (error?.response?.status === 503) return
      redirect('/sorry')
    } else if (error?.response?.status === 401) {
      const userStore = useUserStore()

      if (route.name !== 'logout') {
        sessionStorage.setItem('redirectURL', route.fullPath)
      }
      $http.setToken(false)
      void userStore.logoutUser()
    }
  })

  $http = httpInstance
}

export default http

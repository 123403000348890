import type { ActionTree } from 'vuex'

const actions: ActionTree<void, RootState> = {
  getStoredToken() {
    // Try retrieving token from either sessionStorage or localStorage.
    let token: { expiration: number; value: string } | undefined
    const session = sessionStorage.getItem('accessToken')
    const local = localStorage.getItem('accessToken')
    if (session) {
      token = JSON.parse(session) as { expiration: number; value: string }
    } else if (local) {
      token = JSON.parse(local) as { expiration: number; value: string }
    } else {
      // We have no token found but it could still be stored in sessionStorage
      // in another tab as they don't share sessionStorage among them. Let's
      // communicate via storage events based on
      // https://stackoverflow.com/questions/20325763/browser-sessionstorage-share-between-tabs.
      localStorage.setItem('getSessionStorage', 'syncTabs')
      localStorage.removeItem('getSessionStorage')
    }

    // Only unexpired tokens are valid.
    if (token && token.expiration > Date.now()) {
      return Promise.resolve(token.value)
    } else {
      return Promise.resolve(null)
    }
  },
  removeStoredToken() {
    sessionStorage.removeItem('accessToken')
    localStorage.removeItem('accessToken')

    // Tell other tabs to remove their sessionStorage tokens as well.
    localStorage.setItem('removeSessionStorage', 'syncTabs')
    localStorage.removeItem('removeSessionStorage')
  },
  storeToken(_, { token, stayLoggedIn }: { token: string; stayLoggedIn: boolean }) {
    // Tokens can never live longer than 2 days
    // 2 * 24 * 60 * 60 * 1000 = 172800000.
    // But they can be limited to the user's browsing session or be kept open
    // for later usage using localStorage.
    if (stayLoggedIn) {
      localStorage.setItem(
        'accessToken',
        JSON.stringify({
          value: token,
          expiration: Date.now() + 172800000,
        })
      )
    } else {
      sessionStorage.setItem(
        'accessToken',
        JSON.stringify({
          value: token,
          expiration: Date.now() + 172800000,
        })
      )
    }
  },
}

export default {
  namespaced: true,
  actions,
}

import "core-js/modules/es.array.concat.js";
import { useUserStore } from '@/stores/user';
export default defineComponent({
  name: 'Avatar',
  props: {
    firstName: {
      type: String,
      default: null
    },
    lastName: {
      type: String,
      default: null
    }
  },
  setup: function setup() {
    var userStore = useUserStore();
    return {
      user: userStore
    };
  },
  computed: {
    backgroundColors: function backgroundColors() {
      return [this.$vuetify.theme.themes.light['avatar-0'], this.$vuetify.theme.themes.light['avatar-1'], this.$vuetify.theme.themes.light['price-plan-gold'], this.$vuetify.theme.themes.light['avatar-3'], this.$vuetify.theme.themes.light['avatar-4'], this.$vuetify.theme.themes.light['avatar-5'], this.$vuetify.theme.themes.light['avatar-6'], this.$vuetify.theme.themes.light['avatar-7'], this.$vuetify.theme.themes.light['avatar-8'], this.$vuetify.theme.themes.light['avatar-9']];
    },
    initials: function initials() {
      var _this$user, _this$user2;
      if (this.firstName && this.lastName) {
        // both names have to be given when not the logged in user shall be taken
        return "".concat(this.firstName.substring(0, 1)).concat(this.lastName.substring(0, 1));
      } else if ((_this$user = this.user) !== null && _this$user !== void 0 && _this$user.firstName && (_this$user2 = this.user) !== null && _this$user2 !== void 0 && _this$user2.lastName) {
        return "".concat(this.user.firstName.substring(0, 1)).concat(this.user.lastName.substring(0, 1));
      } else {
        return '';
      }
    },
    getFullName: function getFullName() {
      var _this$user3, _this$user4;
      // both names have to be given when not the logged in user shall be taken
      if (this.firstName && this.lastName) {
        return this.firstName + this.lastName;
      } else if ((_this$user3 = this.user) !== null && _this$user3 !== void 0 && _this$user3.firstName && (_this$user4 = this.user) !== null && _this$user4 !== void 0 && _this$user4.lastName) {
        return this.user.firstName + this.user.lastName;
      } else {
        return '';
      }
    },
    getBackground: function getBackground() {
      var hash = 0;
      var i;
      var chr;

      // Set default color
      if (this.getFullName.length === 0) {
        return this.$vuetify.theme.themes.light['avatar-0'];
      }

      // set hash based color
      for (i = 0; i < this.getFullName.length; i++) {
        chr = this.getFullName.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        // Convert to 32bit integer
        hash |= 0;
      }
      return this.backgroundColors[Math.abs(hash % 10)];
    }
  }
});
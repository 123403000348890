import _countries from 'i18n-iso-countries';
import DE from 'i18n-iso-countries/langs/de.json';
import EN from 'i18n-iso-countries/langs/en.json';
import FR from 'i18n-iso-countries/langs/fr.json';
import IT from 'i18n-iso-countries/langs/it.json';
_countries.registerLocale(EN);
_countries.registerLocale(DE);
_countries.registerLocale(FR);
_countries.registerLocale(IT);
var countries = function countries(_, inject) {
  inject('countries', _countries);
};
export default countries;
import { Plugin } from '@nuxt/types'
import axiosRetry, { exponentialDelay } from 'axios-retry'

const apiPatterns = {
  v1: /^\/?api(\/v1)?(?!\/v2)\//,
  v2: /^\/?api\/v2\//,
}

const axios: Plugin = ({ $axios, store, route, redirect }) => {
  /**
   * Occasionally a request might fail, but not always will it
   * be a consistent failure, so try a few times to see if
   * it really should've failed, then bail.
   */
  axiosRetry($axios, { retryDelay: exponentialDelay, retries: 3 })

  $axios.onError(error => {
    const code = error.response && error.response.status
    if (!code) return
    // send user to something went wrong page -> /sorry
    // when there was an internal server problem with phoebe
    // except when they are already on /sorry
    // we do no longer handle network errors (isNaN(code)) here explicitly
    if (code >= 500 && route.name !== 'sorry') {
      redirect('/sorry')
    }

    // log user out and send him to login when we get a 401 from phoebe
    // we define that phoebe will return 401 only when wave accesses a
    // resource with an invalid or without a token. all other access
    // related errors (like reset-token invalid etc.) will return with
    // status 403
    if (code === 401) {
      if (route.name !== 'logout') {
        sessionStorage.setItem('redirectURL', route.fullPath)
      }
      void store.dispatch('resetUser')
    }
  })

  $axios.onRequest(request => {
    // Rewrite request URL to use correct API version
    const path = request.url ?? ''
    if (apiPatterns.v2.test(path)) {
      request.url = path.replace(apiPatterns.v2, '/v2/')
    } else if (apiPatterns.v1.test(path)) {
      request.url = path.replace(apiPatterns.v1, '/v1/')
    } else if (!path.startsWith('http') && !path.startsWith('/v1/')) {
      request.url = `/v1${request.url?.startsWith('/') ? request.url : `/${request.url}`}`
    }
  })
}

export default axios

import type { ActionTree, GetterTree, MutationTree } from 'vuex'

const getInitialState = (): DialogsState => ({
  transactionalCsat: false,
  addPaymentDetailsOnUpgrade: false,
  addPaymentDetailsOnTrialEnd: false,
})

const state: () => DialogsState = getInitialState

const mutations: MutationTree<DialogsState> = {
  SHOW_TRANSACTIONAL_CSAT(state, value: boolean) {
    state.transactionalCsat = value
  },
  SHOW_ADD_PAYMENT_DETAILS_ON_UPGRADE(state, value: boolean) {
    state.addPaymentDetailsOnUpgrade = value
  },
  SHOW_ADD_PAYMENT_DETAILS_ON_TRIAL_END(state, value: boolean) {
    state.addPaymentDetailsOnTrialEnd = value
  },
}

const getters: GetterTree<DialogsState, RootState> = {
  transactionalCsat: state => {
    return state.transactionalCsat
  },
  addPaymentDetailsOnUpgrade: state => {
    return state.addPaymentDetailsOnUpgrade
  },
  addPaymentDetailsOnTrialEnd: state => {
    return state.addPaymentDetailsOnTrialEnd
  },
}

const actions: ActionTree<BatchState, RootState> = {
  showTransactionalCsat({ commit }, value: boolean) {
    commit('SHOW_TRANSACTIONAL_CSAT', value)
  },
  showAddPaymentDetailsOnUpgrade({ commit }, value: boolean) {
    commit('SHOW_ADD_PAYMENT_DETAILS_ON_UPGRADE', value)
  },
  showAddPaymentDetailsOnTrialEnd({ commit }, value: boolean) {
    commit('SHOW_ADD_PAYMENT_DETAILS_ON_TRIAL_END', value)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

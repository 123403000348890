import { storeToRefs } from 'pinia'
import type { Ref } from 'vue'

import useDocumentUrl from '@/composables/useDocumentUrl'
import {
  getAttachment as getDirectAttachment,
  getDocumentData as getDirectDocumentData,
  getProtocol as getDirectProtocol,
} from '@/repository/direct-sign/document'
import { decline as directDeclineRequest } from '@/repository/direct-sign/signatureRequest'
import { getAttachment, getDocumentData, getProtocol } from '@/repository/document'
import {
  decline as declineRequest,
  delegate,
  inviteSigner as invite,
  remindSigners,
  remove as deleteRequest,
  removeSigner as deleteSignature,
  withdraw as withdrawRequest,
} from '@/repository/signatureRequest'
import { useDirectSignStore } from '@/stores/directSign'
import { useUserStore } from '@/stores/user'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useDocumentActions(signatureRequest: Ref<SignatureRequestData>) {
  const { isDirectSign, directSignB64AuthHeader } = storeToRefs(useDirectSignStore())
  const { email } = storeToRefs(useUserStore())
  const { downloadFile } = useDocumentUrl()

  // TODO: Multiple boolean parameters can be confusing, we may want to use an options object instead
  // Also, isSigned could also be inferred from the injected signatureRequest
  const downloadDocument = async (isSigned: boolean, isInline = false) => {
    const documentId = signatureRequest.value.documentId

    const options = {
      isSigned,
      isInline,
    }

    const { data, name } = isDirectSign.value
      ? await getDirectDocumentData(documentId, directSignB64AuthHeader.value, options)
      : await getDocumentData(documentId, options)

    downloadFile(data, name)
  }

  const downloadProtocol = async (type: SignatureProtocolType = 'pdf') => {
    const srId = signatureRequest.value.id

    const { data, name } = isDirectSign.value
      ? await getDirectProtocol(srId, type, directSignB64AuthHeader.value)
      : await getProtocol(srId, type)

    downloadFile(data, name)
  }

  const downloadAttachment = async (attachmentId: string, name?: string) => {
    const srId = signatureRequest.value.id

    const { data, name: attachmentName } = isDirectSign.value
      ? await getDirectAttachment(srId, attachmentId, directSignB64AuthHeader.value)
      : await getAttachment(srId, attachmentId)

    downloadFile(data, name ?? attachmentName)
  }

  const assign = async (emails: string[], message: string): Promise<DocumentActionResult> => {
    const signatureId = signatureRequest.value.signatures.find(
      sig => sig.accountEmail === email.value && sig.statusCode === 'OPEN'
    )?.sid

    if (!signatureId) throw new Error('No signature of assigning user found')

    const { status } = await delegate(signatureRequest.value.id, {
      sid: signatureId,
      to: emails,
      message,
    })

    return status
  }

  const decline = async (message?: string): Promise<DocumentActionResult> => {
    const srId = signatureRequest.value.id

    const { status } = isDirectSign.value
      ? await directDeclineRequest(srId, message)
      : await declineRequest(srId, message)

    return status
  }

  const withdraw = async (message?: string): Promise<DocumentActionResult> => {
    const { status } = await withdrawRequest(signatureRequest.value.id, message)

    return status
  }

  const remove = async (): Promise<DocumentActionResult> => {
    const { status } = await deleteRequest(signatureRequest.value.id)

    return status
  }

  const remind = async () => {
    await remindSigners(signatureRequest.value.id)
  }

  const inviteSigner = async (email: string) => {
    await invite(signatureRequest.value.id, email)
  }

  const removeSigner = async (signatureId: string) => {
    await deleteSignature(signatureRequest.value.id, signatureId)
  }

  return {
    downloadDocument,
    downloadProtocol,
    downloadAttachment,
    assign,
    decline,
    withdraw,
    remove,
    remind,
    inviteSigner,
    removeSigner,
  }
}

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useI18n } from 'nuxt-i18n-composable';
export default function useCurrency() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  var _useI18n = useI18n(),
    n = _useI18n.n;
  var businessStore = useBusinessStore();
  var defaultCurrencyOptions = computed(function () {
    var _businessStore$billin;
    return {
      style: 'currency',
      currency: (_businessStore$billin = businessStore.billing.currency) !== null && _businessStore$billin !== void 0 ? _businessStore$billin : 'EUR',
      currencyDisplay: 'code'
    };
  });
  var formatCurrency = function formatCurrency(rawPrice) {
    var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'EUR';
    return n(rawPrice, rawPrice % 1 === 0 ? _objectSpread(_objectSpread({}, defaultCurrencyOptions.value), {}, {
      maximumFractionDigits: 0,
      currency: currency
    }) : _objectSpread(_objectSpread({}, defaultCurrencyOptions.value), {}, {
      currency: currency
    }));
  };
  return {
    formatCurrency: formatCurrency
  };
}